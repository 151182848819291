import * as constants from './constants';

const initialState = {
    stats: {
        inProgress: false,
        data: [],
        success: null,
        error: false,
    },
    tags: {
        inProgress: false,
        data: [],
        success: null,
        error: false,
    },
    batch: {
        inProgress: false,
        data: [],
        success: null,
        error: false,
    },
    daily: {
        inProgress: false,
        data: [],
        success: null,
        error: false,
    },
    ratingChange: {
        inProgress: false,
        data: [],
        success: null,
        error: false,
    },
    ratingChangeFromLessons: {
        inProgress: false,
        data: [],
        success: null,
        error: false,
    },
    dailyList: {
        inProgress: false,
        data: [],
        success: null,
        error: false,
    },
    limitStatus: {
        inProgress: false,
        data: [],
        success: null,
        error: false,
    },
};

const sortType = (type) => {
    switch (type) {
        case constants.GET_PUZZLE_TAGS_REQUEST:
        case constants.GET_PUZZLE_TAGS_SUCCESS:
        case constants.GET_PUZZLE_TAGS_FAILURE:
            return 'tags';
        case constants.GET_PUZZLE_STATS_REQUEST:
        case constants.GET_PUZZLE_STATS_SUCCESS:
        case constants.GET_PUZZLE_STATS_FAILURE:
            return 'stats';
        case constants.GET_PUZZLE_BATCH_REQUEST:
        case constants.GET_PUZZLE_BATCH_SUCCESS:
        case constants.GET_PUZZLE_BATCH_FAILURE:
            return 'batch';
        case constants.GET_PUZZLE_DAILY_REQUEST:
        case constants.GET_PUZZLE_DAILY_SUCCESS:
        case constants.GET_PUZZLE_DAILY_FAILURE:
            return 'daily';
        case constants.SEND_PUZZLE_RESULT_REQUEST:
        case constants.SEND_PUZZLE_RESULT_SUCCESS:
        case constants.SEND_PUZZLE_RESULT_FAILURE:
            return 'ratingChange';
        case constants.SEND_PUZZLE_FROM_LESSONS_RESULT_REQUEST:
        case constants.SEND_PUZZLE_FROM_LESSONS_RESULT_SUCCESS:
        case constants.SEND_PUZZLE_FROM_LESSONS_RESULT_FAILURE:
            return 'ratingChangeFromLessons';
        case constants.GET_DAILY_PUZZLES_REQUEST:
        case constants.GET_DAILY_PUZZLES_FAILURE:
        case constants.GET_DAILY_PUZZLES_SUCCESS:
            return 'dailyList';
        case constants.GET_PUZZLE_5DLIMIT_STATUS:
        case constants.GET_PUZZLE_5DLIMIT_STATUS_FAILURE:
        case constants.GET_PUZZLE_5DLIMIT_STATUS_SUCCESS:
            return 'limitStatus';
        default:
            return 'error';
    }
};

const requestDataChange = (state, action) => {
    return {
        ...state,
        [sortType(action.type)]: { inProgress: true, error: null },
    };
};

const successDataChange = (state, action) => {
    return {
        ...state,
        [sortType(action.type)]: {
            inProgress: false,
            error: false,
            data: action.data,
            success: true,
        },
    };
};

const failiureDataChange = (state, action) => {
    return {
        ...state,
        [sortType(action.type)]: {
            inProgress: false,
            error: action.error,
            success: false,
        },
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_PUZZLE_TAGS_REQUEST:
        case constants.GET_PUZZLE_STATS_REQUEST:
        case constants.GET_PUZZLE_BATCH_REQUEST:
        case constants.GET_PUZZLE_DAILY_REQUEST:
        case constants.SEND_PUZZLE_RESULT_REQUEST:
        case constants.SEND_PUZZLE_FROM_LESSONS_RESULT_REQUEST:
		case constants.GET_DAILY_PUZZLES_REQUEST:
		case constants.GET_PUZZLE_5DLIMIT_STATUS:
            return requestDataChange(state, action);
        case constants.GET_PUZZLE_TAGS_SUCCESS:
        case constants.GET_PUZZLE_STATS_SUCCESS:
        case constants.GET_PUZZLE_BATCH_SUCCESS:
        case constants.GET_PUZZLE_DAILY_SUCCESS:
        case constants.SEND_PUZZLE_RESULT_SUCCESS:
        case constants.SEND_PUZZLE_FROM_LESSONS_RESULT_SUCCESS:
		case constants.GET_DAILY_PUZZLES_SUCCESS:
		case constants.GET_PUZZLE_5DLIMIT_STATUS_SUCCESS:
            return successDataChange(state, action);
        case constants.GET_PUZZLE_TAGS_FAILURE:
        case constants.GET_PUZZLE_STATS_FAILURE:
        case constants.GET_PUZZLE_BATCH_FAILURE:
        case constants.GET_PUZZLE_DAILY_FAILURE:
        case constants.SEND_PUZZLE_RESULT_FAILURE:
        case constants.SEND_PUZZLE_FROM_LESSONS_RESULT_FAILURE:
		case constants.GET_DAILY_PUZZLES_FAILURE:
		case constants.GET_PUZZLE_5DLIMIT_STATUS_FAILURE:
            return failiureDataChange(state, action);
        case constants.GET_PUZZLE_BATCH_5DLIMIT:
            return {
                ...state,
                batch: {
                    inProgress: false,
                    error: false,
                    data: [],
                    success: true,
                    dailyLimit: true,
                },
            };

        default:
            return state;
    }
};

export default reducer;
