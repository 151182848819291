import { updateObject } from 'shared/utility';
import * as constants from './constants';

const initialState = {
    subscriptionPlans: {
        inProgress: null,
        isSuccessful: null,
        data: null,
        error: null,
    },
    userPlan: null,
};

const getSubscriptionPlansRequest = (
    state,
    inProgress,
    isSuccessful,
    data,
    error
) => {
    return updateObject(state, {
        subscriptionPlans: {
            inProgress,
            isSuccessful,
            data:
                data &&
                data.sort((a, b) =>
                    a.period > b.period ? -1 : b.period > a.period ? 1 : 0
                ),
            error,
        },
    });
};

const setSubscriptionPlan = (state, data) => {
    return updateObject(state, {
        userPlan: data,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_PLAN:
            return setSubscriptionPlan(state, action.data);
        case constants.GET_PLANS_REQUEST:
            return getSubscriptionPlansRequest(state, true, null, null, '');
        case constants.GET_PLANS_SUCCESS:
            return getSubscriptionPlansRequest(
                state,
                false,
                true,
                action.data,
                null
            );
        case constants.GET_PLANS_FAILURE:
            return getSubscriptionPlansRequest(
                state,
                false,
                false,
                null,
                action.error
            );
        default:
            return state;
    }
};

export default reducer;
