export const facebookSDK = (lang) => {
    window.fbAsyncInit = function() {
        window.FB.init({
            appId: process.env.REACT_APP_FB_APP_ID,
            cookie: true,
            xfbml: true,
            autoLogAppEvents: true,
            version: 'v9.0',
        });

        window.FB.AppEvents.logPageView();
    };

    (function(d, s, id) {
        let js = d.getElementsByTagName(s)[0];
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
            return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = `https://connect.facebook.net/${lang}/sdk.js`;
        fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
};

export function googleSDK(setAuth2) {
    window.googleSDKLoaded = () => {
        window.gapi.load('auth2', () => {
            const auth2 = window.gapi.auth2.init({
                client_id: process.env.REACT_APP_GOOGLE_APP_ID,
                cookiepolicy: 'single_host_origin',
                scope: 'profile email',
            });
            setAuth2(auth2);
        });
    };

    (function(d, s, id) {
        let js = d.getElementsByTagName(s)[0];
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
            return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src =
            'https://apis.google.com/js/platform.js?onload=googleSDKLoaded';
        fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'google-jssdk');
}

export function appleSDK() {
    window.AppleID.auth.init({
        clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
        scope: 'name email',
        redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URL,
        // redirectURI: 'https://keysquare.pagekite.me/onboarding',
        state: 'Auth request',
        usePopup: true,
    });
}

export function cometChatSDK(CometChat) {
    const chatAppSetting = new CometChat.AppSettingsBuilder()
        .subscribePresenceForAllUsers()
        .setRegion(process.env.REACT_APP_COMETCHAT_API_REGION)
        .build();

    CometChat.init(process.env.REACT_APP_COMETCHAT_APP_ID, chatAppSetting).then(
        () => {
            // eslint-disable-next-line
            // console.log('Chat initialization completed successfully');
            // You can now call login function.
        },
        (error) => {
            // eslint-disable-next-line
            console.log('Chat initialization failed with error:', error);
            // Check the reason for error and take appropriate action.
        }
    );
}

export function usersnapSDK() {
	window.onUsersnapCXLoad = function(api) {
		api.init({
			button: {
				isHidden: true,
			},
		});
		window.Usersnap = api;
	};

	(function(d, s, id) {
        let js = d.getElementsByTagName(s)[0];
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
            return;
        }
        js = d.createElement(s);
		js.id = id;
		js.defer = 1;
        js.src =
            `https://widget.usersnap.com/load/${process.env.REACT_APP_USERSNAP_KEY}?onload=onUsersnapCXLoad`;
        fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'usersnap-jssdk');
}
