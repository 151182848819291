import * as constants from './constants';

const initialState = {
    postGameAnalysis: {
        inProgress: false,
        success: null,
        lastGet: null,
        data: {},
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_POST_GAME_ANALYSIS_REQUEST:
            return {
                ...state,
                postGameAnalysis: { inProgress: true, success: null },
            };
        case constants.GET_POST_GAME_ANALYSIS_FAILURE:
            return {
                ...state,
                postGameAnalysis: { inProgress: false, success: false },
            };
        case constants.GET_POST_GAME_ANALYSIS_SUCCESS:
            return {
                ...state,
                postGameAnalysis: {
                    inProgress: false,
                    success: true,
                    data: action.data,
                },
            };

        default:
            return state;
    }
};

export default reducer;
