const SESSION_STORAGE_KEYS = {
    PUZZLE_FILTERS_SETTINGS: 'puzzle_filter_settings',
};

// Puzzle Filter Settings
export const setPuzzleFiltersSS = (settings) => {
    return sessionStorage.setItem(SESSION_STORAGE_KEYS.PUZZLE_FILTERS_SETTINGS, JSON.stringify(settings));
};

export const getPuzzleFiltersSS = () => {
    return sessionStorage.getItem(SESSION_STORAGE_KEYS.PUZZLE_FILTERS_SETTINGS);
};

export const removePuzzleFiltersSS = () => {
    return sessionStorage.removeItem(SESSION_STORAGE_KEYS.PUZZLE_FILTERS_SETTINGS);
};
