import { GAME_TYPE_LIST } from 'store/game/constants';

export const chessTimeToSeconds = (time) => {
    if (time.minutes !== undefined && time.seconds !== undefined) {
        if (!time.hours) {
            time.hours = 0;
        }
        return time.hours * 3600 + time.minutes * 60 + time.seconds;
    }
    if (time.time) {
        return time.time;
    }
    return time;
};

export const secondToMillisecond = (seconds) => {
    return seconds * 1000;
};

export const millisecondsToSeconds = (milli) => {
    return milli / 1000;
};

export const secondsToChessTime = (sec) => {
    const hours = Math.floor(sec / 3600);
    const minutes =
        hours > 0 ? Math.floor((sec / 60) % 60) : Math.floor(sec / 60);
    const seconds = Math.round(sec % 60);

    return {
        hours,
        minutes,
        seconds,
    };
};

export const utcToLocalTime = (time) => {
	return new Date(time);
};

export const getFormatedTimeFromJSDate = (date) => {
    return (
        date.getUTCFullYear() +
        '/' +
        ('0' + (date.getUTCMonth() + 1)).slice(-2) +
        '/' +
        ('0' + date.getUTCDate()).slice(-2) +
        ' ' +
        ('0' + date.getUTCHours()).slice(-2) +
        ':' +
        ('0' + date.getUTCMinutes()).slice(-2) +
        ':' +
        ('0' + date.getUTCSeconds()).slice(-2)
		);
	};

	export const isCorrespondenceTimeControl = (timeControl) => {
		return (
        timeControl === GAME_TYPE_LIST.CORRESPONDENCE_1_0 ||
        timeControl === GAME_TYPE_LIST.CORRESPONDENCE_3_0 ||
        timeControl === GAME_TYPE_LIST.CORRESPONDENCE_5_0
    );
};

// We never display the seconds and always round up to the next min.
// The hours and min are clearly named.
// Exemple : All duration between 0 and 59:59 min is displayed as the following
// 00:10 → 1 min
// 00:45 → 1 min
// 02:34 → 3 min
// 02:23 → 3 min
// Exemple 2 : All duration above one hour is displayed
// 01:32:20 → 1 hour 33 min
// 04:36:30 → 4 hours 37 min

export const formatDuration = (time) => {
	const S_IN_HOUR = 3600;
	const S_IN_MINUTE = 60;
	const hours = Math.floor(time / S_IN_HOUR);
	const minutes = Math.ceil((time - (hours * S_IN_HOUR)) / S_IN_MINUTE);
	const h = hours && hours === 1 ? 'hour' : 'hours';
	const m = 'min';

	return ` ${hours > 0 ? `${hours} ${h}` : ''} ${`${minutes} ${m}`}`;
};

export const formatGameDuration = (time, increment = 0) => {
	const S_IN_DAY = 86400;
	const S_IN_HOUR = 3600;
	const S_IN_MINUTE = 60;
	const days = Math.floor(time / S_IN_DAY);
	const hours = Math.floor(time / S_IN_HOUR);
	const minutes = Math.floor(time / S_IN_MINUTE);
	const d = days && days === 1 ? 'day' : 'days';
	const h = hours && hours === 1 ? 'hour' : 'hours';
	const m = 'min';

	switch (true) {
		case days > 0:
			return increment === 0 ? `${days} ${d}` : `${days}/${increment}`;
		case hours > 0:
			return increment === 0 ? `${hours} ${h}` : `${hours}/${increment}`;
		case minutes > 0:
			return increment === 0 ? `${minutes} ${m}` : `${minutes}/${increment}`;

		default:
			return null;
	}
};
