import * as constants from './constants';

export const getLiveGamesRequest = (data) => {
    return {
        type: constants.GET_LIVE_GAMES_REQUEST,
        data,
    };
};

export const getLiveGamesSuccess = (data) => {
    return {
        type: constants.GET_LIVE_GAMES_SUCCESS,
        data,
    };
};

export const getLiveGamesFailure = (data) => {
    return {
        type: constants.GET_LIVE_GAMES_FAILURE,
        data,
    };
};

