export const GET_PUZZLE_TAGS_REQUEST = 'PUZZLE:GET_PUZZLE_TAGS_REQUEST';
export const GET_PUZZLE_TAGS_FAILURE = 'PUZZLE:GET_PUZZLE_TAGS_FAILURE';
export const GET_PUZZLE_TAGS_SUCCESS = 'PUZZLE:GET_PUZZLE_TAGS_SUCCESS';

export const GET_PUZZLE_STATS_REQUEST = 'PUZZLE:GET_PUZZLE_STATS_REQUEST';
export const GET_PUZZLE_STATS_FAILURE = 'PUZZLE:GET_PUZZLE_STATS_FAILURE';
export const GET_PUZZLE_STATS_SUCCESS = 'PUZZLE:GET_PUZZLE_STATS_SUCCESS';

export const GET_PUZZLE_BATCH_REQUEST = 'PUZZLE:GET_PUZZLE_BATCH_REQUEST';
export const GET_PUZZLE_BATCH_FAILURE = 'PUZZLE:GET_PUZZLE_BATCH_FAILURE';
export const GET_PUZZLE_BATCH_SUCCESS = 'PUZZLE:GET_PUZZLE_BATCH_SUCCESS';
export const GET_PUZZLE_BATCH_5DLIMIT = 'PUZZLE:GET_PUZZLE_BATCH_DAILY_LIMIT';

export const GET_PUZZLE_DAILY_REQUEST = 'PUZZLE:GET_PUZZLE_DAILY_REQUEST';
export const GET_PUZZLE_DAILY_FAILURE = 'PUZZLE:GET_PUZZLE_DAILY_FAILURE';
export const GET_PUZZLE_DAILY_SUCCESS = 'PUZZLE:GET_PUZZLE_DAILY_SUCCESS';

export const SEND_PUZZLE_RESULT_REQUEST = 'PUZZLE:SEND_PUZZLE_RESULT_REQUEST';
export const SEND_PUZZLE_RESULT_FAILURE = 'PUZZLE:SEND_PUZZLE_RESULT_FAILURE';
export const SEND_PUZZLE_RESULT_SUCCESS = 'PUZZLE:SEND_PUZZLE_RESULT_SUCCESS';

export const GET_DAILY_PUZZLES_REQUEST = 'PUZZLE:GET_DAILY_PUZZLES_REQUEST';
export const GET_DAILY_PUZZLES_FAILURE = 'PUZZLE:GET_DAILY_PUZZLES_FAILURE';
export const GET_DAILY_PUZZLES_SUCCESS = 'PUZZLE:GET_DAILY_PUZZLES_SUCCESS';

export const SEND_PUZZLE_FROM_LESSONS_RESULT_REQUEST = 'PUZZLE:SEND_PUZZLE_FROM_LESSONS_RESULT_REQUEST';
export const SEND_PUZZLE_FROM_LESSONS_RESULT_FAILURE = 'PUZZLE:SEND_PUZZLE_FROM_LESSONS_RESULT_FAILURE';
export const SEND_PUZZLE_FROM_LESSONS_RESULT_SUCCESS = 'PUZZLE:SEND_PUZZLE_FROM_LESSONS_RESULT_SUCCESS';

export const GET_PUZZLE_5DLIMIT_STATUS = 'PUZZLE:GET_PUZZLE_5DLIMIT_STATUS';
export const GET_PUZZLE_5DLIMIT_STATUS_SUCCESS = 'PUZZLE:GET_PUZZLE_5DLIMIT_STATUS_SUCCESS';
export const GET_PUZZLE_5DLIMIT_STATUS_FAILURE = 'PUZZLE:GET_PUZZLE_5DLIMIT_STATUS_FAILURE';
