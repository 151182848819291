import * as constants from './actionConstants';
import { DRAW_OFFER_RESPONSE, RECEIVE_WS, SEND_WS } from './wsConstants';
import { getResultFromServerWinner } from 'shared/game';
import {
    removeGameIdLS,
    setGuestUserLS,
    getIsGuestLS,
} from 'shared/localStorage';
import { secondsToChessTime } from 'shared/time';

const initialState = {
    connectionInProgress: false,
    connectionAttempts: 0,
    connected: false,
    search: {
        inProgress: false,
        users: [],
    },
    matched: {
        data: null,
        inProgress: false,
        reconnect: false,
    },
    waitingFriend: {
        deny: false,
        timeout: false,
        denyReason: '',
        spam: false,
    },
    serverMove: null,
    result: null,
    drawOffer: false,
    rematch: false,
    rematchTimeout: false,
    waitingDraw: '',
    reaction: {
        reaction: '',
        user_id: null,
    },
    challengeList: [],
    lastOpponentId: null,
    lastGameId: null,
    sendListQueue: [],
    correspondence_games_list: [],
    notificationList: [],
    latency_ping: null,
    player_latency: [],
    friendInteraction: {
        friend: null,
        blocked: null,
        username: '',
    },
    queueCheck: {
        inProgress: false,
        startTime: null,
        response: null,
        number: 0,
    }
};

const wsSendAction = (state, action) => {
    if (!state.connected) {
        const newQueue = [...state.sendListQueue, action];
        return {
            ...state,
            sendListQueue: newQueue,
        };
    }
    const type = action.wsAction.type ? action.wsAction.type : action.wsAction;
    switch (type) {
        case SEND_WS.CONNECT:
            return {
                ...state,
                matched: {
                    data: null,
                    inProgress: true,
                    reconnect: false,
                },
            };
        case SEND_WS.SEARCH_USER:
            return {
                ...state,
                search: {
                    ...state.search,
                    inProgress: true,
                },
            };
        case SEND_WS.DRAW_OFFER:
            return {
                ...state,
                drawOffer: false,
                waitingDraw: action.data.text,
            };
        case SEND_WS.DRAW_OFFER_RESPONSE:
            return {
                ...state,
                drawOffer: false,
                waitingDraw: '',
            };
        case SEND_WS.NEW_GAME:
            if (action.data.rematch) {
                return {
                    ...state,
                    rematch: true,
                    lastOpponentId: action.data.user,
                    rematchTimeout: false,
                    result: null,
                    drawOffer: false,
                };
            } else {
                return {
                    ...state,
                    lastOpponentId: action.data.user,
                    result: null,
                    waitingFriend: {
                        deny: false,
                        timeout: false,
                        denyReason: '',
                    },
                };
            }
        case SEND_WS.NEW_GAME_INVITE_RESPONSE:
            const isAccepted = action.data.resp === DRAW_OFFER_RESPONSE.ACCEPT;
            const isDeclined = action.data.resp === DRAW_OFFER_RESPONSE.DENY;
            const newChallengeList = isAccepted
                ? []
                : state.challengeList.filter(
                      (challenge) => challenge.user.id !== action.data.user
                  );
            if (isDeclined) {
                if (isDeclined && action.data.rematch) {
                    return {
                        ...state,
                        rematch: false,
                        rematchTimeout: false,
                        challengeList: [],
                        waitingFriend: {
                            deny: true,
                            denyReason: 'rematch_declined',
                        },
                    };
                } else {
                    return {
                        ...state,
                        rematch: false,
                        rematchTimeout: false,
                        challengeList: [],
                        waitingFriend: {
                            deny: true,
                            denyReason: 'deny',
                        },
                        // matched: {
                        //     data: null,
                        //     inProgress: true,
                        // }
                    };
                }
            } else {
                return {
                    ...state,
                    drawOffer: false,
                    waitingDraw: '',
                    lastOpponentId: action.data.user,
                    challengeList: newChallengeList,
                    result: null,
                    rematch: true,
                    rematchTimeout: false,
                };
            }
        case SEND_WS.CANCEL_WAITING:
            return {
                ...state,
                waitingFriend: {
                    deny: false,
                    timeout: false,
                    denyReason: 'cancel',
                },
            };
        case SEND_WS.DISCONNECT_OTB_GAME:
            return {
                ...state,
                spectating: undefined,
                result: undefined,
                serverMove: undefined,
            };
        case SEND_WS.SEND_REACTION:
            const reaction = state.reaction ? '' : action.data.reaction;
            return {
                ...state,
                reaction: {
                    ...state.reaction,
                    reaction: reaction,
                },
            };
        case SEND_WS.DISCONNECT_GAME:
            return {
                ...state,
                matched: {
                    data: null,
                    inProgress: false,
                },
            };
        case SEND_WS.IS_IN_QUEUE:
            return {
                ...state,
                queueCheck: {
                    inProgress: true,
                    startTime: Date.now(),
                    response: null,
                    number: action.data.number,
                }
            };

        default:
            return state;
    }
};

const receiveWsAction = (state, action) => {
    switch (action.type) {
        case RECEIVE_WS.USER_SEARCH_RESULT:
            return {
                ...state,
                search: {
                    inProgress: false,
                    users: action.data.users,
                },
            };
        case RECEIVE_WS.MATCHED:
            const getOpponentID =
                action.data.black.id === state.user.id
                    ? action.data.white.id
                    : action.data.black.id;
            return {
                ...state,
                matched: {
                    data: action.data,
                    inProgress: false,
                },
                result: null,
                rematch: false,
                lastOpponentId: getOpponentID,
                waitingDraw: '',
                lastGameId: action.data.id,
                challengeList: [],
            };
        case RECEIVE_WS.MOVE:
            return {
                ...state,
                serverMove: {
                    move: action.data.move,
                    clock: action.data.clock,
                    fen: action.data.fen,
                    id: action.data.id,
                },
            };
        case RECEIVE_WS.ERROR:
            if (action.data.msg === "Can't invite: Busy") {
                return {
                    ...state,
                    waitingFriend: {
                        ...state.waitingFriend,
                        spam: false,
                        deny: true,
                        denyReason: 'busy',
                    },
                    rematch: false,
                    rematchTimeout: false,
                };
            }

            if (action.data.msg === "Can't invite users") {
                return {
                    ...state,
                    waitingFriend: {
                        ...state.waitingFriend,
                        spam: false,
                        deny: true,
                        denyReason: 'blocked',
                    },
                    rematch: false,
                    rematchTimeout: false,
                };
            }
            if (action.data.msg === "Can't invite: Spam") {
                return {
                    ...state,
                    waitingFriend: {
                        ...state.waitingFriend,
                        spam: true,
                        deny: true,
                        denyReason: 'spam',
                    },
                    rematch: false,
                    rematchTimeout: false,
                };
            }

            return { ...state };
        case RECEIVE_WS.CANCEL_CHALLENGE:
            return {
                ...state,
                waitingFriend: {
                    ...state.waitingFriend,
                    deny: true,
                    denyReason: 'cancelled',
                },
            };
        case RECEIVE_WS.GAME_OVER:
            removeGameIdLS();
			// eslint-disable-next-line
            console.log('reducer', action);

            return {
                ...state,
                serverMove: {
                    move: action.data.move,
                    clock: action.data.clock,
                },
                result: {
                    score: getResultFromServerWinner(action.data.winner),
                    endState: action.data.end,
                    accuracy: action.data.accuracy,
                    allTimeScore: action.data.score,
                    winner: action.data.winner,
                    blackPlayer: action.data.black,
                    whitePlayer: action.data.white,
                    private: action.data.private,
                    rated: action.data.rated,
                    time: action.data.time,
                    player: action.data.player,
                    increment: action.data.increment,
                    gameId: action.data.id,
                    fen: action.data.fen,
                    white_rating_diff: action.data.white_rating_diff,
                    black_rating_diff: action.data.black_rating_diff,
                    variant: action.data.variant,
                },
                matched: {
                    data: null,
                    inProgress: false,
                },
                rematch: false,
                lastGameId: action.data.id,
            };
        case RECEIVE_WS.DRAW_OFFER:
            return {
                ...state,
                drawOffer: true,
                waitingDraw: '',
            };
        case RECEIVE_WS.DRAW_OFFER_RESPONSE:
            return {
                ...state,
                drawOffer: false,
                waitingDraw: '',
            };
        case RECEIVE_WS.CONNECT:
            // Handle game ended on reconnect
            if (
                action.data &&
                action.data.end &&
                action.data.end !== 'in_progress'
            ) {
                return {
                    ...state,
                    result: {
                        score: getResultFromServerWinner(action.data.winner),
                        endState: action.data.end,
                        accuracy: action.data.best_moves,
                        allTimeScore: action.data.score,
                        winner: action.data.winner,
                        blackPlayer: action.data.black,
                        whitePlayer: action.data.white,
                        pgn: action.data.pgn,
                        private: action.data.private
                            ? action.data.private
                            : null,
                        rated: action.data.rated ? action.data.rated : null,
                        time: action.data.time,
                        gameId: action.data.id,
                        player: action.data.player,
                        increment: action.data.increment,
                        white_rating_diff: action.data.white_rating_diff,
                        black_rating_diff: action.data.black_rating_diff,
                        variant: action.data.variant,
                        startFen: action.data.start_fen ? action.data.start_fen : null,
                        uciMoves: action.data.uci_moves ? action.data.uci_moves : null,
                    },
                    matched: {
                        data: null,
                        inProgress: false,
                        reconnect: true,
                        gameEnd: true,
                    },
                };
            }
            return {
                ...state,
                matched: {
                    data: action.data,
                    inProgress: false,
                    reconnectedFen: action.data.fen,
                    reconnectedClock: action.data.clock,
                    reconnect: true,
                },
            };
        case RECEIVE_WS.CONNECTED:
            getIsGuestLS() && setGuestUserLS(JSON.stringify(action.data));

            return {
                ...state,
                user: action.data,
            };
        case RECEIVE_WS.NEW_GAME_INVITE:
            const newChallenge = {
                ...secondsToChessTime(action.data.time),
                increment: action.data.increment,
                user: action.data.user,
                variant: action.data.variant,
                private: action.data.private,
            };
            if (action.data.rematch) {
                return {
                    ...state,
                    rematch: true,
                    rematchTimeout: false,
                    challengeList: [...state.challengeList, newChallenge],
                    lastOpponentId: newChallenge.user.id,
                };
            } else {
                return {
                    ...state,
                    challengeList: [...state.challengeList, newChallenge],
                    lastOpponentId: newChallenge.user.id,
                    waitingFriend: {
                        deny: false,
                        denyReason: '',
                    },
                    rematch: false,
                    // result: null,
                };
            }
        case RECEIVE_WS.GET_OTB_TOURNAMENT_LIST:
            return {
                ...state,
                tournamentList: action.data,
            };
        case RECEIVE_WS.GET_CORRESPONDENCE_GAMES:
            return {
                ...state,
                correspondence_games_list: action.data,
            };
        case RECEIVE_WS.SPECTATE_OTB_GAME:
            return {
                ...state,
                spectating: [action.data],
            };
        case RECEIVE_WS.OTB_MOVE:
            return {
                ...state,
                serverMove: {
                    move: action.data.move,
                    clock: action.data.clock,
                },
            };
        case RECEIVE_WS.RESET_OTB_GAME:
            return {
                ...state,
                otbReset: action.data,
                spectating: [action.data]
            };
        case RECEIVE_WS.OTB_GAME_OVER:
            const score = getResultFromServerWinner(action.data.winner);
            let text = '';

            if (score.white === 1) {
                text = 'White wins!';
            }
            if (score.black === 1) {
                text = 'Black wins!';
            }
            if (score.white === 0.5) {
                text = 'It is a draw!';
            }

            return {
                ...state,
                result: {
                    score,
                    reason: action.data.end,
                    accuracy: action.data.best_moves,
                    id: action.data.id,
                    otb: true,
                    scoreHumanFormat: score.white + ' - ' + score.black,
                    resultText: text,
                    winner: action.data.winner,
                },
                serverMove: {
                    move: action.data.move,
                    clock: action.data.clock,
                },
            };
        case RECEIVE_WS.OTB_GAME_OVER_REST:
            return {
                ...state,
                otherResults: {
                    gameId: action.data.id,
                    score: getResultFromServerWinner(action.data.winner),
                    winner: action.data.winner,
                }
            };
        case RECEIVE_WS.NEW_GAME_INVITE_RESPONSE:
            if (action.data.resp === 'timeout' && state.rematch) {
                return {
                    ...state,
                    waitingFriend: {
                        deny: true,
                        denyReason: 'timeout',
                    },
                    rematch: false,
                    rematchTimeout: true,
                    challengeList: [],
                };
            }

            if (action.data.resp === 'deny' && state.rematch) {
                return {
                    ...state,
                    waitingFriend: {
                        deny: true,
                        denyReason: 'rematch_declined',
                    },
                    challengeList: [],
                    rematch: false,
                    rematchTimeout: false,
                };
            }
            if (action.data.resp === 'timeout') {
                return {
                    ...state,
                    waitingFriend: {
                        deny: true,
                        timeout: true,
                        denyReason: action.data.resp,
                    },
                };
            }

            if (action.data.resp === 'deny') {
                return {
                    ...state,
                    waitingFriend: {
                        deny: true,
                        timeout: false,
                        denyReason: 'deny',
                    },
                    challengeList: [],
                    rematch: false,
                };
            }

            return { ...state };

        case RECEIVE_WS.NEW_GAME_INVITE_FAILED:
            if (action.data.reason === 'chess960_on_mobile') {
                return {
                    ...state,
                    waitingFriend: {
                        deny: true,
                        denyReason: 'chess960_on_mobile',
                    },
                };
            }

            if (action.data.reason === 'offline') {
                return {
                    ...state,
                    waitingFriend: {
                        deny: true,
                        timeout: false,
                        denyReason: 'deny',
                    },
                    challengeList: [],
                    rematch: false,
                };
            }

            return { ...state };

        case RECEIVE_WS.NEW_GAME_TIMEOUT:
            return {
                ...state,
                waitingFriend: {
                    deny: true,
                    timeout: false,
                    denyReason: RECEIVE_WS.NEW_GAME_TIMEOUT,
                },
                challengeList: [],
            };
        case RECEIVE_WS.NEW_REACTION:
            return {
                ...state,
                reaction: {
                    reaction: action.data.reaction,
                    user_id: action.data.user_id,
                },
            };
        case RECEIVE_WS.NOTIFICATION:
            return {
                ...state,
                notificationList: [...state.notificationList, action.data],
            };
        case RECEIVE_WS.LATENCY_PING:
            const data = {
                ...action.data,
                received: Date.now(),
            };

            return {
                ...state,
                latency_ping: data,
            };
        case RECEIVE_WS.PLAYER_LATENCY:
            const resultArr = [...state.player_latency];
            const index = resultArr.findIndex(
                (element) => element.id === action.data.id
            );

            if (index === -1) {
                resultArr.push(action.data);
            } else {
                resultArr.splice(index, 1, action.data);
            }

            return {
                ...state,
                player_latency: [...resultArr],
            };

        case RECEIVE_WS.SPECTATE_GAME_STATUS:
            return {
                ...state,
                spectateGameStatus: action.data,
            };

        case RECEIVE_WS.SPECTATED_USER_NEW_GAME:
            return {
                ...state,
                spectatedUserNewGame: {
                    ...state.spectatedUserNewGame,
                    [action.data.user_id]: action.data,
                },
            };
        case RECEIVE_WS.IS_IN_QUEUE:
            return {
                ...state,
                queueCheck: {
                    inProgress: false,
                    startTime: null,
                    response: action.data,
                    number: state.queueCheck.number,
                }
            };

        default:
            return state;
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.WS_SEND_ACTION:
            return wsSendAction(state, action);
        case constants.WS_RECEIVE_ACTION:
            return receiveWsAction(state, action.data);

        case constants.WS_CONNECTION_REQUEST:
            return {
                ...state,
                connectionInProgress: true,
                connectionAttempts: state.connectionAttempts + 1,
            };
        case constants.WS_CONNECTION_FAILURE:
            return {
                ...state,
                connected: false,
                connectionInProgress: false,
                error: action.error,
                disconnected: true,
                socket: false,
            };
        case constants.WS_CONNECTION_SUCCESS:
            return {
                ...state,
                connected: true,
                connectionInProgress: false,
                disconnected: false,
                connectionAttempts: 0,
                socket: action.socketChannel,
            };
        case constants.WS_DISCONNECT_SUCCESS:
            return { ...state, connected: false, disconnected: true, socket: false, };
        case constants.WS_UNEXPECTED_DISCONNECT:
            return { ...state, connected: false, disconnected: true, socket: false, };

        case constants.WS_CLEAR_QUEUE:
            const newQueue = [...state.sendListQueue];
            delete newQueue[action.index];
            return { ...state, sendListQueue: newQueue };
        case constants.WS_CLEAR_GAME:
            return {
                ...state,
                result: null,
                matched: {
                    data: null,
                    inProgress: true,
                },
            };
        case constants.SET_USER_INTERACTION:
            return {
                ...state,
                friendInteraction: {
                    friend: action.data.isFriend,
                    blocked: action.data.blocked,
                },
            };
        case constants.ADD_AS_FRIEND_SUCCESS:
            return {
                ...state,
                friendInteraction: {
                    ...state.friendInteraction,
                    friend: true,
                },
            };
        case constants.REMOVE_FRIEND_SUCCESS:
            return {
                ...state,
                friendInteraction: {
                    ...state.friendInteraction,
                    friend: false,
                },
            };
        case constants.BLOCK_PLAYER_SUCCESS:
            return {
                ...state,
                friendInteraction: {
                    ...state.friendInteraction,
                    blocked: true,
                },
            };
        case constants.UNBLOCK_PLAYER_SUCCESS:
            return {
                ...state,
                friendInteraction: {
                    ...state.friendInteraction,
                    blocked: false,
                },
            };
        default:
            return state;
    }
};

export default reducer;
