import API from 'API';

const API_LIST = {
    ADD_AS_FRIEND: '/friends',
    UNFRIEND: '/friends/',
};

export function addAsFriend(params) {
    return API.post(API_LIST.ADD_AS_FRIEND, params.payload);
}

export function removeAsFriend(params) {
    return API.delete(API_LIST.UNFRIEND + params.id);
}

export function blockPlayer(params) {
    return API.post(`/friends/${params.id}/action`, params.payload);
}

export function unBlockPlayer(params) {
    return API.post(`/friends/${params.id}/action`, params.payload);
}
