// https://tsuyoshiwada.github.io/react-md-spinner/
// import MDSpinner from 'react-md-spinner';
// import React from 'react';

// const Spinner = ({ size, borderSize, color, style }) => {
//     return (
//         <MDSpinner
// 			style={style}
//             size={size || 42}
//             singleColor={color || "#fff"}
//             borderSize={borderSize || 3}
//         />
//     );
// };

// export default Spinner;

import React from 'react';

import data from 'assets/animations/main_loader.gif';

const Spinner = ({ size, style }) => {
    return (
		<div
			style={{
				...style,
				width: size ? size : '120px',
				height: size ? size : '120px',
				backgroundImage: `url(${data})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
			}}
		/>
	);
};

export default Spinner;
