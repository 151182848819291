import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import API, { getAccesstoken } from 'API';
import { setWsToken } from 'store/user/actions';
import { getUserAccessToken } from 'store/user/selector';
import uuidv4 from 'uuid/v4';

import { auth } from 'shared/utility';
import { getIsGuestLS } from 'shared/localStorage';
import {
    wsConnectionRequest,
    wsConnectionSuccess,
    wsConnectionFailure,
} from 'store/gameWs/actions';

const generateGuestUserDeviceId = () => {
    return uuidv4();
};

const getWSToken = async (token, dispatch) => {
    // TODO Transfer to redux
    try {
        dispatch(wsConnectionRequest());
        const response = await API.get(
            '/notifications/get_ws_token',
            token ? auth(token) : null
        );
        // TODO This should be in away section
        // dispatch(wsConnectionSuccess());
        dispatch(setWsToken(response.data.token));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error while trying to get a token');
        dispatch(wsConnectionFailure());
        // setErrorMessage(error.response && error.response.data.error);
    }
};

export const getGuestWSToken = async (dispatch) => {
    try {
        const additionalParams = {};
        // Take id from localstorage
        let guestUserDeviceId = localStorage.getItem('guestUserDeviceId');
        // If there is no item generate it and put into local storage
        if (!guestUserDeviceId) {
            guestUserDeviceId = generateGuestUserDeviceId();
            localStorage.setItem('guestUserDeviceId', guestUserDeviceId);
        }

        // Add guest device id to the params
        additionalParams.deviceId = guestUserDeviceId;

        dispatch(wsConnectionRequest());
        const response = await API.get('/notifications/get_ws_token', {
            params: additionalParams,
        });

        dispatch(setWsToken(response.data.token));
        dispatch(wsConnectionSuccess());
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error while trying to get a token');
        dispatch(wsConnectionFailure());
        // setErrorMessage(error.response && error.response.data.error);
    }
};

export const getWsTokenWrapper = (dispatch, token) => {
    const isGuest = getIsGuestLS();

    if (token) {
        getWSToken(token, dispatch);
    } else {
        // Do this only if there is no isGuest param already
        if (!isGuest) {
            getAccesstoken().then((response) => {
                // If response failed set guest WS
                if (response && response.response && response.response.status && response.response.status !== 200) {
                    // Get guest token
                    getGuestWSToken(dispatch);
                } else {
                    getWSToken(token, dispatch);
                }
            });
        } else {
            // Get guest token
            getGuestWSToken(dispatch);
        }
    }
};

const TokenWrapper = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getUserAccessToken, shallowEqual);

    // Get new WS token on Access Token change
    useEffect(() => {
        getWsTokenWrapper(dispatch, token);
    }, [dispatch]);

    return <>{props.children}</>;
};

export default TokenWrapper;
