// personal infos
export const SET_FIRST_NAME = 'SET:FIRST_NAME';
export const SET_LAST_NAME = 'SET:LAST_NAME';
export const SET_USERNAME = 'SET:USERNAME';
export const SET_EMAIL = 'SET:EMAIL';
export const SET_CHANGED_PASSWORD = 'SET:CHANGED_PASSWORD';
export const SET_COUNTRY = 'SET:COUNTRY';
export const SET_TIME_ZONE = 'SET:TIME_ZONE';
export const SET_AVATAR = 'SET:AVATAR';

// privacy
export const SET_PRIVACY_CHAT = 'SET:PRIVACY_CHAT';
export const SET_SEE_FRIENDS = 'SET:SEE_FRIENDS';
export const SET_INCOMING_FRIENDS_REQUESTS = 'SET:INCOMING_FRIENDS_REQUESTS';
export const SET_CHALLENGES = 'SET:CHALLENGES';

// notifications
export const SET_DO_NOT_DISTURB = 'SET:DO_NOT_DISTURB';
export const SET_CORRESPONDENCE_TURN = 'SET:CORRESPONDENCE_TURN';
export const SET_NEW_CHALLENGE = 'SET:NEW_CHALLENGE';
export const SET_CORRESPONDENCE_OVER = 'SET:CORRESPONDENCE_OVER';
export const SET_OTB_TOURNAMENT_AVAILABLE = 'SET:OTB_TOURNAMENT_AVAILABLE';
export const SET_LONG_TIME_NO_SEE = 'SET:LONG_TIME_NO_SEE';
export const SET_FRIENDS_REQUESTS = 'SET:FRIENDS_REQUESTS';
export const SET_FIDE_RATING = 'SET:FIDE_RATING';
export const SET_CARD_REJECTED = 'SET:CARD_REJECTED';
export const SET_SUBSCRIPTION_CONFIRMED = 'SET:SUBSCRIPTION_CONFIRMED';
export const SET_NEW_LEARNING_CONTENT = 'SET:NEW_LEARNING_CONTENT';
export const SET_IMPORTANT_BROADCASTING = 'SET:IMPORTANT_BROADCASTING';
export const SET_WEEKLY_NEWS = 'SET:WEEKLY_NEWS';
export const SET_NEW_CONTENT_THEME = 'SET:NEW_CONTENT_THEME';
