import {
    GET_GAME_HISTORY_REQUEST,
    GET_GAME_HISTORY_FAILURE,
    GET_GAME_HISTORY_SUCCESS,
} from './constants';

const initialState = {
    inProgress: false,
    data: [],
    success: null,
    error: false,
    knowsMonth: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_GAME_HISTORY_REQUEST:
            return { ...state, inProgress: true, error: null };
        case GET_GAME_HISTORY_SUCCESS:
            return {
                ...state,
                inProgress: false,
                error: false,
                data: action.data,
                success: true,
                knowsMonth: true,
            };
        case GET_GAME_HISTORY_FAILURE:
            return {
                ...state,
                inProgress: false,
                error: action.error,
                success: false,
            };

        default:
            return state;
    }
};

export default reducer;
