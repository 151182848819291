import * as constants from './constants';

export const getGameSettingsRequest = (data) => {
    return {
        type: constants.GET_GAME_SETTINGS_REQUEST,
        data,
    };
};

export const getGameSettingsSuccess = (data) => {
    return {
        type: constants.GET_GAME_SETTINGS_SUCCESS,
        data,
    };
};

export const getGameSettingsFailure = (data) => {
    return {
        type: constants.GET_GAME_SETTINGS_FAILURE,
        data,
    };
};

