import { SEND_WS } from './wsConstants';
import { chessTimeToSeconds } from 'shared/time';

export const searchNewGameWs = (data) => {
    return {
        type: SEND_WS.NEW_GAME,
        data: {
            variant: data.gameType,
            time: chessTimeToSeconds(data.time),
            increment: data.time.increment,
            private: data.private,
            rated: data.rated,
        },
    };
};

export const cancelNewGameSearchWs = (data = {}) => {
    return {
        type: SEND_WS.CANCEL_WAITING,
        data,
    };
};

export const sendMoveMetricsWs = (id, move, jsonArray) => {
    return {
        type: SEND_WS.MOVE_METRICS,
        data: {
            id: id,
            // listOfMouseSpeed: listOfMouseSpeed,
            move: move,
            jsonArray: jsonArray,
        },
    };
};

export const sendMoveWs = (id, move) => {
    return {
        type: SEND_WS.MOVE,
        data: {
            id: id,
            move: move,
        },
    };
};

export const sendBestMovesWs = (id, move, ply) => {
    return {
        type: SEND_WS.BEST_MOVES,
        data: {
            id: id,
            move: parseInt(ply) - 1,
            best_moves: [move],
        },
    };
};

export const resignWs = (gameId) => {
    return {
        type: SEND_WS.RESIGN,
        data: {
            id: gameId,
        },
    };
};

export const offerDrawWs = (gameId, text) => {
    return {
        type: SEND_WS.DRAW_OFFER,
        data: {
            id: gameId,
            text,
        },
    };
};

export const offerDrawResponseWs = (gameId, response) => {
    return {
        type: SEND_WS.DRAW_OFFER_RESPONSE,
        data: {
            id: gameId,
            resp: response,
        },
    };
};

export const searchUser = (query) => {
    return {
        type: SEND_WS.SEARCH_USER,
        data: {
            query: query,
        },
    };
};

export const challengeFriend = (
    data,
    friendId,
    rematch = false,
    rated,
    publicGame
) => {
    return {
        type: SEND_WS.NEW_GAME,
        data: {
            variant: data.gameType,
            time: chessTimeToSeconds(data.time),
            increment: data.time.increment,
            user: friendId,
            start_fen: data.predefinedFen ? data.predefinedFen : null,
            rematch,
            private: publicGame ? false : data.private,
            rated: rated ? rated : data.rated,
        },
    };
};

export const friendChallengeResponse = (id, response, rematch = false) => {
    return {
        type: SEND_WS.NEW_GAME_INVITE_RESPONSE,
        data: {
            user: id,
            resp: response,
            rematch,
        },
    };
};

export const cancelChallengeFriend = (id) => {
    return {
        type: SEND_WS.CANCEL_WAITING,
        data: {
            user: id,
        },
    };
};

export const rematch = (data, id, rematch) => {
	// eslint-disable-next-line
    console.log('action', data);
    return {
        type: SEND_WS.NEW_GAME,
        data: {
            rematch,
            variant: data.gameType,
            time: chessTimeToSeconds(data.time),
            increment: data.time.increment,
            user: id,
            start_fen: data.predefinedFen ? data.predefinedFen : null,
            private: data.private,
            rated: data.rated,
        },
    };
};

export const sendReaction = (id, reaction, game) => {
    return {
        type: SEND_WS.SEND_REACTION,
        data: {
            to: game,
            id: id,
            reaction,
        },
    };
};

export const reconnect = (id) => {
    return {
        type: SEND_WS.CONNECT,
        data: {
            id: Number.parseInt(id),
        },
    };
};

export const disconnectGame = (id) => {
    return {
        type: SEND_WS.DISCONNECT_GAME,
        data: {
            id: Number.parseInt(id),
        },
    };
};

export const getOTBTournamentList = (data = {}) => {
    return {
        type: SEND_WS.GET_OTB_TOURNAMENT_LIST,
        data,
    };
};

export const getCorrespondenceGameList = () => {
    return {
        type: SEND_WS.GET_CORRESPONDENCE_GAMES,
    };
};

export const spectateOTBGame = (gameId) => {
    return {
        type: SEND_WS.SPECTATE_OTB_GAME,
        data: {
            id: parseInt(gameId),
        },
    };
};

export const disconnectOTBGame = (gameId) => {
    return {
        type: SEND_WS.DISCONNECT_OTB_GAME,
        data: {
            id: parseInt(gameId),
        },
    };
};

export const checkQueue = (number) => {
    return {
        type: SEND_WS.IS_IN_QUEUE,
        data: {number},
    };
};

export const sendLatencyPong = (ping) => {
    const client = ping.start + (Date.now() - ping.received);

    return {
        type: SEND_WS.LATENCY_PONG,
        data: {
            id: ping.id,
            client: client,
        },
    };
};

// Triggering the PING manually
export const triggerPing = () => {
    return {
        type: SEND_WS.LATENCY_PING_TRIGGER,
    };
};

// Asking server for average latency
export const getAverageLatency = () => {
    return {
        type: SEND_WS.LATENCY_AVERAGE,
    };
};

export const spectateMultipleGames = (gameIds) => {
    return {
        type: SEND_WS.SPECTATE_GAMES,
        data: {
            games: gameIds,
        },
    };
};

export const stopSpectatingGames = (gameIds) => {
    return {
        type: SEND_WS.STOP_SPECTATING_GAMES,
        data: {
            games: gameIds,
        },
    };
};
