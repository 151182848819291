import * as constants from './constants';

export const getCurrentProfileRequest = (id) => {
    return {
        type: constants.GET_CURRENT_USER_REQUEST,
        id,
    };
};

export const getCurrentUserProfileSuccess = (data) => {
    return {
        type: constants.GET_CURRENT_USER_SUCCESS,
        data,
    };
};

export const getCurrentUserProfileFailiure = (error) => {
    return {
        type: constants.GET_CURRENT_USER_FAILURE,
        error,
    };
};

export const getUserProfileRequest = (id) => {
    return {
        type: constants.GET_USER_REQUEST,
        id,
    };
};

export const setUserColors = (data) => {
    return {
        type: constants.SET_USER_COLORS,
        data,
    };
};

export const getUserProfileSuccess = (data) => {
    return {
        type: constants.GET_USER_SUCCESS,
        data,
    };
};

export const getUserProfileFailiure = (error) => {
    return {
        type: constants.GET_USER_FAILURE,
        error,
    };
};

export const addAFriendRequest = (data, username) => {
    return {
        type: constants.ADD_A_FRIEND_REQUEST,
        data,
        username,
    };
};

export const addAFriendSuccess = (username) => {
    return {
        type: constants.ADD_A_FRIEND_SUCCESS,
        username,
    };
};

export const addAFriendFailure = (error) => {
    return {
        type: constants.ADD_A_FRIEND_FAILURE,
        error,
    };
};

export const removeAsFriendRequest = (userId, username) => {
    return {
        type: constants.REMOVE_A_FRIEND_REQUEST,
        userId,
        username,
    };
};

export const removeAsFriendSuccess = (username) => {
    return {
        type: constants.REMOVE_A_FRIEND_SUCCESS,
        username,
    };
};

export const removeAsFriendFailure = (error) => {
    return {
        type: constants.REMOVE_A_FRIEND_FAILURE,
        error,
    };
};

export const getMyBadgesRequest = () => {
    return {
        type: constants.GET_MY_BADGES_REQUEST,
    };
};

export const getMyBadgesSuccess = (data) => {
    return {
        type: constants.GET_MY_BADGES_SUCCESS,
        data,
    };
};

export const getMyBadgesFailure = () => {
    return {
        type: constants.GET_MY_BADGES_FAILURE,
    };
};

export const getProfileBadgesRequest = (id) => {
    return {
        type: constants.GET_PROFILE_BADGES_REQUEST,
        id,
    };
};

export const getProfileBadgesSuccess = (data) => {
    return {
        type: constants.GET_PROFILE_BADGES_SUCCESS,
        data,
    };
};

export const getProfileBadgesFailure = (id) => {
    return {
        type: constants.GET_PROFILE_BADGES_FAILURE,
        id,
    };
};
