import PropTypes from 'prop-types';
import React from 'react';

import styles from './PrimaryButton.module.scss';

const PrimaryButton = ({
    disabled,
    clicked,
    children,
    active,
    style,
    className,
}) => {
    const btnStyle = [styles.primary_button];
    if (active) {
        btnStyle.push(styles.active);
    }
    if (className) {
        btnStyle.push(className);
    }

    return (
        <button
            disabled={disabled}
            className={btnStyle.join(' ')}
            onClick={clicked}
            style={style && style}
        >
            {/* Text inside button */}
            {children}
        </button>
    );
};

PrimaryButton.propTypes = {
    disabled: PropTypes.bool,
    clicked: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array])
        .isRequired,
    active: PropTypes.bool,
    style: PropTypes.object,
};

PrimaryButton.defaultProps = {
    disabled: false,
};

export default PrimaryButton;
