import * as constants from './constants';

export const redirect = (url = '/', hard = false) => {
    return {
        type: constants.REDIRECT,
        url,
        hard,
    };
};

export const setTableHeight = (height) => {
    return {
        type: constants.SET_TABLE_HEIGHT,
        height,
    };
};
