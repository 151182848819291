import { useEffect } from 'react';

// Fn for unmutable object cloning
export const updateObject = (oldObject, newProps) => {
    return {
        ...oldObject,
        ...newProps,
    };
};

// Seconds to HH:mm:ss
export const toHHMMSS = (seconds) => {
    return new Date(seconds * 1000).toISOString().substr(11, 8);
};

export const truncate = (str, n) => {
    return str.length > n ? `${str.substr(0, n - 1)}...` : str;
};

// Validation check Fn
export const checkValidity = (value, rules) => {
    const val = {
        isValid: true,
        message: '',
    };

    if (!rules) {
        return val;
    }

    if (rules.required) {
        val.isValid = value.trim() !== '' && val.isValid;
        if (val.message === '' && !val.isValid) {
            val.message = 'util.required_data';
        }
    }

    // if (rules.isNumeric) {
    // 	const pattern = /^-?[0-9]+$/;
    // 	val.isValid = pattern.test(value) && val.isValid;
    // 	if (val.message === '' && !val.isValid) {
    // 		val.message = 'Numbers only';
    // 	}
    // }

    // if (rules.isPositiveNumber) {
    // 	const pattern = /^[0-9]+$/;
    // 	val.isValid = pattern.test(value) && val.isValid && value >= 0;
    // 	if (val.message === '' && !val.isValid) {
    // 		val.message = 'Positive numbers only';
    // 	}
    // }

    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        val.isValid = pattern.test(value) && val.isValid;
        if (val.message === '' && !val.isValid) {
            val.message = 'util.valid_email';
        }
    }

    if (rules.isPassword) {
        const pattern = RegExp(
            `^(?=.*[0-9~!@#$%^&*]).{${rules.minLength || 8},${rules.maxLength ||
                20}}$`
        );
        val.isValid = pattern.test(value) && val.isValid;
        if (val.message === '' && !val.isValid) {
            val.message = 'util.valid_password';
        }
    }

    if (rules.isNoSpace) {
        const pattern = /^[\S]+$/;
        val.isValid = pattern.test(value) && val.isValid;
        if (val.message === '' && !val.isValid) {
            val.message = 'util.user_username_contains_spaces';
        }
	}

    if (rules.isNoSpecial) {
        const pattern = /^[a-zA-Z0-9.'_-]+$/;
        val.isValid = pattern.test(value) && val.isValid;
        if (val.message === '' && !val.isValid) {
            val.message = 'util.user_username_contains_special_characters';
        }
    }

    if (rules.isName) {
        const pattern = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;
        val.isValid = pattern.test(value) && val.isValid;
        if (val.message === '' && !val.isValid) {
            val.message = 'util.valid_name';
        }
    }

    // if (rules.isPhone) {
    // 	const pattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    // 	val.isValid = pattern.test(value) && val.isValid;
    // 	if (val.message === '' && !val.isValid) {
    // 		val.message = 'Please enter a valid phone number';
    // 	}
    // }

    if (rules.minLength) {
        val.isValid = value.length >= rules.minLength && val.isValid;
        if (val.message === '' && !val.isValid) {
            val.message = 'util.min_length';
        }
    }

    if (rules.maxLength) {
        val.isValid = value.length <= rules.maxLength && val.isValid;
        if (val.message === '' && !val.isValid) {
            val.message = 'util.max_length';
        }
    }

    if (rules.minValue) {
        val.isValid = parseInt(value) >= rules.minValue && val.isValid;
        if (val.message === '' && !val.isValid) {
            val.message = 'util.min_value';
        }
    }

    if (rules.maxValue) {
        val.isValid = parseInt(value) <= rules.maxValue && val.isValid;
        if (val.message === '' && !val.isValid) {
            val.message = 'util.max_value';
        }
    }

    return val;
};

export const auth = (token) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};

// export const Base64 = {
//     _keyStr:
//         'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',

//     encode: (input) => {
//         let output = '';
//         let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
//         let i = 0;

//         input = Base64._utf8_encode(input);

//         while (i < input.length) {
//             chr1 = input.charCodeAt(i++);
//             chr2 = input.charCodeAt(i++);
//             chr3 = input.charCodeAt(i++);

//             enc1 = chr1 >> 2;
//             enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
//             enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
//             enc4 = chr3 & 63;

//             if (isNaN(chr2)) {
//                 enc3 = enc4 = 64;
//             } else if (isNaN(chr3)) {
//                 enc4 = 64;
//             }

//             output =
//                 output +
//                 Base64._keyStr.charAt(enc1) +
//                 Base64._keyStr.charAt(enc2) +
//                 Base64._keyStr.charAt(enc3) +
//                 Base64._keyStr.charAt(enc4);
//         }

//         return output;
//     },

//     decode: (input) => {
//         let output = '';
//         let chr1, chr2, chr3;
//         let enc1, enc2, enc3, enc4;
//         let i = 0;

//         input = input.replace(/[^A-Za-z0-9+/=]/g, '');

//         while (i < input.length) {
//             enc1 = Base64._keyStr.indexOf(input.charAt(i++));
//             enc2 = Base64._keyStr.indexOf(input.charAt(i++));
//             enc3 = Base64._keyStr.indexOf(input.charAt(i++));
//             enc4 = Base64._keyStr.indexOf(input.charAt(i++));

//             chr1 = (enc1 << 2) | (enc2 >> 4);
//             chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
//             chr3 = ((enc3 & 3) << 6) | enc4;

//             output = output + String.fromCharCode(chr1);

//             if (enc3 !== 64) {
//                 output = output + String.fromCharCode(chr2);
//             }
//             if (enc4 !== 64) {
//                 output = output + String.fromCharCode(chr3);
//             }
//         }

//         output = Base64._utf8_decode(output);

//         return output;
//     },

//     _utf8_encode: (string) => {
//         string = string.replace(/\r\n/g, '\n');
//         let utftext = '';

//         for (let n = 0; n < string.length; n++) {
//             const c = string.charCodeAt(n);

//             if (c < 128) {
//                 utftext += String.fromCharCode(c);
//             } else if (c > 127 && c < 2048) {
//                 utftext += String.fromCharCode((c >> 6) | 192);
//                 utftext += String.fromCharCode((c & 63) | 128);
//             } else {
//                 utftext += String.fromCharCode((c >> 12) | 224);
//                 utftext += String.fromCharCode(((c >> 6) & 63) | 128);
//                 utftext += String.fromCharCode((c & 63) | 128);
//             }
//         }

//         return utftext;
//     },

//     _utf8_decode: (utftext) => {
//         let string = '';
//         let i = 0;
//         let c,
//             c2,
//             c3 = 0;

//         while (i < utftext.length) {
//             c = utftext.charCodeAt(i);

//             if (c < 128) {
//                 string += String.fromCharCode(c);
//                 i++;
//             } else if (c > 191 && c < 224) {
//                 c2 = utftext.charCodeAt(i + 1);
//                 string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
//                 i += 2;
//             } else {
//                 c2 = utftext.charCodeAt(i + 1);
//                 c3 = utftext.charCodeAt(i + 2);
//                 string += String.fromCharCode(
//                     ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
//                 );
//                 i += 3;
//             }
//         }

//         return string;
//     },
// };

export const emptyFunction = () => {};

// get browser name, version and OS
export const getBrowserInfo = () => {
    // eslint-disable-next-line prefer-const
    let unknown = '-';

    // screen
    let screenSize = '';
    if (
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
    ) {
        const width =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth
                ? window.innerWidth
                : '';
        const height =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight
                ? window.innerHeight
                : '';
        screenSize += '' + width + ' x ' + height;
    }

    // browser
    const nVer = navigator.appVersion;
    const nAgt = navigator.userAgent;
    let browser = navigator.appName;
    let version = '' + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset, verOffset, ix;

    // Opera
    if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
        browser = 'Opera';
        version = nAgt.substring(verOffset + 6);
        if ((verOffset = nAgt.indexOf('Version')) !== -1) {
            version = nAgt.substring(verOffset + 8);
        }
    }
    // Opera Next
    if ((verOffset = nAgt.indexOf('OPR')) !== -1) {
        browser = 'Opera';
        version = nAgt.substring(verOffset + 4);
    }
    // Legacy Edge
    else if ((verOffset = nAgt.indexOf('Edge')) !== -1) {
        browser = 'Microsoft Legacy Edge';
        version = nAgt.substring(verOffset + 5);
    }
    // Edge (Chromium)
    else if ((verOffset = nAgt.indexOf('Edg')) !== -1) {
        browser = 'Microsoft Edge';
        version = nAgt.substring(verOffset + 4);
    }
    // MSIE
    else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
        browser = 'Microsoft Internet Explorer';
        version = nAgt.substring(verOffset + 5);
    }
    // Chrome
    else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
        browser = 'Chrome';
        version = nAgt.substring(verOffset + 7);
    }
    // Safari
    else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
        browser = 'Safari';
        version = nAgt.substring(verOffset + 7);
        if ((verOffset = nAgt.indexOf('Version')) !== -1) {
            version = nAgt.substring(verOffset + 8);
        }
    }
    // Firefox
    else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
        browser = 'Firefox';
        version = nAgt.substring(verOffset + 8);
    }
    // MSIE 11+
    else if (nAgt.indexOf('Trident/') !== -1) {
        browser = 'Microsoft Internet Explorer';
        version = nAgt.substring(nAgt.indexOf('rv:') + 3);
    }
    // Other browsers
    else if (
        (nameOffset = nAgt.lastIndexOf(' ') + 1) <
        (verOffset = nAgt.lastIndexOf('/'))
    ) {
        browser = nAgt.substring(nameOffset, verOffset);
        version = nAgt.substring(verOffset + 1);
        if (browser.toLowerCase() === browser.toUpperCase()) {
            browser = navigator.appName;
        }
    }
    // trim the version string
    if ((ix = version.indexOf(';')) !== -1) {
        version = version.substring(0, ix);
    }
    if ((ix = version.indexOf(' ')) !== -1) {
        version = version.substring(0, ix);
    }
    if ((ix = version.indexOf(')')) !== -1) {
        version = version.substring(0, ix);
    }

    majorVersion = parseInt('' + version, 10);
    if (isNaN(majorVersion)) {
        version = '' + parseFloat(navigator.appVersion);
        majorVersion = parseInt(navigator.appVersion, 10);
    }

    // mobile version
    const mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

    // cookie
    let cookieEnabled = navigator.cookieEnabled ? true : false;

    if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
        document.cookie = 'testcookie';
        cookieEnabled =
            document.cookie.indexOf('testcookie') !== -1 ? true : false;
    }

    // system
    let os = unknown;
    const clientStrings = [
        { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
        { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
        { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
        { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
        { s: 'Windows Vista', r: /Windows NT 6.0/ },
        { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
        { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
        { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
        { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
        { s: 'Windows 98', r: /(Windows 98|Win98)/ },
        { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
        {
            s: 'Windows NT 4.0',
            r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/,
        },
        { s: 'Windows CE', r: /Windows CE/ },
        { s: 'Windows 3.11', r: /Win16/ },
        { s: 'Android', r: /Android/ },
        { s: 'Open BSD', r: /OpenBSD/ },
        { s: 'Sun OS', r: /SunOS/ },
        { s: 'Chrome OS', r: /CrOS/ },
        { s: 'Linux', r: /(Linux|X11(?!.*CrOS))/ },
        { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
        { s: 'Mac OS X', r: /Mac OS X/ },
        { s: 'Mac OS', r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
        { s: 'QNX', r: /QNX/ },
        { s: 'UNIX', r: /UNIX/ },
        { s: 'BeOS', r: /BeOS/ },
        { s: 'OS/2', r: /OS\/2/ },
        {
            s: 'Search Bot',
            r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
        },
    ];
    for (const id in clientStrings) {
        const cs = clientStrings[id];
        if (cs.r.test(nAgt)) {
            os = cs.s;
            break;
        }
    }

    let osVersion = unknown;

    if (/Windows/.test(os)) {
        osVersion = /Windows (.*)/.exec(os)[1];
        os = 'Windows';
    }

    switch (os) {
        case 'Mac OS':
        case 'Mac OS X':
        case 'Android':
            // eslint-disable-next-line no-useless-escape
            osVersion = /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([\.\_\d]+)/.exec(
                nAgt
            )[1];
            break;

        case 'iOS':
            osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
            osVersion =
                osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
            break;
        default:
    }

    return {
        screen: screenSize,
        browser: browser,
        browserVersion: version,
        browserMajorVersion: majorVersion,
        mobile: mobile,
        os: os,
        osVersion: osVersion,
        cookies: cookieEnabled,
    };
};

export const useClickOutside = (ref, handler) => {
	useEffect(() => {
		const listener = (e) => {
			if (!ref.current || ref.current.contains(e.target)) {
				return;
			}

			handler(e);
		};

		document.addEventListener('click', listener);

		return () => {
			document.removeEventListener('click', listener);
		};
	}, [ref, handler]);
};
