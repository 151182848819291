import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { PropTypes } from 'prop-types';
import { CometChat } from '@cometchat-pro/chat';

import * as actions from 'store/user/actions';
import * as notifications from 'store/notifications/actions';

import {
    facebookSDK,
    googleSDK,
    appleSDK,
    cometChatSDK,
    usersnapSDK,
} from './SDK';

import Layout from 'containers/Layout/Layout';
import WSWrapper from 'components/Wrappers/WSWrapper';
import TokenWrapper from 'components/Wrappers/TokenWrapper';
import RouterWrapper from 'components/Wrappers/RouterWrapper';
import ScreenSizeWrapper from 'components/Wrappers/ScreenSizeWrapper';
import ToastWrapper from 'components/Wrappers/ToastWrapper/ToastWrapper';
import Helmet from 'components/Helmet/Helmet';

import version from './version';
import messages from 'assets/translations/messages';
import { ModalsProvider } from 'context/modal/ModalsProvider';

import { modalRegistrator } from 'modalRegistartor';

const App = ({ store, getUser, lang, getChatAuthTokenRequest, ...props }) => {
    window.keyPush = props.history.push;
    const [auth2, setAuth2] = useState(null);
    useEffect(() => {
        store.getState().user.accessToken && getUser();
        store.getState().user.accessToken &&
            getChatAuthTokenRequest(store.getState().user.accessToken);
        googleSDK(setAuth2);
        facebookSDK(lang);
        appleSDK();
        cometChatSDK(CometChat);
        usersnapSDK();

        // Print version in the console
        console.log('***********'); // eslint-disable-line no-console
        console.log('App Version'); // eslint-disable-line no-console
        console.log(version); // eslint-disable-line no-console
        console.log('***********'); // eslint-disable-line no-console

        if (process.env.REACT_APP_IS_PRODUCTION) {
            window.console.log = () => {};
            window.console.warn = () => {};
            window.console.info = () => {};
            window.console.error = () => {};
        }
    }, [lang]);

    return (
        <>
            <Helmet
                title="K-chess"
                metaDesc="Elevate your chess game with Garry Kasparov masterclass, lessons from grandmasters, entertaining documentaries and interviews."
            />
            <IntlProvider locale={lang} messages={messages[lang]}>
                <Provider store={store}>
                    <TokenWrapper>
                        <WSWrapper>
                            <ModalsProvider modals={modalRegistrator}>
                                <ToastWrapper>
                                    <Layout>
                                        <ScreenSizeWrapper>
                                            <RouterWrapper auth2={auth2} />
                                        </ScreenSizeWrapper>
                                    </Layout>
                                </ToastWrapper>
                            </ModalsProvider>
                        </WSWrapper>
                    </TokenWrapper>
                </Provider>
            </IntlProvider>
        </>
    );
};

App.propTypes = {
    guest: PropTypes.bool,
    lang: PropTypes.string.isRequired,
    logout: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        guest: state.user.guestUser,
        lang: state.locale.lang,
        notifications: state.notifications,
    };
};

const mapDispatchToProps = {
    getUser: (props) => actions.getUser(props),
    getChatAuthTokenRequest: (props) => actions.getChatAuthTokenRequest(props),
    logout: (props) => actions.logout(props),
    notifications: (props) => notifications(props),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
