import { getWsTokenLS, getUserLS } from 'shared/localStorage';

const getUserBaseReducer = (state) => state.user;
export const getLoginRequest = (state) => state.user.loginRequest;
export const getUser = (state) => {
    if (state.user.user) {
        return state.user.user;
    }
    const lsUser = getUserLS();
    if (lsUser) {
        return JSON.parse(lsUser);
    }

    return null;
    // TODO At some point this should return guest info
    // return JSON.parse(getGuestUserLS());
};
export const getUserColors = (state) => state.user.userColors;
export const getRegisterRequest = (state) => state.user.registerRequest;
export const getUsernameCheckRequest = (state) =>
    state.user.checkUsernameRequest;
export const getEmailCheckRequest = (state) => state.user.checkEmailRequest;
export const startPasswordRecoveryRequest = (state) =>
    state.user.startPasswordRecoveryRequest;
export const recoverPasswordRequest = (state) =>
    state.user.recoverPasswordRequest;

export const getUserAccessToken = (state) => {
    const token =
        getUserBaseReducer(state) && getUserBaseReducer(state).accessToken;
    return token;
};
export const getIsGuestUser = (state) => getUserBaseReducer(state).guestUser;
export const getUserWSToken = (state) => {
    if (!state) {
        return getWsTokenLS();
    }
    const token = getUserBaseReducer(state).wsToken;
    return token ? token : getWsTokenLS();
};

export const getReactionStatus = (state) =>
    getUserBaseReducer(state).showReaction;
export const setUserDataStatus = (state) =>
    getUserBaseReducer(state).setUserState;
export const getUserRedirectAfterLogin = (state) =>
    getUserBaseReducer(state).redirectAfterLogin;
