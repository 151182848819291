import * as constants from './constants';

export const getSubscriptionPlansRequest = () => {
    return {
        type: constants.GET_PLANS_REQUEST,
    };
};

export const getSubscriptionPlansSuccess = (data) => {
    return {
        type: constants.GET_PLANS_SUCCESS,
        data,
    };
};

export const getSubscriptionPlansFailure = (error) => {
    return {
        type: constants.GET_PLANS_FAILURE,
        error,
    };
};

export const setSubscriptionPlan = (data) => {
    return {
        type: constants.SET_PLAN,
		data
    };
};
