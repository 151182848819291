import React from 'react';
import { useHistory } from 'react-router-dom';
import PrimaryButton from 'UI/PrimaryButton/PrimaryButton';
import styles from './IndividualAppPurchaseCta.module.scss';
import { useDispatch } from 'react-redux';
import Spinner from 'UI/Spinner/Spinner';
import { addToast } from 'store/toast/actions';
import { loadStripe } from '@stripe/stripe-js';

import API from 'API';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const entityContent = {
    common: {
        title:
            'Want more from K-chess? Unlock this lesson or all our training suite!',
        subtitle:
            'Reach the top of your game with our documentaries, podcasts, interviews, lessons, masterclasses, puzzles.',
        text: 'Subscribe to unlock all K-chess',
        learnMore: 'Learn more',
        or: 'or',
    },
};

const formatter = (currency) => {
    switch (currency?.toLowerCase()) {
        case 'usd': {
            return '$';
        }
        case 'eur': {
            return '€';
        }
        case 'inr': {
            return '₹';
        }
        default: {
            return 'Missing currency!';
        }
    }
};

const printPrice = (price, currency) => {
    const symbolFirst = currency.toLowerCase() === 'usd';

    return symbolFirst
        ? `${formatter(currency)}${price}`
        : `${price}${formatter(currency)}`;
};

export default function IndividualAppPurchaseCta({
    onCloseModal,
    entityId,
    entity,
    price,
    currency,
    finalUrl,
    entityText,
    learnMoreText,
    subscribeText,
}) {
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const handlePriceClick = async () => {
        setLoading(true);

        try {
            const response = await API.post('/products/purchase', {
                id: entityId,
                entity,
                final_url: finalUrl,
            });

            if (response?.data?.data?.id) {
                const stripe = await stripePromise;
                await stripe.redirectToCheckout({
                    sessionId: response.data.data.id,
                });
            }
            setLoading(false);
        } catch (error) {
            if (
                error?.response?.status === 400 ||
                error?.response?.status === 401
            ) {
                history.push('/onboarding');
                onCloseModal && onCloseModal();
            } else {
                dispatch(
                    addToast({
                        type: 'error',
                        text: {
                            title:
                                'Sorry, something went wrong. Please try again later.',
                        },
                        durationInSeconds: 7,
                    })
                );
                setLoading(false);
            }
        }
    };

    const handleLearnModeClick = () => {
        history.push('/upgrade-account');
        onCloseModal && onCloseModal();
    };

    return (
        <div className={styles.content_main}>
            <div className={styles.content}>
                <p className={styles.content_text}>{entityText}</p>
                <div className={styles.vertical_divider} />
                <p className={styles.content_text}>
                    <PrimaryButton
                        className={styles.button}
                        clicked={() => {
                            if (!loading) {
                                handlePriceClick();
                            }
                        }}
                        disabled={loading}
                    >
                        {loading ? (
                            <Spinner size={40} />
                        ) : (
                            printPrice(price, currency)
                        )}
                    </PrimaryButton>
                </p>
            </div>
            <div className={styles.content_divider}>
                {entityContent.common.or}
            </div>
            <div className={`${styles.content} ${styles.content_light}`}>
                <p className={styles.content_text}>
                    {subscribeText || entityContent.common.text}
                </p>
                <div className={styles.vertical_divider} />
                <p className={styles.content_text}>
                    <PrimaryButton
                        className={styles.button}
                        clicked={handleLearnModeClick}
                    >
                        {learnMoreText || entityContent.common.learnMore}
                    </PrimaryButton>
                </p>
            </div>
        </div>
    );
}
