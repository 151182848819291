// game settings
export const GET_GAME_SETTINGS_REQUEST = 'GET:GAME_SETTINGS_REQUEST';
export const GET_GAME_SETTINGS_SUCCESS = 'GET:GAME_SETTINGS_SUCCESS';
export const GET_GAME_SETTINGS_FAILURE = 'GET:GAME_SETTINGS_FAILURE';

export const SET_GAME_SETTINGS_REQUEST = 'SET:GAME_SETTINGS_REQUEST';
export const SET_GAME_SETTINGS_SUCCESS = 'SET:GAME_SETTINGS_SUCCESS';
export const SET_GAME_SETTINGS_FAILURE = 'SET:GAME_SETTINGS_FAILURE';

// privacy settings
export const GET_PRIVACY_REQUEST = 'GET:PRIVACY_REQUEST';
export const GET_PRIVACY_SUCCESS = 'GET:PRIVACY_SUCCESS';
export const GET_PRIVACY_FAILURE = 'GET:PRIVACY_FAILURE';

export const SET_PRIVACY_REQUEST = 'SET:PRIVACY_REQUEST';
export const SET_PRIVACY_SUCCESS = 'SET:PRIVACY_SUCCESS';
export const SET_PRIVACY_FAILURE = 'SET:PRIVACY_FAILURE';

export const SET_ENABLE_PREMOVES = 'SET:ENABLE_PREMOVES';
export const SET_PROMOTION_QUEEN = 'SET:PROMOTION_QUEEN';
export const SET_LOW_TIME_WARNING = 'SET:LOW_TIME_WARNING';
export const SET_INCOMING_CHALLENGE = 'SET:INCOMING_CHALLENGE';
export const SET_OFF_LINE_CHALLENGE = 'SET:OFF_LINE_CHALLENGE';
export const SET_LIVE_CHESS_CHAT = 'SET:LIVE_CHESS_CHAT';
export const SET_DRAW_ARROWS = 'SET:DRAW_ARROWS';
export const SET_EVENTS = 'SET:EVENTS';

// daily settings
export const GET_DAILY_REQUEST = 'GET:GET_DAILY_REQUEST';
export const GET_DAILY_SUCCESS = 'GET:GET_DAILY_SUCCESS';
export const GET_DAILY_FAILURE = 'GET:GET_DAILY_FAILURE';

export const SET_PAUSE_GAMES = 'SET:PAUSE_GAMES';
export const SET_MAXIMUM_NUMBER_GAMES = 'SET:MAXIMUM_NUMBER_GAMES';
export const SET_AUTOMATIC_CLAIM_VICTORY = 'SET:AUTOMATIC_CLAIM_VICTORY';
export const SET_FRIEND_CHALLENGE = 'SET:FRIEND_CHALLENGE';
export const SET_DAILY_CHESS_CHAT = 'SET:DAILY_CHESS_CHAT';

// notification settings
export const GET_NOTIFICATION_SETTINGS_REQUEST =
    'GET:NOTIFICATION_SETTINGS_REQUEST';
export const GET_NOTIFICATION_SETTINGS_SUCCESS =
    'GET:NOTIFICATION_SETTINGS_SUCCESS';
export const GET_NOTIFICATION_SETTINGS_FAILURE =
    'GET:NOTIFICATION_SETTINGS_FAILURE';

export const SET_NOTIFICATION_SETTINGS_REQUEST =
    'SET:NOTIFICATION_SETTINGS_REQUEST';
export const SET_NOTIFICATION_SETTINGS_SUCCESS =
    'SET:NOTIFICATION_SETTINGS_SUCCESS';
export const SET_NOTIFICATION_SETTINGS_FAILURE =
    'SET:NOTIFICATION_SETTINGS_FAILURE';
