export const GET_CURRENT_USER_REQUEST = 'USER_PROFILE:GET_CURRENT_USER_REQUEST';
export const GET_CURRENT_USER_FAILURE = 'USER_PROFILE:GET_CURRENT_USER_FAILURE';
export const GET_CURRENT_USER_SUCCESS = 'USER_PROFILE:GET_CURRENT_USER_SUCCESS';

export const GET_MY_BADGES_REQUEST = 'USER_PROFILE:GET_MY_BADGES_REQUEST';
export const GET_MY_BADGES_SUCCESS = 'USER_PROFILE:GET_MY_BADGES_SUCCESS';
export const GET_MY_BADGES_FAILURE = 'USER_PROFILE:GET_MY_BADGES_FAILURE';

export const GET_PROFILE_BADGES_REQUEST =
    'USER_PROFILE:GET_PROFILE_BADGES_REQUEST';
export const GET_PROFILE_BADGES_SUCCESS =
    'USER_PROFILE:GET_PROFILE_BADGES_SUCCESS';
export const GET_PROFILE_BADGES_FAILURE =
    'USER_PROFILE:GET_PROFILE_BADGES_FAILURE';

export const GET_USER_REQUEST = 'USER_PROFILE:GET_USER_REQUEST';
export const GET_USER_FAILURE = 'USER_PROFILE:GET_USER_FAILURE';
export const GET_USER_SUCCESS = 'USER_PROFILE:GET_USER_SUCCESS';

export const ADD_A_FRIEND_REQUEST = 'USER_PROFILE:ADD_A_FRIEND_REQUEST';
export const ADD_A_FRIEND_SUCCESS = 'USER_PROFILE:ADD_A_FRIEND_SUCCESS';
export const ADD_A_FRIEND_FAILURE = 'USER_PROFILE:ADD_A_FRIEND_FAILURE';

export const REMOVE_A_FRIEND_REQUEST = 'USER_PROFILE:REMOVE_A_FRIEND_REQUEST';
export const REMOVE_A_FRIEND_SUCCESS = 'USER_PROFILE:REMOVE_A_FRIEND_SUCCESS';
export const REMOVE_A_FRIEND_FAILURE = 'USER_PROFILE:REMOVE_A_FRIEND_FAILURE';

export const SET_USER_COLORS = 'USER_PROFILE:SET_USER_COLORS';
