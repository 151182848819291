import { getUserLS } from 'shared/localStorage';
import STRAPI from 'API_STRAPI';

export const shouldOpenPurchaseModal = async (
    episode,
    contextModals,
    cb,
    finalUrl = null,
    search
) => {
    const me = JSON.parse(getUserLS());

    if (!me) {
        return cb();
    }

    if (episode.hasOwnProperty('iap')) {
        return contextModals.openContextModal('inAppPurchaseModal', {
            innerProps: {
                price: episode.iap.currentUserPrice.price,
                currency: episode.iap.currentUserPrice.currency,
                entityId: episode.iap.entityId,
                entity: episode.iap.entity,
                finalUrl,
                onCloseModal: episode?.onCloseModal,
            },
        });
    } else if (search) {
        const response = await getInAppPurchaseIap({ id: episode.id, entity: search});

        const iap = response.data.data

        return contextModals.openContextModal('inAppPurchaseModal', {
            innerProps: {
                price: iap.currentUserPrice.price,
                currency: iap.currentUserPrice.currency,
                entityId: iap.entityId,
                entity: iap.entity,
                finalUrl,
                onCloseModal: episode?.onCloseModal,
            },
        });
    } else {

        return cb();
    }

};

export async function getInAppPurchaseIap({ id, entity }) {
    const body = {
        id,
        entity,
    };

    const response = await STRAPI.post(
        'https://backend.dev.keysquare.io/products/get-iap',
        JSON.stringify(body)
    );

    return response;
}
