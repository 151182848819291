import { takeLatest, put as dispatch } from 'redux-saga/effects';

import * as actions from './actions';
import * as api from './api';
import * as constants from './constants';

export function* subscriptionSaga() {
    yield takeLatest(constants.GET_PLANS_REQUEST, getSubscriptionPlansRequest);
}

function* getSubscriptionPlansRequest() {
    try {
        const response = yield api.getSubscriptionPlans();
        yield dispatch(actions.getSubscriptionPlansSuccess(response.data.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('getSubscriptionPlans failed', e);
        yield dispatch(actions.getSubscriptionPlansFailure(e));
    }
}
