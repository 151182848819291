import { PLAYER_COLOR, PLAYER_COLOR_SHORT_MAPPER } from 'store/game/constants';
// import { getUserLS } from 'shared/localStorage';
import { USER_ROLES } from 'shared/userRoles';
import { replaceLetterInString } from './string';

// let myInfo = JSON.parse(getUserLS());

export const ANIMATION_DURATION = 100;

export const ENDGAME_STATUS = {
    CHECKMATE: 'checkmate',
    STALEMATE: 'stalemate',
    AUTODRAW: 'autodraw',
    VARIANT_END: 'variant_end',
    TIMEOUT: 'timeout',
    RESIGNED: 'resigned',
    DRAW: 'draw',
    THREEFOLD_REPETITION: 'threefold_repetition',
    INSUFFICIENT_MATERIAL: 'insufficient_material',
    FIFTY_MOVES_RULE: 'fifty_moves_rule',
};

export const getResultFromServerWinner = (winner) => {
    switch (winner) {
        case PLAYER_COLOR.WHITE:
            return { white: 1, black: 0 };
        case PLAYER_COLOR.BLACK:
            return { white: 0, black: 1 };
        default:
            return { white: 0.5, black: 0.5 };
    }
};

export const getPlayerWonPointsFromResult = (result, playerColor) => {
    return playerColor === PLAYER_COLOR.WHITE ? result : 1 - result;
};

function generate960StartPosition() {
    const rank = new Array(8),
        // randomizer (our die)
        d = function(num) {
            return Math.floor(Math.random() * ++num);
        },
        emptySquares = function() {
            const arr = [];
            for (let i = 0; i < 8; i++) {
                if (rank[i] === undefined) {
                    arr.push(i);
                }
            }
            return arr;
        };
    // place one bishop on any black square
    rank[d(2) * 2] = 'b';
    // place the other bishop on any white square
    rank[d(2) * 2 + 1] = 'b';
    // place the queen on any empty square
    rank[emptySquares()[d(5)]] = 'q';
    // place one knight on any empty square
    rank[emptySquares()[d(4)]] = 'n';
    // place the other knight on any empty square
    rank[emptySquares()[d(3)]] = 'n';
    // place the rooks and the king on the squares left, king in the middle
    for (let x = 1; x <= 3; x++) {
        rank[emptySquares()[0]] = x === 2 ? 'k' : 'r';
    }
    return rank.join('');
}

export function startPositionToFen() {
    const fenTemplate =
        'bbbbbbbb/pppppppp/8/8/8/8/PPPPPPPP/WWWWWWWW w KQkq 0 1';
    const startPosition = generate960StartPosition();
    return fenTemplate
        .replace('bbbbbbbb', startPosition)
        .replace('WWWWWWWW', startPosition.toUpperCase());
}

export const pgnListToMoves = (pgnList) => {
    const moves = [];
    if (pgnList) {
        for (let i = 1; i <= pgnList.length; i += 2) {
            const moveNum = Math.round(i / 2) - 1;
            moves[moveNum] = {
                white: pgnList[i - 1],
                black: pgnList[i],
            };
        }
    }

    return moves;
};

export const getPieceOnSquare = (square, fen) => {
    const squareColumn = square.substr(0, 1);
    const squareRow = square.substr(1, 1);

    const row = fen.split('/')[8 - squareRow]; // It is opposite order in fen
    const splitRow = row.split('');
    const squareColumnNumber = squareColumn.toUpperCase().charCodeAt(0) - 64; // Map column to number
    const spreadRow = splitRow.reduce((reducer, value) => {
        // Spread rows, so something like 3p4 will be ---p----
        const parsedValue = parseInt(value);

        if (isNaN(parsedValue)) {
            reducer.push(value);
            return reducer;
        } else {
            for (let i = 0; i < parsedValue; i++) {
                reducer.push('');
            }
            return reducer;
        }
    }, []);

    return spreadRow[squareColumnNumber - 1];
};

export const getTurnFromFen = (fen) => {
    const matched = fen.match(/ (w|b) /g);
    if (matched) {
        return PLAYER_COLOR_SHORT_MAPPER[matched[0].trim()];
    } else {
        return PLAYER_COLOR.WHITE;
    }
};

const pointsToEndResult = {
    0: 'lost',
    0.5: 'draw',
    1: 'won',
};
const pointsToEndResultSpectate = {
    0: 'Black won',
    0.5: 'Draw',
    1: 'White won',
};
const reasonConverter = (reason) => {
    if (reason === 'resign' || reason === 'resigned') {
        return 'resignation';
    }
    if (reason === 'mate') {
        return 'checkmate';
    }
    return reason;
};
export const generateEndGameMessage = (
    result,
    playerColor,
    isSpectate = false
) => {
    let bestMoveString = '';

    if (result && result.accuracy) {
        const accuracy = parseInt(result.accuracy[playerColor]);
        if (!isNaN(accuracy)) {
            bestMoveString = 'Best moves: ' + accuracy.toFixed(2) + '%';
        }
    }
    let title = '';
    if (isSpectate) {
        title = pointsToEndResultSpectate[result.score[playerColor]];
    } else {
        title = 'You ' + pointsToEndResult[result.score[playerColor]] + '';
    }
    const message = [
        `Game ended by ${reasonConverter(result.reason)}`,
        bestMoveString,
    ];

    return [title, message];
};

export const setUserColors = (user) => {
    if (user) {
        switch (user.level) {
            case USER_ROLES.BEGINNER:
                return {
                    color1: 'rgba(195, 230, 110, 0.7)',
                    color2: 'rgba(82, 229, 148, 0.7)',
                };
            case USER_ROLES.CASUAL:
                return {
                    color1: 'rgba(9, 157, 208, 0.7)',
                    color2: 'rgba(8, 229, 167, 0.7)',
                };
            case USER_ROLES.CLUB:
                return {
                    color1: 'rgba(16, 79, 159, 0.7)',
                    color2: 'rgba(11, 190, 191, 0.7)',
                };
            case USER_ROLES.LEAGUE:
                return {
                    color1: 'rgba(97, 75, 239, 0.7)',
                    color2: 'rgba(17, 62, 154, 0.7)',
                };
            case USER_ROLES.TOURNAMENT:
                return {
                    color1: 'rgba(115, 17, 111, 0.7)',
                    color2: 'rgba(115, 46, 222, 0.7)',
                };
            case USER_ROLES.ADVANCED:
                return {
                    color1: 'rgba(180, 62, 72, 0.7)',
                    color2: 'rgba(118, 17, 100, 0.7)',
                };
            case USER_ROLES.EXPERT:
                return {
                    color1: 'rgba(191, 176, 80, 0.7)',
                    color2: 'rgba(190, 79, 79, 0.7)',
                };
            case USER_ROLES.MASTER:
                return {
                    color1: 'rgba(89, 89, 89, 0.7)',
                    color2: 'rgba(255, 0, 0, 0.7)',
                };
            default:
                return {
                    color1: 'rgba(195, 230, 110, 0.7)',
                    color2: 'rgba(82, 229, 148, 0.7)',
                };
        }
    }

    return {
        color1: 'rgba(195, 230, 110, 0.7)',
        color2: 'rgba(82, 229, 148, 0.7)',
    };
};

export const importPieceInFen = (piece, fen, to) => {
    const toColumn = to.substr(0, 1);
    const toRow = 8 - to.substr(1, 1);
    const toColumnNumber = toColumn.toUpperCase().charCodeAt(0) - 64; // Map column to number
    const fenRows = fen.split('/');
    const fenRow = fenRows[toRow];
    let newRow = fenRow;
    let calcNumber = 0;
    for (let i = 0; i < fenRow.length; i++) {
        const number = parseInt(fenRow[i]);
        if (!isNaN(number)) {
            calcNumber += number;
        } else {
            calcNumber++;
        }

        if (calcNumber === toColumnNumber) {
            newRow = replaceLetterInString(newRow, piece, i);
        }
    }
    fenRows[toRow] = newRow;
    return fenRows.join('/');
};

export const addPieceInFen = (piece, fen, to) => {
    const toColumn = to.substr(0, 1);
    const toRow = 8 - to.substr(1, 1);
    const toColumnNumber = toColumn.toUpperCase().charCodeAt(0) - 64; // Map column to number
    const fenRows = fen.split('/');
    const fenRow = fenRows[toRow];
    let newRow = fenRow;

    // Spread number to 1s, ie 3n4 should become 111n1111
    let numRow = '';
    for (let i = 0; i < fenRow.length; i++) {
        const number = parseInt(fenRow[i]);
        if (!isNaN(number)) {
            let spread = '';
            for (let i = 0; i < number; i++) {
                spread += '1';
            }
            numRow += spread;
            // numRow += replaceLetterInString(fenRow, spread, i + totalSpread);
            // totalSpread += number;
        } else {
            numRow += fenRow[i];
        }
    }

    // Replace piece on position
    for (let i = 1; i <= numRow.length; i++) {
        if (i === toColumnNumber) {
            newRow = replaceLetterInString(numRow, piece, i - 1);
        }
    }

    // Return 1s in number format, ie 111n1111 should become 3n4
    let finalRow = newRow;
    if (numRow !== fenRow) {
        finalRow = '';
        let calcNumber = 0;
        for (let i = 0; i < newRow.length; i++) {
            const number = parseInt(newRow[i]);
            if (!isNaN(number)) {
                calcNumber += 1;
            } else {
                if (calcNumber !== 0) {
                    finalRow += calcNumber + newRow[i];
                    calcNumber = 0;
                } else {
                    finalRow += newRow[i];
                }
            }
        }
        if (calcNumber !== 0) {
            finalRow += calcNumber;
        }
    }

    fenRows[toRow] = finalRow;
    return fenRows.join('/');
};

export const removePieceInFen = (fen, to) => {
    const toColumn = to.substr(0, 1);
    const toRow = 8 - to.substr(1, 1);
    const toColumnNumber = toColumn.toUpperCase().charCodeAt(0) - 64; // Map column to number

    const fenRows = fen.split('/');
    const fenRow = fenRows[toRow];
    let newRow = fenRow;
    let calcNumber = 0;
    for (let i = 0; i < fenRow.length; i++) {
        const number = parseInt(fenRow[i]);
        if (!isNaN(number)) {
            calcNumber += number;
        } else {
            calcNumber++;
        }
        if (calcNumber === toColumnNumber) {
            newRow = replaceLetterInString(newRow, '1', i);
        }
    }
    fenRows[toRow] = newRow;
    return fenRows.join('/');
};

export const setBadgeAndColorForComp = (level) => {
    const getStockfishLevel = level.replace(/\D/g, '');

    switch (getStockfishLevel) {
        case '0':
            return {
                badge: require('assets/icons/userTitle/beginner_badge.svg'),
                color1: 'rgba(195, 230, 110, 0.7)',
                color2: 'rgba(82, 229, 148, 0.7)',
            };
        case '1':
            return {
                badge: require('assets/icons/userTitle/casual_badge.svg'),
                color1: 'rgba(9, 157, 208, 0.7)',
                color2: 'rgba(8, 229, 167, 0.7)',
            };
        case '2':
            return {
                badge: require('assets/icons/userTitle/club_badge.svg'),
                color1: 'rgba(16, 79, 159, 0.7)',
                color2: 'rgba(11, 190, 191, 0.7)',
            };
        case '3':
            return {
                badge: require('assets/icons/userTitle/league_badge.svg'),
                color1: 'rgba(97, 75, 239, 0.7)',
                color2: 'rgba(17, 62, 154, 0.7)',
            };
        case '4':
            return {
                badge: require('assets/icons/userTitle/tournament_badge.svg'),
                color1: 'rgba(115, 17, 111, 0.7)',
                color2: 'rgba(115, 46, 222, 0.7)',
            };
        case '5':
            return {
                badge: require('assets/icons/userTitle/advanced_badge.svg'),
                color1: 'rgba(180, 62, 72, 0.7)',
                color2: 'rgba(118, 17, 100, 0.7)',
            };
        case '6':
            return {
                badge: require('assets/icons/userTitle/expert_badge.svg'),
                color1: 'rgba(191, 176, 80, 0.7)',
                color2: 'rgba(190, 79, 79, 0.7)',
            };
        case '7':
            return {
                badge: require('assets/icons/userTitle/master_badge.svg'),
                color1: 'rgba(198, 200, 128, 0.7)',
                color2: 'rgba(132, 123, 66, 0.7)',
            };
        case '8':
            return {
                badge: require('assets/icons/userTitle/master_badge.svg'),
                color1: 'rgba(198, 200, 128, 0.7)',
                color2: 'rgba(132, 123, 66, 0.7)',
            };

        default:
            return {
                badge: require('assets/icons/userTitle/beginner_badge.svg'),
                color1: 'rgba(195, 230, 110, 0.7)',
                color2: 'rgba(82, 229, 148, 0.7)',
            };
    }
};

export const getEndStateMessages = (
    winner,
    endState,
    lastMove,
    white,
    black,
    me
) => {
    switch (endState) {
        case ENDGAME_STATUS.CHECKMATE:
            if (winner === 'white' && white.id === me.id) {
                return {
                    firstLine: 'Another brilliant game.',
                    secondLine: 'You won!',
                };
            } else if (winner === 'black' && white.id === me.id) {
                return {
                    firstLine: 'Checkmate.',
                    secondLine: `${black.username} wins.`,
                };
            } else if (winner === 'white' && white.id !== me.id) {
                return {
                    firstLine: 'Checkmate.',
                    secondLine: `${white.username} wins.`,
                };
            } else if (winner === 'black' && white.id !== me.id) {
                return {
                    firstLine: 'Another brilliant game.',
                    secondLine: 'You won!',
                };
            } else {
                return { firstLine: 'asd', secondLine: 'asd' };
            }

        case ENDGAME_STATUS.TIMEOUT:
            if (winner === 'white' && white.id === me.id) {
                return { firstLine: 'Time out.', secondLine: 'You won!' };
            } else if (winner === 'white' && white.id !== me.id) {
                return {
                    firstLine: 'Time out.',
                    secondLine: `${white.username} wins.`,
                };
            } else if (winner === 'black' && white.id === me.id) {
                return {
                    firstLine: 'Time out.',
                    secondLine: `${black.username} wins.`,
                };
            } else if (winner === 'black' && white.id !== me.id) {
                return { firstLine: 'Time out.', secondLine: 'You won!' };
            } else {
                return { firstLine: 'asd', secondLine: 'asd' };
            }

        case ENDGAME_STATUS.RESIGNED:
            if (winner === 'white' && white.id === me.id) {
                return {
                    firstLine: `Game over. ${black.username} resigned.`,
                    secondLine: 'You won!',
                };
            } else if (winner === 'white' && white.id !== me.id) {
                return {
                    firstLine: 'Game over. You resigned.',
                    secondLine: `${white.username} wins.`,
                };
            } else if (winner === 'black' && white.id === me.id) {
                return {
                    firstLine: 'Game over. You resigned.',
                    secondLine: `${black.username} wins.`,
                };
            } else if (winner === 'black' && white.id !== me.id) {
                return {
                    firstLine: `Game over. ${white.username} resigned.`,
                    secondLine: 'You won!',
                };
            } else {
                return { firstLine: 'asd', secondLine: 'asd' };
            }

        case ENDGAME_STATUS.THREEFOLD_REPETITION:
            if (lastMove === 'black' && white.id === me.id) {
                return {
                    firstLine: `Game over. ${black.username} claimed a draw by threefold repetition.`,
                    secondLine: "It's a draw.",
                };
            } else if (lastMove === 'black' && white.id !== me.id) {
                return {
                    firstLine:
                        'Game over. You claimed a draw by threefold repetition.',
                    secondLine: "It's a draw.",
                };
            } else if (lastMove === 'white' && white.id === me.id) {
                return {
                    firstLine:
                        'Game over. You claimed a draw by threefold repetition.',
                    secondLine: "It's a draw.",
                };
            } else if (lastMove === 'white' && white.id !== me.id) {
                return {
                    firstLine: `Game over. ${black.username} claimed a draw by threefold repetition.`,
                    secondLine: "It's a draw.",
                };
            } else {
                return { firstLine: 'asd', secondLine: 'asd' };
            }

        case ENDGAME_STATUS.FIFTY_MOVES_RULE:
            if (lastMove === 'black' && white.id === me.id) {
                return {
                    firstLine: `Game over. ${black.username} claimed a draw by fifty-move rule.`,
                    secondLine: "It's a draw.",
                };
            } else if (lastMove === 'black' && white.id !== me.id) {
                return {
                    firstLine:
                        'Game over. You claimed a draw by fifty-move rule.',
                    secondLine: "It's a draw.",
                };
            } else if (lastMove === 'white' && white.id === me.id) {
                return {
                    firstLine:
                        'Game over. You claimed a draw by fifty-move rule.',
                    secondLine: "It's a draw.",
                };
            } else if (lastMove === 'white' && white.id !== me.id) {
                return {
                    firstLine: `Game over. ${black.username} claimed a draw by fifty-move rule.`,
                    secondLine: "It's a draw.",
                };
            } else {
                return { firstLine: 'asd', secondLine: 'asd' };
            }

        case ENDGAME_STATUS.STALEMATE:
            return { firstLine: 'Stalemate.', secondLine: "It's a draw." };

        case ENDGAME_STATUS.DRAW:
            return {
                firstLine: 'Another thrilling game.',
                secondLine: "It's a draw.",
            };

        case ENDGAME_STATUS.INSUFFICIENT_MATERIAL:
            return {
                firstLine: 'Game over. Insufficient material.',
                secondLine: "It's a draw.",
            };

        default:
            return;
    }
};

export const getEndStateMessagesSpectate = (
    winner,
    endState,
    lastMove,
    white,
    black,
    me
) => {
    switch (endState) {
        case ENDGAME_STATUS.CHECKMATE:
            if (winner === 'white') {
                return {
                    firstLine: 'Win by checkmate',
                    secondLine: `${white.username} won by checkmate!`,
                };
            } else {
                return {
                    firstLine: 'Win by checkmate',
                    secondLine: `${black.username} won by checkmate!`,
                };
            }

        case ENDGAME_STATUS.TIMEOUT:
            if (winner === 'white') {
                return {
                    firstLine: 'Win by timeout',
                    secondLine: `${white.username} won by timeout!`,
                };
            } else {
                return {
                    firstLine: 'Win by timeout',
                    secondLine: `${black.username} won by timeout!`,
                };
            }

        case ENDGAME_STATUS.RESIGNED:
            if (winner === 'white') {
                return {
                    firstLine: 'Win by resignation',
                    secondLine: `${black.username} resigned. ${white.username} won!`,
                };
            } else {
                return {
                    firstLine: 'Win by resignation',
                    secondLine: `${white.username} resigned. ${black.username} won!`,
                };
            }

        case ENDGAME_STATUS.THREEFOLD_REPETITION:
            return {
                firstLine: 'Draw by threefold repetition',
                secondLine: "It's a draw by threefold repetition",
            };

        case ENDGAME_STATUS.FIFTY_MOVES_RULE:
            if (lastMove === 'black') {
                return {
                    firstLine: 'Draw by 50 move rule',
                    secondLine: `It's a draw. ${black.username} claimed a draw by the fifty-move rule.`,
                };
            } else {
                return {
                    firstLine: 'Draw by 50 move rule',
                    secondLine: `It's a draw. ${white.username} claimed a draw by the fifty-move rule.`,
                };
            }

        case ENDGAME_STATUS.STALEMATE:
            return { firstLine: 'Draw by stalemate.', secondLine: 'Stalemate' };

        case ENDGAME_STATUS.DRAW:
            return {
                firstLine: 'Draw by agreement',
                secondLine: "It's a draw",
            };

        case ENDGAME_STATUS.INSUFFICIENT_MATERIAL:
            return {
                firstLine: 'Draw by insufficient material',
                secondLine: "It's a draw by insufficient material",
            };

        default:
            return;
    }
};
