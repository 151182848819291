import API from 'API';

const API_LIST = {
    GET_PGA: '/games/_gameId_/analysis/request',
    GET_PGA_COMPLETE: '/games/_gameId_/analysis',
};

export function getPostGameAnalysis(params) {
    if (params.request) {
        return API.get(API_LIST.GET_PGA.replace('_gameId_', params.id));
    } else {
        return API.get(API_LIST.GET_PGA_COMPLETE.replace('_gameId_', params.id));
    }
}
