import { combineReducers } from 'redux';
import utilReducer from 'store/util/reducer';
import userReducer from 'store/user/reducer';
import localeReducer from 'store/locale/reducer';
import gameReducer from 'store/game/reducer';
import gameWsReducer from 'store/gameWs/reducer';
import tournamentReducer from 'store/otbTournament/reducer';
import globalSettings from 'store/globalSettings/reducer';
import userProfileReducer from 'store/userProfile/reducer';
import privacySettings from 'store/globalSettings/userProfileSettings/reducer';
import gameHistoryReducer from 'store/gameHistory/reducer';
import statsReducer from 'store/stats/reducer';
import notifications from 'store/notifications/reducer';
import toast from 'store/toast/reducer';
import subscriptionsReducer from 'store/subscriptions/reducer';
import puzzle from 'store/puzzle/reducer';
import footer from 'store/footer/reducer';
import settings from 'store/settings/reducer';
import postGameAnalysisReducer from 'store/postGameAnalysis/reducer';
import pgnReaderReducer from 'store/pgnReader/reducer';
import liveGamesReducer from 'store/liveGames/reducer';
import strapiContent from 'store/strapi/reducer';

// Combine reducers for redux store
const rootReducer = combineReducers({
    user: userReducer,
    locale: localeReducer,
    util: utilReducer,
    game: gameReducer,
    gameWs: gameWsReducer,
    tournament: tournamentReducer,
    globalSettings: globalSettings,
    userProfile: userProfileReducer,
    privacySettings: privacySettings,
    gameHistory: gameHistoryReducer,
    stats: statsReducer,
    notifications: notifications,
    toast: toast,
    subscriptions: subscriptionsReducer,
    puzzle: puzzle,
    footer: footer,
    settings: settings,
    postGameAnalysis: postGameAnalysisReducer,
    pgnReader: pgnReaderReducer,
    liveGames: liveGamesReducer,
    strapiContent,
});

export default rootReducer;
