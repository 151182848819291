import { takeLatest, put as dispatch } from 'redux-saga/effects';

import * as actions from './actions';
import * as api from './api';
import * as constants from './constants';

export function* pgnReaderSaga() {
    yield takeLatest(constants.GET_PGN_READER_REQUEST, getPGNReader);
}

function* getPGNReader(params) {
    try {
        const response = yield api.getPGNReader(params);
        yield dispatch(actions.getPGNReaderSuccess(response.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('getPGNReader failed', e);
        yield dispatch(actions.getPGNReaderFailure(e));
    }
}
