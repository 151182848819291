import React from 'react';

import PlayerAvatar from './PlayerAvatar';
import PlayerTitle from 'UI/PlayerTitle/PlayerTitle';

import styles from './PlayerAvatarAndBadge.module.scss';

const begginer = require(`assets/icons/userTitle/beginner_badge.svg`);

const PlayerAvatarAndBadge = ({ player, mirror, imageSize, snackbar }) => {
    if (mirror) {
        return (
            <div className={styles.badgeAndIcon}>
                <div className={styles.playerInfoBadgeMirror}>
                    {player.level || player.skill_level ? (
                        <PlayerTitle
                            title={
                                player && (player.level || player.skill_level)
                            }
                            width={imageSize - 1 || 40}
                        />
                    ) : (
                        <img src={begginer} alt="" style={{ height: `${imageSize - 1 || 40}px` }}/>
                    )}
                </div>
                <PlayerAvatar
                    avatar={player.avatar}
                    imageSize={imageSize || 40}
                    level={player && (player.level || player.skill_level)}
                    snackbar={snackbar}
                />
            </div>
        );
    }
    return (
        <div className={styles.badgeAndIcon}>
            <PlayerAvatar
                avatar={player && player.avatar}
                imageSize={imageSize || 40}
                level={player && (player.level || player.skill_level)}
                snackbar={snackbar}
            />
            <div className={styles.playerInfoBadge}>
                {player && (player.level || player.skill_level) ? (
                    <PlayerTitle
                        title={player && (player.level || player.skill_level)}
                        width={imageSize - 1 || 40}
                    />
                ) : (
					<img src={begginer} alt="" style={{ height: `${imageSize - 1 || 40}px` }}/>
                )}
            </div>
        </div>
    );
};

export default PlayerAvatarAndBadge;
