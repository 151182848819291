import React, { useEffect, useState } from 'react';

import TC_OG_IMAGE from 'assets/images/kchess_tc_og_image.png';

import { Helmet } from 'react-helmet';

function HelmetWrapper(props) {
    const { title, metaDesc } = props;
    const [loc, setLoc] = useState(null);

    useEffect(() => {
        setLoc(window.location.href);
    }, [window.location.href]);

    return (
        <Helmet>
            <title>{title}</title>

            {/* basic meta data */}

            <meta name="title" content={title} data-react-helmet="true" />
            <meta
                name="description"
                content={metaDesc}
                data-react-helmet="true"
            />
            <meta
                name="keywords"
                content="chess, Kasparov, play chess online, learn, improve at chess, lessons from grandmasters, Garry Kasparov masterclass"
            />
            <meta name="robots" content="index, follow" />
            <meta
                http-equiv="Content-Type"
                content="text/html; charset=utf-8"
            />
            <meta name="language" content="English" />

            {/* open graph parameters */}

            <meta
                property="og:title"
                content={title}
                data-react-helmet="true"
            />
            <meta property="og:url" content={loc} data-react-helmet="true" />
            <meta
                property="og:description"
                content={metaDesc}
                data-react-helmet="true"
            />
            <meta
                property="og:type"
                content="website"
                data-react-helmet="true"
            />
            <meta
                property="og:image"
                content={TC_OG_IMAGE}
                data-react-helmet="true"
            />
            <meta
                property="og:image:secure_url"
                content={TC_OG_IMAGE}
                data-react-helmet="true"
            />

            {/* twitter card parameters */}

            <meta
                property="twitter:title"
                content={title}
                data-react-helmet="true"
            />
            <meta
                name="twitter:card"
                content="summary_large_image"
                data-react-helmet="true"
            />
            <meta
                name="twitter:site"
                content={`@${loc}`}
                data-react-helmet="true"
            />
            <meta
                property="twitter:description"
                content={metaDesc}
                data-react-helmet="true"
            />
            <meta
                name="twitter:image"
                content={TC_OG_IMAGE}
                data-react-helmet="true"
            />
        </Helmet>
    );
}

export default HelmetWrapper;
