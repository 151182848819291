/* eslint-disable no-unused-expressions */
import React, { useReducer } from 'react';
import { modalsReducer } from './reducer';
import { ModalsContext } from './context';
import Modal from './modal';

const randomId = () =>
    '_' +
    Math.random()
        .toString(36)
        .substr(2, 9);

export function ModalsProvider({ children, modalProps, modals }) {
    const [state, dispatch] = useReducer(modalsReducer, {
        modals: [],
        current: null,
    });

    const closeAll = (canceled) => {
        state.modals.forEach((modal) => {
            if (modal.type === 'confirm' && canceled) {
                modal.props?.onCancel?.();
            }

            modal.props?.onClose?.();
        });
        dispatch({ type: 'CLOSE_ALL' });
    };

    const openModal = (props) => {
        const id = props.id || randomId();
        dispatch({
            type: 'OPEN',
            payload: {
                id,
                type: 'content',
                props,
            },
        });
        return id;
    };

    const openContextModal = (modal, props) => {
        const id = props.id || randomId();
        dispatch({
            type: 'OPEN',
            payload: {
                id,
                type: 'context',
                props,
                ctx: modal,
            },
        });
        return id;
    };

    const closeModal = (id, canceled) => {
        if (state.modals.length <= 1) {
            closeAll(canceled);
            return;
        }

        const modal = state.modals.find((item) => item.id === id);
        if (modal?.type === 'confirm' && canceled) {
            modal.props?.onCancel?.();
        }
        modal?.props?.onClose?.();
        dispatch({ type: 'CLOSE', payload: modal.id });
    };

    const ctx = {
        modals: state.modals,
        openModal,
        openContextModal,
        closeModal,
        closeAll,
    };

    const getCurrentModal = () => {
        switch (state.current?.type) {
            case 'context': {
                const { innerProps, ...rest } = state.current.props;
                const ContextModal = modals[state.current.ctx];

                return {
                    modalProps: rest,
                    content: (
                        <ContextModal
                            innerProps={innerProps}
                            context={ctx}
                            id={state.current.id}
                        />
                    ),
                };
            }
            case 'content': {
                const {
                    children: currentModalChildren,
                    ...rest
                } = state.current.props;

                return {
                    modalProps: rest,
                    content: <>{currentModalChildren}</>,
                };
            }
            default: {
                return {
                    modalProps: {},
                    content: null,
                };
            }
        }
    };

    const { modalProps: currentModalProps, content } = getCurrentModal();
    console.log('all', getCurrentModal());
    return (
        <ModalsContext.Provider value={ctx}>
            <Modal
                {...modalProps}
                {...currentModalProps}
                opened={state.modals.length > 0}
                onClose={() => {
                    closeModal(state.current.id);
                    content?.props?.innerProps?.onCloseModal &&
                        content?.props?.innerProps?.onCloseModal();
                }}
            >
                {content}
            </Modal>

            {children}
        </ModalsContext.Provider>
    );
}
