import { capitalize } from './string';

export const formatDateFromISO = (inputDate, format = 'M.D.Y') => {
    const jsDate = new Date(inputDate);

    const mapObj = {
        Y: jsDate.getUTCFullYear(),
        M: ('0' + (jsDate.getUTCMonth() + 1)).slice(-2),
        T: capitalize(months[jsDate.getUTCMonth()]),
        D: ('0' + jsDate.getUTCDate()).slice(-2),
        H: ('0' + jsDate.getUTCHours()).slice(-2),
        I: ('0' + jsDate.getUTCMinutes()).slice(-2),
        S: ('0' + jsDate.getUTCSeconds()).slice(-2),
    };

    return format.replace(/Y|M|T|D|H|I|S/gi, function(matched) {
        return mapObj[matched];
    });
};

export const getCurrentMonth = (index = false) => {
    const date = new Date();
    const month = date.getMonth();
    return index ? month : months[month];
};

export const numberOfDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
};

export const getDaysInMonth = (month, year) => {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }
    return days;
};

export const months = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
];

export const monthsFullName = [
    'January',
    'February',
    'Mart',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
