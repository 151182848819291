import { takeLatest, put as dispatch } from 'redux-saga/effects';

import * as actions from './actions';
import * as api from './api';
import * as constants from './constants';

export function* gameHistorySaga() {
    yield takeLatest(constants.GET_GAME_HISTORY_REQUEST, getGameHistory);
}

function* getGameHistory(params) {
    try {
        const response = yield api.getGameHistory(params);
        yield dispatch(actions.getGameHistorySuccess(response.data.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('getUserProfile failed', e);
        yield dispatch(actions.getGameHistoryFailiure(e));
    }
}
