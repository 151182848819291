export const GET_PLAYHUB_REQUEST = 'OMNIHUB:PLAYHUB:GET_PLAYHUB_REQUEST';
export const GET_PLAYHUB_SUCCESS = 'OMNIHUB:PLAYHUB:GET_PLAYHUB_SUCCESS';
export const GET_PLAYHUB_FAILURE = 'OMNIHUB:PLAYHUB:GET_PLAYHUB_FAILURE';

export const GET_HOME_REQUEST = 'OMNIHUB:HOME:GET_HOME_REQUEST';
export const GET_HOME_SUCCESS = 'OMNIHUB:HOME:GET_HOME_SUCCESS';
export const GET_HOME_FAILURE = 'OMNIHUB:HOME:GET_HOME_FAILURE';

export const GET_LEARN_REQUEST = 'OMNIHUB:LEARN:GET_LEARN_REQUEST';
export const GET_LEARN_SUCCESS = 'OMNIHUB:LEARN:GET_LEARN_SUCCESS';
export const GET_LEARN_FAILURE = 'OMNIHUB:LEARN:GET_LEARN_FAILURE';

export const GET_WATCH_REQUEST = 'OMNIHUB:WATCH:GET_WATCH_REQUEST';
export const GET_WATCH_SUCCESS = 'OMNIHUB:WATCH:GET_WATCH_SUCCESS';
export const GET_WATCH_FAILURE = 'OMNIHUB:WATCH:GET_WATCH_FAILURE';

export const GET_ALL_ARTICLES_REQUEST =
    'STRAPI:ARTICLES:GET_ALL_ARTICLES_REQUEST';
export const GET_ALL_ARTICLES_SUCCESS =
    'STRAPI:ARTICLES:GET_ALL_ARTICLES_SUCCESS';
export const GET_ALL_ARTICLES_FAILURE =
    'STRAPI:ARTICLES:GET_ALL_ARTICLES_FAILURE';
export const CLEAR_ALL_ARTICLES =
    'STRAPI:ARTICLES:CLEAR_ALL_ARTICLES';

export const GET_SINGLE_ARTICLE_REQUEST =
    'STRAPI:ARTICLES:GET_SINGLE_ARTICLE_REQUEST';
export const GET_SINGLE_ARTICLE_SUCCESS =
    'STRAPI:ARTICLES:GET_SINGLE_ARTICLE_SUCCESS';
export const GET_SINGLE_ARTICLE_FAILURE =
    'STRAPI:ARTICLES:GET_SINGLE_ARTICLE_FAILURE';

export const GET_MOST_VIEWED_ARTICLES_REQUEST =
    'STRAPI:ARTICLES:GET_MOST_VIEWED_ARTICLES_REQUEST';
export const GET_MOST_VIEWED_ARTICLES_SUCCESS =
    'STRAPI:ARTICLES:GET_MOST_VIEWED_ARTICLES_SUCCESS';
export const GET_MOST_VIEWED_ARTICLES_FAILURE =
    'STRAPI:ARTICLES:GET_MOST_VIEWED_ARTICLES_FAILURE';

export const GET_ALL_VIDEOS_REQUEST = 'STRAPI:VIDEOS:GET_ALL_VIDEOS_REQUEST';
export const GET_ALL_VIDEOS_SUCCESS = 'STRAPI:VIDEOS:GET_ALL_VIDEOS_SUCCESS';
export const GET_ALL_VIDEOS_FAILURE = 'STRAPI:VIDEOS:GET_ALL_VIDEOS_FAILURE';

export const GET_SINGLE_VIDEO_REQUEST =
    'STRAPI:VIDEOS:GET_SINGLE_VIDEO_REQUEST';
export const GET_SINGLE_VIDEO_SUCCESS =
    'STRAPI:VIDEOS:GET_SINGLE_VIDEO_SUCCESS';
export const GET_SINGLE_VIDEO_FAILURE =
    'STRAPI:VIDEOS:GET_SINGLE_VIDEO_FAILURE';

export const GET_VIDEO_CATEGORY_REQUEST =
    'STRAPI:VIDEOS:GET_VIDEO_CATEGORY_REQUEST';
export const GET_VIDEO_CATEGORY_SUCCESS =
    'STRAPI:VIDEOS:GET_VIDEO_CATEGORY_SUCCESS';
export const GET_VIDEO_CATEGORY_FAILURE =
    'STRAPI:VIDEOS:GET_VIDEO_CATEGORY_FAILURE';
