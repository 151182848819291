import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Success } from 'assets/icons/notifications/success.svg';
import { ReactComponent as Failed } from 'assets/icons/notifications/failed.svg';

import styles from './Toast.module.scss';

const Toast = ({
    data: { text, type, cta, timestamp, durationInSeconds, content },
    removeToast,
}) => {
    const [show] = useState(true);
    const timerToClearSomewhere = useRef(false);

    document.documentElement.style.setProperty(
        '--delay',
        `${durationInSeconds - 0.5}s`
    );

    useEffect(() => {
        timerToClearSomewhere.current = setTimeout(
            () => removeToast(timestamp),
            durationInSeconds * 1000
        );

        return () => {
            clearTimeout(timerToClearSomewhere.current);
        };
    }, []);

    const stopTimer = (fn) => {
        fn();
        clearTimeout(timerToClearSomewhere.current);
        removeToast(timestamp);
    };

	const close = () => {
		clearTimeout(timerToClearSomewhere.current);
		removeToast(timestamp);
	};

    const OpenButton = ({ fn, text }) => {
        return (
            <button
                className={styles.accept_button}
                onClick={() => stopTimer(fn)}
            >
                {text ? text : 'Open'}
            </button>
        );
    };

    const AcceptButton = ({ fn, text }) => {
        return (
            <button
                className={styles.accept_button}
                onClick={() => stopTimer(fn)}
            >
                {text ? text : 'Accept'}
            </button>
        );
    };

    const DeclineButton = ({ fn, text }) => {
        return (
            <button
                className={styles.decline_button}
                onClick={() => stopTimer(fn)}
            >
                {text ? text : 'Decline'}
            </button>
        );
    };

    const PlayButton = ({ fn, text }) => {
        return (
            <button
                className={styles.accept_button}
                onClick={() => stopTimer(fn)}
            >
                {text ? text : 'Play'}
            </button>
        );
    };

    return (
        <div
            id="toast"
            className={[styles.toast, show ? styles.show : styles.fade].join(
                ' '
			)}
			onClick={cta ? null : close}
        >
            <div className={styles.left} style={{ width: type === 'cta' ? '50%' : '80%' }}>
                {content && <div className={styles.content}>{content}</div>}
                <div className={styles.text} style={{ width: type === 'cta' ? '150px' : '100%' }}>
                    <div
                        style={{
                            marginBottom: text && text.subtitle ? '8px' : null,
                        }}
                        className={styles.title}
                    >
                        {text && text.title}
                    </div>
                    <div className={styles.subtitle}>
                        {text && text.subtitle}
                    </div>
                </div>
            </div>
            <div className={styles.right}>
                {type === 'cta' ? (
                    <>
                        <DeclineButton
                            fn={cta && cta.decline}
                            text={cta && cta.declineText}
                        />{' '}
                        <AcceptButton
                            fn={cta && cta.accept}
                            text={cta && cta.acceptText}
                        />
                    </>
                ) : type === 'success' ? (
                    <Success />
                ) : type === 'error' ? (
                    <Failed />
                ) : type === 'info' ? (
                    ''
                ) : type === 'open' ? (
                    <OpenButton
                        fn={cta && cta.open}
                    />
                ) : type === 'play' ? (
                    <PlayButton
                        fn={cta && cta.accept}
                        text={cta && cta.acceptText}
                    />
                ) : null}
            </div>
        </div>
    );
};

Toast.propTypes = {
    data: PropTypes.exact({
        text: PropTypes.exact({
            title: PropTypes.string.isRequired,
            subtitle: PropTypes.string,
        }),
        type: PropTypes.oneOf(['success', 'error', 'cta', 'info', 'play'])
            .isRequired,
        timestamp: PropTypes.string.isRequired,
        cta: PropTypes.exact({
            accept: PropTypes.func,
            decline: PropTypes.func,
            acceptText: PropTypes.string,
            declineText: PropTypes.string,
        }),
        durationInSeconds: PropTypes.number.isRequired,
        content: PropTypes.element,
    }),
    removeToast: PropTypes.func.isRequired,
};

Toast.defaultProps = {
    durationInSeconds: 7,
};

export default Toast;
