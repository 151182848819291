import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
    GAME_URLS,
    MAIN_URLS,
    ONBOARDING_URLS,
    SPECTATING_URLS,
    GAME_SETTINGS_URLS,
    GLOBAL_SETTINGS_URLS,
    PRIVACY_SETTINGS,
    USER_PROFILE,
    GAME_HISTORY,
    RESULTS_HUB_URLS,
    ARTICLES_URLS,
    CORRESPONDENCE_GAMES_URLS,
    WATCH_HUB_URLS,
    LEARN_HUB_URLS,
    MASTERCLASS_URLS,
    GCT_URLS,
    SUPPORT,
    UPGRADE,
    PUZZLE,
    PGN_READER,
    FALLBACK,
    PROGRAMS,
    PRODUCT_PAYMENT,
} from 'shared/urlList';
import { getIsGuestLS } from 'shared/localStorage';

import Splash from 'components/Splash/Splash';
import ProductPaymentCancel from 'components/ProductPaymentCancel/ProductPaymentCancel';
import ProductPaymentSuccess from 'components/ProductPaymentSuccess/ProductPaymentSuccess';
import { useModals } from 'context/modal/useModals';
import IndividualAppPurchasePage from 'components/IndividualAppPurchasePage/IndividualAppPurchasePage';

// Lazy imports of components
const Login = lazy(() => import('components/Login/Login'));
const SocialRegistration = lazy(() =>
    import('components/SocialRegistration/SocialRegistration')
);
// const EmailRegistration = lazy(() =>
//     import('components/EmailRegistration/EmailRegistration')
// );

const EmailRegistrationNoConfirm = lazy(() =>
    import('components/EmailRegistrationNoConfirm/EmailRegistrationNoConfirm')
);
const EmailRegistration2 = lazy(() =>
    import('components/EmailRegistration2/EmailRegistration2')
);
const Privacy = lazy(() => import('components/Privacy/Privacy'));
const Terms = lazy(() => import('components/Terms/Terms'));
const ChessRules = lazy(() => import('components/ChessRules/ChessRules'));
const Legacy = lazy(() => import('components/Legacy/Legacy'));
const DesignSystem = lazy(() => import('components/DesignSystem/DesignSystem'));
const Onboarding = lazy(() => import('components/Onboarding/Onboarding'));
const PasswordRecovery = lazy(() =>
    import('components/PasswordRecovery/PasswordRecovery')
);
const ComputerGame = lazy(() => import('components/Game/GameVsComputer'));
const OpponentGame = lazy(() => import('components/Game/GameVsOpponent'));
const FriendGame = lazy(() => import('components/Game/GameVsFriend'));
const Correspondence = lazy(() => import('components/Game/Correspondance'));
const GameSettings = lazy(() => import('components/GameSettings/GameSettings'));

const OTBTournamentList = lazy(() =>
    import('components/OTBTournaments/OTBTournaments')
);
const OTBGameList = lazy(() => import('components/OTBTournaments/GameList'));
const SpectateOTBGame = lazy(() => import('components/Game/WatchOTBGame'));
const SpectateGCTGame = lazy(() => import('components/Game/GCTSpectate'));
const GlobalSettings = lazy(() => import('UI/SettingsModal/SettingsModal'));
const PrivacySettings = lazy(() =>
    import('UI/SettingsModal/PrivacySettings/PrivacySettings')
);
const UserProfile = lazy(() => import('components/Profile/UserProfile'));
const GameHistory = lazy(() => import('components/GameHistory/GameHistory'));

const PostGame = lazy(() => import('components/Game/PostGame'));
const PlayerStats = lazy(() => import('components/PlayerStats/PlayerStats'));
const GlobalRanking = lazy(() =>
    import('components/GlobalRanking/GlobalRanking')
);
const Articles = lazy(() => import('components/Articles/Articles'));
const Article = lazy(() => import('components/Article/Article'));
const AllVideos = lazy(() => import('components/AllVideos/AllVideos'));

const Demostrator = lazy(() => import('components/Game/Demonstrator'));
const CorrespondenceGames = lazy(() =>
    import('components/CorrespondenceGames/CorrespondenceGames')
);
const Video = lazy(() => import('components/Video/Video'));
const Masterclass = lazy(() => import('components/Masterclass/Masterclass'));
const MasterclassChapter = lazy(() =>
    import('components/MasterclassChapter/MasterclassChapter')
);
const VideoCategories = lazy(() =>
    import('components/VideoCategories/VideoCategories')
);
const Lessons = lazy(() => import('components/Lessons/Lessons'));
const LessonsEpisode = lazy(() => import('components/Lessons/LessonEpisode'));

const OmniHub = lazy(() => import('components/OmniHub/OmniHub'));

const GCT = lazy(() => import('components/GCT/GCT'));

const Support = lazy(() => import('components/Support/Support'));
const RequestSupport = lazy(() => import('components/Support/Request'));
const SupportSlug = lazy(() =>
    import('components/Support/components/SupportSlug')
);
const SupportArticle = lazy(() =>
    import('components/Support/components/SupportArticle')
);
const Upgrade = lazy(() => import('components/Upgrade/Upgrade'));
const Puzzle = lazy(() => import('components/Game/Puzzle'));
const SubscriptionSuccess = lazy(() =>
    import('components/SubscriptionSuccess/SubscriptionSuccess')
);
const SubscriptionCancel = lazy(() =>
    import('components/SubscriptionCancel/SubscriptionCancel')
);
const PuzzleHub = lazy(() => import('components/Puzzle/PuzzleHub'));
const FourOFour = lazy(() => import('components/Fallback/FourOFour'));
const Achievements = lazy(() => import('components/Achievements/Achievements'));
const LiveGames = lazy(() => import('components/LiveGames/LiveGames'));
const PGNReader = lazy(() => import('components/PGNReader/PGNReader'));
const SpectateGame = lazy(() => import('components/Game/Spectate'));
const ScheduleMaintenance = lazy(() =>
    import('components/Fallback/ScheduleMaintenance')
);
const ProgramsHub = lazy(() =>
    import('components/Programs/ProgramsHub/ProgramsHub')
);
const ProgramPage = lazy(() =>
    import('components/Programs/ProgramPage/ProgramPage')
);
const ProgramChapter = lazy(() =>
    import('components/Programs/ProgramChapter/ProgramChapter')
);
const WelcomePage = lazy(() => import('components/Welcome/WelcomePage'));
// TODO Handle guest redirect better
// Introduce isGuest param that will help

const RouterWrapper = (props) => {
    const isGuest = getIsGuestLS();

    return (
        <Suspense fallback={null}>
            {!isGuest ? <LogedIn props={props} /> : <Guest props={props} />}
        </Suspense>
    );
};

const LogedIn = ({ props }) => {
    const modals = useModals();
    return (
        <Switch>
            <Route exact path={MAIN_URLS.LANDING_PAGE} component={Splash} />
            <Route exact path={MAIN_URLS.HOME} component={OmniHub} />
            <Route exact path={USER_PROFILE.PROFILE} component={UserProfile} />
            <Route exact path={GCT_URLS.TOURNAMENT} component={GCT} />
            <Route exact path={GCT_URLS.MAIN} component={GCT} />
            <Route exact path={'/demo'} component={Demostrator} />
            <Route
                exact
                path={GAME_HISTORY.LIST_HISTORY}
                component={GameHistory}
            />
            <Route
                exact
                path={ONBOARDING_URLS.RESET_PASSWORD}
                component={PasswordRecovery}
            />
            <Route
                exact
                path={GAME_URLS.PLAY_COMPUTER}
                component={ComputerGame}
            />
            <Route
                exact
                path={GAME_URLS.PLAY_OPPONENT}
                component={OpponentGame}
            />
            <Route exact path={GAME_URLS.GAME} component={OpponentGame} />
            <Route exact path={GAME_URLS.PLAY_FRIEND} component={FriendGame} />
            <Route
                exact
                path={GAME_URLS.PLAY_DAILY}
                component={Correspondence}
            />
            <Route
                exact
                path={SPECTATING_URLS.SPECTATE}
                component={OTBTournamentList}
            />
            <Route
                exact
                path={SPECTATING_URLS.TOURNAMENT_GAME_LIST}
                component={OTBGameList}
            />
            <Route
                exact
                path={SPECTATING_URLS.SPECTATE_OTB_GAME}
                component={SpectateOTBGame}
            />
            <Route
                exact
                path={SPECTATING_URLS.SPECTATE_GCT_GAME}
                component={SpectateGCTGame}
            />
            <Route
                exact
                path={GLOBAL_SETTINGS_URLS.GLOBAL_SETTINGS_WILDCARD}
                component={GlobalSettings}
            />
            <Route
                exact
                path={PRIVACY_SETTINGS.PRIVACY_SETTINGS_WILDCARD}
                component={PrivacySettings}
            />
            <Route
                exact
                path={RESULTS_HUB_URLS.RESULTS_HUB}
                component={OmniHub}
            />
            <Route
                exact
                path={RESULTS_HUB_URLS.GLOBAL_RANKING}
                component={GlobalRanking}
            />
            <Route exact path={SUPPORT.REQUEST} component={RequestSupport} />
            <Route exact path={SUPPORT.SUPPORT} component={Support} />
            <Route exact path={SUPPORT.SUPPORT_SLUG} component={SupportSlug} />
            <Route
                exact
                path={SUPPORT.SUPPORT_ARTICLE}
                component={SupportArticle}
            />
            <Route exact path={MAIN_URLS.TERMS} component={Terms} />
            <Route exact path={MAIN_URLS.PRIVACY} component={Privacy} />
            <Route exact path={MAIN_URLS.CHESS_RULES} component={ChessRules} />
            <Route exact path={MAIN_URLS.LEGACY} component={Legacy} />
            <Route exact path={MAIN_URLS.PLAYHUB} component={OmniHub} />
            <Route exact path={WATCH_HUB_URLS.WATCH} component={OmniHub} />
            <Route
                exact
                path={WATCH_HUB_URLS.CHESS_JOURNEYS}
                component={VideoCategories}
            />
            <Route
                exact
                path={WATCH_HUB_URLS.DOCUMENTARIES}
                component={VideoCategories}
            />
            <Route
                exact
                path={WATCH_HUB_URLS.INTERVIEWS}
                component={VideoCategories}
            />
            <Route
                exact
                path={WATCH_HUB_URLS.ALL_VIDEOS}
                component={AllVideos}
            />
            <Route exact path={LEARN_HUB_URLS.LEARN} component={OmniHub} />
            <Route exact path={LEARN_HUB_URLS.LESSONS} component={Lessons} />
            <Route
                exact
                path={LEARN_HUB_URLS.LESSON_EPISODE}
                component={LessonsEpisode}
            />
            <Route
                exact
                path="/iap"
                component={IndividualAppPurchasePage}
            />
            <Route exact path={PROGRAMS.PROGRAMS_HUB} component={ProgramsHub} />
            <Route exact path={PROGRAMS.PROGRAM_PAGE} component={ProgramPage} />
            <Route
                exact
                path={PROGRAMS.PROGRAM_CHAPTER}
                component={ProgramChapter}
            />
            <Route
                exact
                path={MASTERCLASS_URLS.MASTERCLASS}
                component={Masterclass}
            />
            <Route
                exact
                path={MASTERCLASS_URLS.MASTERCLASS_EPISODE}
                component={MasterclassChapter}
            />
            <Route
                exact
                path={WATCH_HUB_URLS.CHESS_JOURNEYS}
                component={VideoCategories}
            />
            <Route
                exact
                path={WATCH_HUB_URLS.DOCUMENTARIES}
                component={VideoCategories}
            />
            <Route
                exact
                path={WATCH_HUB_URLS.INTERVIEWS}
                component={VideoCategories}
            />
            <Route
                exact
                path={WATCH_HUB_URLS.ALL_VIDEOS}
                component={AllVideos}
            />
            <Route
                exact
                path={WATCH_HUB_URLS.LIVE_GAMES}
                component={LiveGames}
            />
            <Route
                exact
                path={WATCH_HUB_URLS.WATCH_WILDCARD}
                component={Video}
            />
            <Route exact path={UPGRADE.UPGRADE_ACC} component={Upgrade} />
            <Route
                exact
                path={UPGRADE.SUBSCRIPTION_SUCCESS}
                component={SubscriptionSuccess}
            />
            <Route
                exact
                path={UPGRADE.SUBSCRIPTION_CANCEL}
                component={SubscriptionCancel}
            />
            <Route
                exact
                path={[UPGRADE.WELCOME_YEARLY, UPGRADE.WELCOME_MONTHLY]}
                component={WelcomePage}
            />
            <Route
                exact
                path={MASTERCLASS_URLS.MASTERCLASS}
                component={Masterclass}
            />
            <Route
                exact
                path={MASTERCLASS_URLS.MASTERCLASS_EPISODE}
                component={MasterclassChapter}
            />
            <Route
                exact
                path={GAME_HISTORY.POST_GAME_ANALYSIS}
                component={PostGame}
            />
            <Route exact path={USER_PROFILE.STATS} component={PlayerStats} />
            <Route exact path={ARTICLES_URLS.ARTICLES} component={Articles} />
            <Route
                exact
                path={ARTICLES_URLS.ARTICLE_WILDCARD}
                component={Article}
            />
            <Route
                exact
                path={CORRESPONDENCE_GAMES_URLS.CORRESPONDENCE_GAMES}
                component={CorrespondenceGames}
            />
            <Route
                exact
                path={GAME_SETTINGS_URLS.SETTINGS_WILDCARD}
                component={GameSettings}
            />
            <Route exact path={PUZZLE.PUZZLE} component={Puzzle} />
            <Route exact path={PUZZLE.PUZZLE_DAILY} component={Puzzle} />
            <Route exact path={PUZZLE.PUZZLE_HUB} component={PuzzleHub} />
            <Route exact path={PGN_READER.PGNReader} component={PGNReader} />
            <Route exact path={FALLBACK.FOUR_O_FOUR} component={FourOFour} />
            <Route
                exact
                path={FALLBACK.SCHEDULE_MAINTENANCE}
                component={ScheduleMaintenance}
            />
            <Route
                exact
                path={GAME_URLS.SPECTATE_GAME}
                component={SpectateGame}
            />
            <Route
                exact
                path={USER_PROFILE.ACHIEVEMENTS}
                component={Achievements}
            />
            <Route
                exact
                path={ONBOARDING_URLS.LOGIN}
                render={() => <Login auth2={props.auth2} />}
            />
            <Route
                exact
                path={ONBOARDING_URLS.REGISTER_SOCIAL}
                component={SocialRegistration}
            />
            <Route
                exact
                path={ONBOARDING_URLS.ONBOARDING}
                render={() => <Onboarding auth2={props.auth2} />}
            />
            <Route
                exact
                path={ONBOARDING_URLS.REGIESTER}
                component={EmailRegistrationNoConfirm}
            />
            {/* <Route
                exact
                path={ONBOARDING_URLS.REGIESTER}
                component={EmailRegistration}
            /> */}
            <Route
                exact
                path={ONBOARDING_URLS.VERIFY_EMAIL}
                component={EmailRegistration2}
            />
            <Route
                exact
                path={ONBOARDING_URLS.RESET_PASSWORD}
                component={PasswordRecovery}
            />
            <Route
                exact
                path={PRODUCT_PAYMENT.SUCCESS}
                component={ProductPaymentSuccess}
            />
            <Route
                exact
                path={PRODUCT_PAYMENT.CANCEL}
                component={ProductPaymentCancel}
            />
            <Redirect to={FALLBACK.FOUR_O_FOUR} />
        </Switch>
    );
};

const Guest = ({ props }) => {
    return (
        <Switch>
            <Route
                exact
                path={USER_PROFILE.PROFILE}
                render={(props) => (
                    <UserProfile {...props} redirectToRegister={true} />
                )}
            />
            <Route
                exact
                path={GAME_HISTORY.LIST_HISTORY}
                render={(props) => (
                    <GameHistory {...props} redirectToRegister={true} />
                )}
            />
            <Route
                exact
                path={GAME_URLS.PLAY_FRIEND}
                render={(props) => (
                    <FriendGame {...props} redirectToRegister={true} />
                )}
            />
            <Route
                exact
                path={GAME_URLS.PLAY_DAILY}
                render={(props) => (
                    <Correspondence {...props} redirectToRegister={true} />
                )}
            />
            <Route
                exact
                path={GLOBAL_SETTINGS_URLS.GLOBAL_SETTINGS_WILDCARD}
                render={(props) => (
                    <GlobalSettings {...props} redirectToRegister={true} />
                )}
            />
            <Route
                exact
                path={PRIVACY_SETTINGS.PRIVACY_SETTINGS_WILDCARD}
                render={(props) => (
                    <PrivacySettings {...props} redirectToRegister={true} />
                )}
            />
            <Route
                exact
                path={LEARN_HUB_URLS.LESSON_EPISODE}
                render={(props) => (
                    <LessonsEpisode {...props} redirectToRegister={true} />
                )}
            />
            <Route
                exact
                path={PROGRAMS.PROGRAMS_HUB}
                render={(props) => <ProgramsHub {...props} />}
            />
            <Route
                exact
                path={PROGRAMS.PROGRAM_PAGE}
                render={(props) => (
                    <ProgramPage {...props} redirectToRegister={true} />
                )}
            />
            <Route
                exact
                path={PROGRAMS.PROGRAM_CHAPTER}
                render={(props) => (
                    <ProgramChapter {...props} redirectToRegister={true} />
                )}
            />
            <Route
                exact
                path={MASTERCLASS_URLS.MASTERCLASS_EPISODE}
                render={(props) => (
                    <MasterclassChapter {...props} redirectToRegister={true} />
                )}
            />
            <Route
                exact
                path={CORRESPONDENCE_GAMES_URLS.CORRESPONDENCE_GAMES}
                render={(props) => (
                    <CorrespondenceGames {...props} redirectToRegister={true} />
                )}
            />
            <Route
                exact
                path={PUZZLE.PUZZLE_HUB}
                render={(props) => (
                    <PuzzleHub {...props} redirectToRegister={true} />
                )}
            />
            <Route
                exact
                path={PUZZLE.PUZZLE}
                render={(props) => (
                    <Puzzle {...props} redirectToRegister={true} />
                )}
            />
            <Route
                exact
                path={PUZZLE.PUZZLE_DAILY}
                render={(props) => (
                    <Puzzle {...props} redirectToRegister={true} />
                )}
            />
            <Route
                exact
                path={PGN_READER.PGNReader}
                render={(props) => (
                    <PGNReader {...props} redirectToRegister={true} />
                )}
            />
            <Route
                exact
                path={GAME_HISTORY.POST_GAME_ANALYSIS}
                render={(props) => (
                    <PostGame {...props} redirectToRegister={true} />
                )}
            />

            <Route exact path={MAIN_URLS.LANDING_PAGE} component={Splash} />
            <Route exact path={MAIN_URLS.HOME} component={OmniHub} />
            <Route exact path={GCT_URLS.MAIN} component={GCT} />
            <Route exact path={GCT_URLS.TOURNAMENT} component={GCT} />
            <Route exact path={'/demo'} component={Demostrator} />
            <Route
                exact
                path={ONBOARDING_URLS.ONBOARDING}
                render={() => <Onboarding auth2={props.auth2} />}
            />
            <Route
                exact
                path={GAME_URLS.PLAY_COMPUTER}
                component={ComputerGame}
            />
            <Route
                exact
                path={GAME_URLS.PLAY_OPPONENT}
                component={OpponentGame}
            />
            <Route exact path={GAME_URLS.GAME} component={OpponentGame} />
            <Route exact path={SUPPORT.SUPPORT} component={Support} />
            <Route exact path={SUPPORT.SUPPORT_SLUG} component={SupportSlug} />
            <Route
                exact
                path={SUPPORT.SUPPORT_ARTICLE}
                component={SupportArticle}
            />
            <Route exact path={MAIN_URLS.TERMS} component={Terms} />
            <Route exact path={MAIN_URLS.PRIVACY} component={Privacy} />
            <Route exact path={MAIN_URLS.PLAYHUB} component={OmniHub} />
            <Route exact path={WATCH_HUB_URLS.WATCH} component={OmniHub} />
            <Route exact path={MAIN_URLS.CHESS_RULES} component={ChessRules} />
            <Route exact path={MAIN_URLS.LEGACY} component={Legacy} />
            <Route exact path={PGN_READER.PGNReaderGCT} component={PGNReader} />
            <Route
                exact
                path={MAIN_URLS.DESIGN_SYSTEM}
                component={DesignSystem}
            />
            <Route
                exact
                path={ONBOARDING_URLS.LOGIN}
                render={() => <Login auth2={props.auth2} />}
            />

            <Route
                exact
                path={WATCH_HUB_URLS.CHESS_JOURNEYS}
                component={VideoCategories}
            />
            <Route
                exact
                path={WATCH_HUB_URLS.DOCUMENTARIES}
                component={VideoCategories}
            />
            <Route
                exact
                path={WATCH_HUB_URLS.INTERVIEWS}
                component={VideoCategories}
            />
            <Route
                exact
                path={WATCH_HUB_URLS.ALL_VIDEOS}
                component={AllVideos}
            />
            <Route exact path={LEARN_HUB_URLS.LEARN} component={OmniHub} />
            <Route exact path={LEARN_HUB_URLS.LESSONS} component={Lessons} />
            <Route
                exact
                path={LEARN_HUB_URLS.LESSON_EPISODE}
                component={LessonsEpisode}
            />
            <Route exact path={PROGRAMS.PROGRAMS_HUB} component={ProgramsHub} />
            <Route exact path={PROGRAMS.PROGRAM_PAGE} component={ProgramPage} />
            <Route
                exact
                path={RESULTS_HUB_URLS.RESULTS_HUB}
                component={OmniHub}
            />
            <Route
                exact
                path={RESULTS_HUB_URLS.GLOBAL_RANKING}
                component={GlobalRanking}
            />
            <Route
                exact
                path={WATCH_HUB_URLS.CHESS_JOURNEYS}
                component={VideoCategories}
            />
            <Route
                exact
                path={WATCH_HUB_URLS.DOCUMENTARIES}
                component={VideoCategories}
            />
            <Route
                exact
                path={WATCH_HUB_URLS.INTERVIEWS}
                component={VideoCategories}
            />
            <Route
                exact
                path={WATCH_HUB_URLS.ALL_VIDEOS}
                component={AllVideos}
            />
            <Route
                exact
                path={WATCH_HUB_URLS.WATCH_WILDCARD}
                component={Video}
            />
            <Route exact path={UPGRADE.UPGRADE_ACC} component={Upgrade} />
            <Route
                exact
                path={[UPGRADE.WELCOME_YEARLY, UPGRADE.WELCOME_MONTHLY]}
                component={WelcomePage}
            />
            <Route exact path={ARTICLES_URLS.ARTICLES} component={Articles} />
            <Route
                exact
                path={ARTICLES_URLS.ARTICLE_WILDCARD}
                component={Article}
            />
            <Route
                exact
                path={MASTERCLASS_URLS.MASTERCLASS}
                component={Masterclass}
            />
            <Route
                exact
                path={ONBOARDING_URLS.REGIESTER}
                component={EmailRegistrationNoConfirm}
            />
            {/* <Route
                exact
                path={ONBOARDING_URLS.REGIESTER}
                component={EmailRegistration}
            /> */}
            <Route
                exact
                path={ONBOARDING_URLS.VERIFY_EMAIL}
                component={EmailRegistration2}
            />
            <Route
                exact
                path={ONBOARDING_URLS.REGISTER_SOCIAL}
                component={SocialRegistration}
            />
            <Route
                exact
                path={ONBOARDING_URLS.RESET_PASSWORD}
                component={PasswordRecovery}
            />
            <Route
                exact
                path={SPECTATING_URLS.SPECTATE}
                component={OTBTournamentList}
            />
            <Route
                exact
                path={SPECTATING_URLS.TOURNAMENT_GAME_LIST}
                component={OTBGameList}
            />
            <Route
                exact
                path={SPECTATING_URLS.SPECTATE_OTB_GAME}
                component={SpectateOTBGame}
            />
            <Route
                exact
                path={SPECTATING_URLS.SPECTATE_GCT_GAME}
                component={SpectateGCTGame}
            />
            <Route
                exact
                path={GAME_SETTINGS_URLS.SETTINGS_WILDCARD}
                component={GameSettings}
            />
            <Route
                exact
                path={FALLBACK.SCHEDULE_MAINTENANCE}
                component={ScheduleMaintenance}
            />

            <Route exact path={FALLBACK.FOUR_O_FOUR} component={FourOFour} />
            <Redirect to={FALLBACK.FOUR_O_FOUR} />
        </Switch>
    );
};

export default RouterWrapper;
