import * as constants from './constants';

const initialState = {
    home: {
        homeState: { inProgress: null, success: null, error: null },
        data: null,
    },
    playhub: {
        playhubState: { inProgress: null, success: null, error: null },
        data: null,
    },
    learn: {
        learnState: { inProgress: null, success: null, error: null },
        data: null,
    },
    watch: {
        watchState: { inProgress: null, success: null, error: null },
        data: null,
    },
    articles: {
        inProgress: null,
        success: null,
        error: null,
        allArticles: {
            data: null,
            mostViewed: null,
        },
        singleArticle: {
            data: null,
        },
    },
    videos: {
        inProgress: null,
        success: null,
        error: null,
        allVideos: {
            data: null,
        },
        singleVideo: {
            data: null,
        },
    },
    videoCategory: {
        inProgress: null,
        success: null,
        error: null,
        interviews: { data: null },
        documentaries: { data: null },
        chessJourneys: { data: null },
    },
};

const fetchAllArticles = (state, action) => {
    const data = state.articles.allArticles.data
        ? [...state.articles.allArticles.data, ...action.data]
        : action.data;

    return {
        ...state,
        articles: {
            ...state.articles,
            inProgress: null,
            success: true,
            allArticles: {
                ...state.articles.allArticles,
                data,
            },
        },
    };
};

const fetchAllVideosReq = (state, action) => {
    const offsetValue = action.data.offset >= 12;
    let value;
    switch (true) {
        case action.data.selectedType === 'all' && offsetValue:
        case action.data.selectedType === 'documentaries' && offsetValue:
        case action.data.selectedType === 'chess journeys' && offsetValue:
        case action.data.selectedType === 'interviews' && offsetValue:
            value = [...state.videos.allVideos.data];
            break;

        default:
            value = null;
            break;
    }

    return {
        ...state,
        videos: {
            ...state.videos,
            inProgress: true,
            success: null,
            error: null,
            singleVideo: { data: null },
            allVideos: {
                data: value,
            },
        },
    };
};

const fetchAllVideos = (state, action) => {
    const data = state.videos.allVideos.data
        ? [...state.videos.allVideos.data, ...action.data]
        : action.data;

    return {
        ...state,
        videos: {
            ...state.videos,
            inProgress: null,
            success: true,
            allVideos: { data },
        },
    };
};

const fetchVideoCategoriesSuccess = (state, action) => {
    switch (true) {
        case action.data.name.toLowerCase() === 'documentaries':
            return {
                ...state,
                videoCategory: {
                    ...state.videoCategory,
                    inProgress: null,
                    success: true,
                    documentaries: {
                        data: action.data,
                    },
                },
            };
        case action.data.name.toLowerCase() === 'interviews' || action.data.name.toLowerCase() === 'interviews ':
            return {
                ...state,
                videoCategory: {
                    ...state.videoCategory,
                    inProgress: null,
                    success: true,
                    interviews: {
                        data: action.data,
                    },
                },
            };
        case action.data.name.toLowerCase() === 'chess journeys':
            return {
                ...state,
                videoCategory: {
                    ...state.videoCategory,
                    inProgress: null,
                    success: true,
                    chessJourneys: {
                        data: action.data,
                    },
                },
            };
        default:
            return state;
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_HOME_REQUEST:
            return {
                ...state,
                home: {
                    ...state.home,
                    homeState: {
                        inProgress: true,
                    },
                },
            };
        case constants.GET_HOME_SUCCESS:
            return {
                ...state,
                home: {
                    ...state.home,
                    homeState: {
                        inProgress: false,
                        success: true,
                    },
                    data: action.data,
                },
            };
        case constants.GET_HOME_FAILURE:
            return {
                ...state,
                home: {
                    ...state.home,
                    homeState: {
                        inProgress: false,
                        error: action.error,
                    },
                },
            };
        case constants.GET_PLAYHUB_REQUEST:
            return {
                ...state,
                playhub: {
                    ...state.playhub,
                    playhubState: {
                        inProgress: true,
                    },
                },
            };
        case constants.GET_PLAYHUB_SUCCESS:
            return {
                ...state,
                playhub: {
                    ...state.playhub,
                    playhubState: {
                        inProgress: false,
                        success: true,
                    },
                    data: action.data,
                },
            };
        case constants.GET_PLAYHUB_FAILURE:
            return {
                ...state,
                playhub: {
                    ...state.playhub,
                    playhubState: {
                        inProgress: false,
                        error: action.error,
                    },
                },
            };
        case constants.GET_LEARN_REQUEST:
            return {
                ...state,
                learn: {
                    ...state.learn,
                    learnState: {
                        inProgress: true,
                    },
                },
            };
        case constants.GET_LEARN_SUCCESS:
            return {
                ...state,
                learn: {
                    ...state.learn,
                    learnState: {
                        inProgress: false,
                        success: true,
                    },
                    data: action.data,
                },
            };
        case constants.GET_LEARN_FAILURE:
            return {
                ...state,
                learn: {
                    ...state.learn,
                    learnState: {
                        inProgress: false,
                        error: action.error,
                    },
                },
            };
        case constants.GET_WATCH_REQUEST:
            return {
                ...state,
                watch: {
                    ...state.watch,
                    watchState: {
                        inProgress: true,
                    },
                },
            };
        case constants.GET_WATCH_SUCCESS:
            return {
                ...state,
                watch: {
                    ...state.watch,
                    watchState: {
                        inProgress: false,
                        success: true,
                    },
                    data: action.data,
                },
            };
        case constants.GET_WATCH_FAILURE:
            return {
                ...state,
                watch: {
                    ...state.watch,
                    watchState: {
                        inProgress: false,
                        error: action.error,
                    },
                },
            };
        case constants.GET_ALL_ARTICLES_REQUEST:
        case constants.GET_SINGLE_ARTICLE_REQUEST:
        case constants.GET_MOST_VIEWED_ARTICLES_REQUEST:
            return {
                ...state,
                articles: {
                    ...state.articles,
                    inProgress: true,
                    singleArticle: { data: null },
                },
			};
		case constants.CLEAR_ALL_ARTICLES:
            return {
                ...state,
                articles: {
					inProgress: null,
					success: null,
					error: null,
					allArticles: {
						data: null,
						mostViewed: null,
					},
					singleArticle: {
						data: null,
					},
				},
			};
        case constants.GET_ALL_ARTICLES_SUCCESS:
            return fetchAllArticles(state, action);
        case constants.GET_SINGLE_ARTICLE_SUCCESS:
            return {
                ...state,
                articles: {
                    ...state.articles,
                    inProgress: null,
                    success: true,
                    singleArticle: {
                        data: action.data,
                    },
                },
            };
        case constants.GET_MOST_VIEWED_ARTICLES_SUCCESS:
            return {
                ...state,
                articles: {
                    ...state.articles,
                    inProgress: null,
                    success: true,
                    allArticles: {
                        ...state.articles.allArticles,
                        mostViewed: action.data,
                    },
                },
            };
        case constants.GET_ALL_ARTICLES_FAILURE:
        case constants.GET_SINGLE_ARTICLE_FAILURE:
        case constants.GET_MOST_VIEWED_ARTICLES_FAILURE:
            return {
                ...state,
                articles: {
                    ...state.articles,
                    inProgress: null,
                    error: action.error,
                },
            };
        case constants.GET_ALL_VIDEOS_REQUEST:
            return fetchAllVideosReq(state, action);
        case constants.GET_SINGLE_VIDEO_REQUEST:
            return {
                ...state,
                videos: {
                    ...state.videos,
                    inProgress: true,
                    success: null,
                    error: null,
                    singleVideo: { data: null },
                },
            };
        case constants.GET_ALL_VIDEOS_SUCCESS:
            return fetchAllVideos(state, action);
        case constants.GET_SINGLE_VIDEO_SUCCESS:
            return {
                ...state,
                videos: {
                    ...state.videos,
                    inProgress: null,
                    success: true,
                    singleVideo: {
                        data: action.data,
                    },
                },
            };
        case constants.GET_ALL_VIDEOS_FAILURE:
        case constants.GET_SINGLE_VIDEO_FAILURE:
            return {
                ...state,
                videos: {
                    ...state.videos,
                    inProgress: null,
                    error: action.error,
                },
            };
        case constants.GET_VIDEO_CATEGORY_REQUEST:
            return {
                ...state,
                videoCategory: {
                    ...state.videoCategory,
                    inProgress: true,
                    success: null,
                    error: null,
                },
            };
        case constants.GET_VIDEO_CATEGORY_SUCCESS:
            return fetchVideoCategoriesSuccess(state, action);
        case constants.GET_VIDEO_CATEGORY_FAILURE:
            return {
                ...state,
                videoCategory: {
                    ...state.videoCategory,
                    inProgress: null,
                    error: action.error,
                },
            };
        default:
            return state;
    }
};

export default reducer;
