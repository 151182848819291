import API from 'API';
import { formatDateFromISO, months } from '../../shared/date';

const API_LIST = { GET_STATS: '/users/:userId/stats' };

export function getStats(params) {
    const apiParams = {};
    if (params.mode !== 'all') {
        apiParams.kind = params.mode;
    }
    if (!params.isYearMode) {
        apiParams.month = formatDateFromISO(
            '02-' + months[params.month] + ' ' + params.year,
            'Y-M-D'
        );
    } else {
        apiParams.year = params.year;
    }
    return API.get(API_LIST.GET_STATS.replace(':userId', params.id), {
        params: apiParams,
    });
}
