import { takeLatest, put as dispatch } from 'redux-saga/effects';

import * as actions from './actions';
import * as api from './api';
import * as constants from './constants';
import { addToast } from 'store/toast/actions';

export function* userProfileSaga() {
    yield takeLatest(constants.GET_CURRENT_USER_REQUEST, getCurrentUserProfile);
    yield takeLatest(constants.GET_USER_REQUEST, getUserProfile);
    yield takeLatest(constants.ADD_A_FRIEND_REQUEST, addAsFriend);
    yield takeLatest(constants.REMOVE_A_FRIEND_REQUEST, removeAsFriend);
    yield takeLatest(constants.GET_MY_BADGES_REQUEST, getMyBadges);
    yield takeLatest(constants.GET_PROFILE_BADGES_REQUEST, getProfileBadges);
}

function* getUserProfile(params) {
    try {
        const response = yield api.getUserProfile(params);
        yield dispatch(actions.getUserProfileSuccess(response.data.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('getUserProfile failed', e);
        yield dispatch(actions.getUserProfileFailiure(e));
    }
}

function* getCurrentUserProfile() {
    try {
        const response = yield api.getCurrentUserProfile();
        yield dispatch(
            actions.getCurrentUserProfileSuccess(response.data.data)
        );
    } catch (e) {
        //eslint-disable-next-line
        console.warn('getCurrentUserProfile failed', e);
        yield dispatch(actions.getCurrentUserProfileFailiure(e));
    }
}

function* addAsFriend(params) {
    try {
        yield api.addAsFriend(params);
        yield dispatch(actions.addAFriendSuccess());
        yield dispatch(
            addToast({
                type: 'success',
                text: {
                    title: `${params.username} added as friend`,
                },
                durationInSeconds: 5,
            })
        );
    } catch (e) {
        //eslint-disable-next-line
        console.warn('addAsFriend failed', e);
        yield dispatch(actions.addAFriendFailure(e));
        yield dispatch(
            addToast({
                type: 'error',
                text: {
                    title: `Something went wrong, please try again later`,
                },
                durationInSeconds: 5,
            })
        );
    }
}

function* removeAsFriend(params) {
    try {
        yield api.removeAsFriend(params);
        yield dispatch(actions.removeAsFriendSuccess());
        yield dispatch(
            addToast({
                type: 'success',
                text: {
                    title: `${params.username} removed as friend`,
                },
                durationInSeconds: 5,
            })
        );
    } catch (e) {
        //eslint-disable-next-line
        console.warn('removeAsFriend failed', e);
        yield dispatch(actions.removeAsFriendFailure(e));
        yield dispatch(
            addToast({
                type: 'error',
                text: {
                    title: `Something went wrong, please try again later`,
                },
                durationInSeconds: 5,
            })
        );
    }
}

function* getMyBadges() {
    try {
        const resp = yield api.getMyBadges();
        yield dispatch(actions.getMyBadgesSuccess(resp.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('getMyBadges failed', e);
        yield dispatch(actions.getMyBadgesFailure(e));
    }
}

function* getProfileBadges(params) {
    try {
        const resp = yield api.getProfileBadges(params);
        // eslint-disable-next-line
        console.log('getProfileBadges resp', resp.data);
        yield dispatch(actions.getProfileBadgesSuccess(resp.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('getProfileBadges failed', e);
        yield dispatch(actions.getProfileBadgesFailure(e));
    }
}
