import { takeLatest, put as dispatch } from 'redux-saga/effects';

import * as actions from './actions';
import * as api from './api';
import * as constants from './constants';
// import { addToast } from 'store/toast/actions';
import { redirect } from 'store/util/actions';

export function* strapiSaga() {
    yield takeLatest(constants.GET_HOME_REQUEST, getHomePage);
    yield takeLatest(constants.GET_PLAYHUB_REQUEST, getPlayhub);
    yield takeLatest(constants.GET_WATCH_REQUEST, getWatchHub);
    yield takeLatest(constants.GET_LEARN_REQUEST, getLearnHub);
    yield takeLatest(constants.GET_ALL_ARTICLES_REQUEST, getAllArticles);
    yield takeLatest(constants.GET_SINGLE_ARTICLE_REQUEST, getSingleArticle);
    yield takeLatest(
        constants.GET_MOST_VIEWED_ARTICLES_REQUEST,
        getMostViewedArticles
    );
    yield takeLatest(constants.GET_ALL_VIDEOS_REQUEST, getAllVideos);
    yield takeLatest(constants.GET_SINGLE_VIDEO_REQUEST, getSingleVideo);
    yield takeLatest(constants.GET_VIDEO_CATEGORY_REQUEST, getVideoCategory);
}

function* getHomePage() {
    try {
        const resp = yield api.getHomePage();
        yield dispatch(actions.getHomeSuccess(resp.data));
    } catch (error) {
        //eslint-disable-next-line
        console.warn('getHomePage failed', error);
        yield dispatch(actions.getHomeFailure(error));
    }
}

function* getPlayhub() {
    try {
        const resp = yield api.getPlayhubPage();
        yield dispatch(actions.getPlayhubSuccess(resp.data));
    } catch (error) {
        //eslint-disable-next-line
        console.warn('getPlayhub failed', error);
        yield dispatch(actions.getPlayhubFailure(error));
    }
}

function* getWatchHub() {
    try {
        const resp = yield api.getWatchPage();
        yield dispatch(actions.getWatchSuccess(resp.data));
    } catch (error) {
        //eslint-disable-next-line
        console.warn('getWatchHub failed', error);
        yield dispatch(actions.getWatchFailure(error));
    }
}

function* getLearnHub() {
    try {
        const resp = yield api.getLearnPage();
        yield dispatch(actions.getLearnSuccess(resp.data));
    } catch (error) {
        //eslint-disable-next-line
        console.warn('getLearnHub failed', error);
        yield dispatch(actions.getLearnFailure(error));
    }
}

function* getAllArticles(params) {
    try {
        const resp = yield api.getAllArticles(params);
        yield dispatch(actions.getAllArticlesSuccess(resp.data));
    } catch (error) {
        //eslint-disable-next-line
        console.warn('getAllArticles failed', error);
        yield dispatch(actions.getAllArticlesFailure(error));
    }
}

function* getSingleArticle(params) {
    try {
        const resp = yield api.getSingleArticle(params);
        yield dispatch(actions.getSingleArticleSuccess(resp.data));
    } catch (error) {
        //eslint-disable-next-line
        console.warn('getSingleArticle failed', error);
        yield dispatch(actions.getSingleArticleFailure(error));
    }
}

function* getMostViewedArticles() {
    try {
        const resp = yield api.getMostViewedArticles();
        yield dispatch(actions.getMostViewedArticlesSuccess(resp.data));
    } catch (error) {
        //eslint-disable-next-line
        console.warn('getMostViewedArticles failed', error);
        yield dispatch(actions.getMostViewedArticlesFailure(error));
    }
}

function* getAllVideos(params) {
    try {
        const resp = yield api.getAllVideos(params);
        yield dispatch(actions.getAllVideosSuccess(resp.data));
    } catch (error) {
        //eslint-disable-next-line
        console.warn('getAllVideos failed', error);
        yield dispatch(actions.getAllVideosFailure(error));
    }
}

function* getSingleVideo(params) {
    try {
        const resp = yield api.getSingleVideo(params);
        yield dispatch(actions.getSingleVideoSuccess(resp.data));
    } catch (error) {
        //eslint-disable-next-line
        console.warn('getSingleVideo failed', error);
        yield dispatch(actions.getSingleVideoFailure(error));
        if (error.response.data.statusCode === 403) {
            yield dispatch(redirect('/videos'));
        }
        if (error.response.data.statusCode === 404) {
            yield dispatch(redirect('/404'));
        }
    }
}

function* getVideoCategory(params) {
    try {
        const resp = yield api.getVideoCategory(params);
        yield dispatch(actions.getVideoCategorySuccess(resp.data));
    } catch (error) {
        //eslint-disable-next-line
        console.warn('getVideoCategory failed', error);
        yield dispatch(actions.getVideoCategoryFailure(error));
    }
}
