export const replaceWildcards = (url, valueArray) => {
    return url.replace(/\/:[a-zA-Z]+\??/g, () => {
        const shiftedValue = valueArray.shift();
        return shiftedValue ? '/' + shiftedValue : '';
    });
};

export const MAIN_URLS = {
    LANDING_PAGE: '/',
    HOME: '/home',
    LEGACY: '/legacy',
    TERMS: '/terms-and-conditions',
    PRIVACY: '/privacy-policy',
    PLAYHUB: '/play-online-chess',
    CHESS_RULES: '/chess-rules',
    DESIGN_SYSTEM: '/design-system',
};

export const GAME_URLS = {
    NEW_GAME: '/new-game',
    GAME: '/live-game/:id?',
    PLAY_COMPUTER: '/game/computer',
    PLAY_OPPONENT: '/game/opponent/:id?',
    PLAY_FRIEND: '/game/friend/:id?',
    PLAY_DAILY: '/correspondence/:id',
    SPECTATE_GAME: '/spectate/:id',
};

export const ONBOARDING_URLS = {
    LOGIN: '/login',
    ONBOARDING: '/onboarding',
    REGIESTER: '/register',
    VERIFY_EMAIL: '/verify_email',
    REGISTER_SOCIAL: '/register/:social',
    RESET_PASSWORD: '/reset_password',
};

export const SPECTATING_URLS = {
    SPECTATE: '/spectate',
    TOURNAMENT_GAME_LIST: '/game-list/:tournamentId/:categoryId/:roundId',
    SPECTATE_OTB_GAME: '/spectate/otb/:id',
    SPECTATE_GCT_GAME: '/gct/game/:id',
};

export const GAME_SETTINGS_URLS = {
    SETTINGS_WILDCARD: '/game-type/:type',
    SETTINGS_OPPONENT: '/game-type/opponent',
    SETTINGS_FRIEND: '/game-type/friend',
    SETTINGS_TOURNAMENT: '/game-type/tournament',
    SETTINGS_COMPUTER: '/game-type/computer',
    SETTINGS_CUSTOM_GAME: '/game-type/custom',
};

export const GLOBAL_SETTINGS_URLS = {
    GLOBAL_SETTINGS_WILDCARD: '/settings/:type',
    BOARD_AND_PIECES: '/settings/board-and-pieces',
    LIVE_CHESS: '/settings/live-chess',
    DAILY_CHESS: '/settings/daily-chess',
};

export const PRIVACY_SETTINGS = {
    PRIVACY_SETTINGS_WILDCARD: '/settings/privacy-settings/:screen',
    PERSONAL_INFOS: '/settings/privacy-settings/personal-info',
    SUBSCRIPTION: '/settings/privacy-settings/subscription',
    PRIVACY: '/settings/privacy-settings/privacy',
    BLOCKING: '/settings/privacy-settings/blocking',
    FRIENDS: '/settings/privacy-settings/friends',
    NOTIFICATIONS: '/settings/privacy-settings/notifications',
    PASSWORD_CHANGE: '/settings/privacy-settings/password-change',
};

export const USER_PROFILE = {
    PROFILE: '/profile/:id',
    STATS: '/stats/:id/:control?',
    STATS_WITH_CONTROL: '/stats/:id/:control',
    ACHIEVEMENTS: '/achievements/:id',
};

export const GAME_HISTORY = {
    LIST_HISTORY: '/game-history/:id/:filter?/:year?/:month?',
    LIST_HISTORY_BASE: '/game-history/',
    POST_GAME_ANALYSIS: '/post-game-analysis/:id',
};

export const RESULTS_HUB_URLS = {
    RESULTS_HUB: '/results',
    GLOBAL_RANKING: '/global-ranking',
};

export const ARTICLES_URLS = {
    ARTICLES: '/articles',
    ARTICLE_WILDCARD: '/articles/:id',
};

export const CORRESPONDENCE_GAMES_URLS = {
    CORRESPONDENCE_GAMES: '/correspondence-games',
};

export const WATCH_HUB_URLS = {
    WATCH: '/watch',
    ALL_VIDEOS: '/videos',
    WATCH_WILDCARD: '/videos/:id',
    CHESS_JOURNEYS: '/chess-journeys',
    DOCUMENTARIES: '/documentaries',
    GAME_HISTORY: '/game-history',
    GCT: '/gct',
    INTERVIEWS: '/interviews',
    LIVE_GAMES: '/live-games',
};

export const LEARN_HUB_URLS = {
    LEARN: '/learn',
    LESSONS: '/lessons',
    LESSON_EPISODE: '/lessons/:id',
};

export const MASTERCLASS_URLS = {
    MASTERCLASS: '/masterclass',
    MASTERCLASS_EPISODE: '/masterclass/chapter/:id',
};

export const GCT_URLS = {
    MAIN: '/grand-chess-tour',
    TOURNAMENT: '/grand-chess-tour/:id',
};

export const SUPPORT = {
    SUPPORT: '/faq-support',
    REQUEST: '/faq-support/request',
    SUPPORT_SLUG: '/faq-support/:slug',
    SUPPORT_ARTICLE: '/support_articles/:slug',
};

export const UPGRADE = {
    UPGRADE_ACC: '/upgrade-account',
    SUBSCRIPTION_SUCCESS: '/payment/success',
    SUBSCRIPTION_CANCEL: '/payment/cancel',
    WELCOME_YEARLY: '/welcome-you',
    WELCOME_MONTHLY: '/welcome-me',
};

export const PRODUCT_PAYMENT = {
    ROOT: '/product-payment',
    SUCCESS: '/product-payment/success',
    CANCEL: '/product-payment/cancel',
};

export const PUZZLE = {
    PUZZLE_HUB: '/puzzles',
    PUZZLE: '/puzzle/:level/:theme?/:subtheme?',
    PUZZLE_DAILY: '/puzzles/daily/:id',
};

export const PGN_READER = {
    PGNReader: '/pgn-reader/:type/:id',
    PGNReaderGCT: '/pgn-reader/gct/:id',
};

export const FALLBACK = {
    FOUR_O_FOUR: '/404',
    SCHEDULE_MAINTENANCE: '/schedule-maintenance',
};

export const PROGRAMS = {
    PROGRAMS_HUB: '/programs',
    PROGRAM_PAGE: '/program/:id',
    PROGRAM_CHAPTER: '/program-chapter/:id',
};
