import React from 'react';

import styles from './Modal.module.scss';

export default function Modal({ children, opened, onClose }) {
    React.useEffect(() => {
        opened
            ? (document.body.style.overflow = 'hidden')
            : (document.body.style.overflow = 'unset');
    }, [opened]);

    if (!opened) {
        return null;
    }

    return (
        <>
            <div className={styles.darkBG} onClick={() => onClose(false)} />
            <div className={styles.centered}>
                <div className={styles.modal}>
                    <div className={styles.modalContent}>{children}</div>
                </div>
            </div>
        </>
    );
}
