import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';

import FallbackBrowser from 'components/Fallback/Fallback';

import { getSubscriptionPlansRequest } from 'store/subscriptions/actions';
import { getFooterRequest } from 'store/footer/actions';
import { getUserAccessToken } from 'store/user/selector';
import { getBrowserInfo } from 'shared/utility';

import styles from './Layout.module.scss';

const Layout = ({ children, location, ...props }) => {
    const dispatch = useDispatch();
	const [displayCookieBanner, setDisplayCookieBanner] = useState(typeof localStorage._k_sqr_cookies_sb === 'undefined');
    const token = useSelector(getUserAccessToken, shallowEqual);
    const [viewMoreStyle, setViewMoreStyle] = useState([
        styles.view_more_link,
        styles.hide,
    ]);
    const [renderChildren, setRenderChildren] = useState(true);

    const scrollingHandler = () => {
        window.scrollY < window.innerHeight
            ? setViewMoreStyle([styles.view_more_link, styles.hide])
            : setViewMoreStyle([styles.view_more_link, styles.show]);
    };

    useEffect(() => {
        window.addEventListener('scroll', scrollingHandler, false);
        return () => {
            window.removeEventListener('scroll', scrollingHandler, false);
        };
    }, []);

    useEffect(() => {
        if (token) {
            dispatch(getSubscriptionPlansRequest());
        }

        dispatch(getFooterRequest());
    }, [token, dispatch]);

    useEffect(() => {
        getBrowserVersionAndName();
    }, []);

	const setAcceptCookies = (bool) => {
		window.cookie_consent_statistics = bool;
		localStorage._k_sqr_c_acc = bool;
		localStorage._k_sqr_cookies_sb = false;
		setDisplayCookieBanner(false);
	};

    const scrollToTop = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    };

    const getBrowserVersionAndName = () => {
        const browser = getBrowserInfo();
        const browserName = browser.browser;
        const versionArray = browser.browserVersion.split('.');
        const version = parseInt(versionArray[0]);

        const isChrome = browserName === 'Chrome' && version >= 80;
        const isFirefox = browserName === 'Firefox' && version >= 77;
        const isSafari = browserName === 'Safari' && version >= 13;
        const isEdge = browserName === 'Microsoft Edge' && version >= 82;
        const isOpera = browserName === 'Opera' && version >= 66;

        if (isChrome || isFirefox || isSafari || isEdge || isOpera) {
            return setRenderChildren(true);
        } else {
            return setRenderChildren(false);
        }
    };

    return (
        <div className={styles.layout}>
            <main className={styles.main}>
                {renderChildren ? (
                    children
                ) : (
                    <FallbackBrowser text="browser version" />
                )}
                <div
                    className={viewMoreStyle.join(' ')}
                    role="button"
                    onClick={scrollToTop}
                />
            </main>
			<div className={[styles.cookie_banner, displayCookieBanner ? styles.show_banner : styles.hide_banner].join(' ')}>
				<div>
					<h4>We use cookies on this website to enhance your experience</h4>
					<p>Cookies are collected for analytics and metrics about our visitors. To find out more about the cookies we use, see our <Link to='/privacy-policy'>Privacy Policy</Link>.</p>
				</div>
				<div>
					<button
						type="button"
						onClick={() => setAcceptCookies(false)}
						className={styles.btn}
					>
						No, thanks
					</button>
					<button
						type="button"
						onClick={() => setAcceptCookies(true)}
						className={styles.btn}
					>
						Yes, I accept
					</button>
				</div>
			</div>
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.element,
    location: PropTypes.object,
};

export default withRouter(Layout);
