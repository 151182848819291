import { takeLatest, put as dispatch } from 'redux-saga/effects';

import * as actions from './actions';
import * as api from './api';
import * as constants from './constants';

export function* puzzleSaga() {
    yield takeLatest(constants.GET_PUZZLE_TAGS_REQUEST, getPuzzleTags);
    yield takeLatest(constants.GET_PUZZLE_STATS_REQUEST, getPuzzleStats);
    yield takeLatest(constants.GET_PUZZLE_BATCH_REQUEST, getPuzzleBatch);
    yield takeLatest(constants.GET_PUZZLE_DAILY_REQUEST, getPuzzleDaily);
    yield takeLatest(constants.SEND_PUZZLE_RESULT_REQUEST, sendPuzzleResult);
    yield takeLatest(constants.SEND_PUZZLE_FROM_LESSONS_RESULT_REQUEST, sendPuzzleFromLessonsResult);
    yield takeLatest(constants.GET_DAILY_PUZZLES_REQUEST, getDailyPuzzles);
    yield takeLatest(constants.GET_PUZZLE_5DLIMIT_STATUS, getPuzzle5DLimitStatus);
}

function* getPuzzleTags(params) {
    try {
        const response = yield api.getPuzzleTags(params);
        yield dispatch(actions.getPuzzleTagsSuccess(response.data.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('getPuzzleTags failed', e);
        yield dispatch(actions.getPuzzleTagsFailure(e));
    }
}

function* getPuzzleStats(params) {
    try {
        const response = yield api.getPuzzleStats(params);
        yield dispatch(actions.getPuzzleStatsSuccess(response.data.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('getPuzzleStats failed', e);
        yield dispatch(actions.getPuzzleStatsFailure(e));
    }
}

function* getPuzzle5DLimitStatus() {
    try {
        const response = yield api.getRemaining();
        yield dispatch(actions.getPuzzle5DLimitStatusSuccess(response.data.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('getPuzzle5DLimitStatus failed', e);
        yield dispatch(actions.getPuzzle5DLimitStatusFailure(e));
    }
}

function* getPuzzleBatch(params) {
    try {
        const number = yield api.getRemaining();
        let puzzleLeft = true;
        let used = 0;
        if (number && number.data && number.data.data) {
            puzzleLeft = number.data.data.puzzlesLeft;
            used = number.data.data.played.length;
        }

        if (used && used < 5) {
            params.data.limit = params.data.limit - used;
        }

        if (!puzzleLeft) {
            yield dispatch(actions.getPuzzleBatchDailyLimit());
        } else {
            const response = yield api.getPuzzleBatch(params);
            yield dispatch(actions.getPuzzleBatchSuccess({...response.data.data, used}));
		}
    } catch (e) {
        if (e.response && e.response.data && e.response.data.error === 'Free quota reached') {
            yield dispatch(actions.getPuzzleBatchDailyLimit(e));
        } else {
            //eslint-disable-next-line
            console.warn('getPuzzleBatch failed', e);
            yield dispatch(actions.getPuzzleBatchFailure(e));
        }
    }
}

function* getPuzzleDaily(params) {
    try {
        const response = yield api.getPuzzleByID(params.data);
        yield dispatch(actions.getPuzzleDailySuccess(response.data.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('getPuzzleDaily failed', e);
        yield dispatch(actions.getPuzzleDailyFailure(e));
    }
}

function* sendPuzzleResult(params) {
    try {
        const response = yield api.sendPuzzleResult(params.id, params.status);
        const data = { ...response.data.data, puzzleId: params.id };
        yield dispatch(actions.sendPuzzleResultSuccess(data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('sendPuzzleResult failed', e);
        yield dispatch(actions.sendPuzzleResultFailure(e));
    }
}

function* sendPuzzleFromLessonsResult(params) {
    try {
        const response = yield api.sendPuzzleFromLessonsResult(params.payload);
        const data = { ...response.data.data };
        yield dispatch(actions.sendPuzzleFromLessonsResultSuccess(data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('sendPuzzleFromLessonsResult failed', e);
        yield dispatch(actions.sendPuzzleFromLessonsResultFailure(e));
    }
}

function* getDailyPuzzles(params) {
    try {
        const response = yield api.getDailyPuzzles();
        yield dispatch(actions.getDailyPuzzlesSuccess(response.data.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('getDailyPuzzles failed', e);
        yield dispatch(actions.getDailyPuzzlesFailiure(e));
    }
}
