import API from 'API';

const API_LIST = {
    TOKEN_REFRESH: '/users/token_refresh',
    LOGIN_REQUEST: '/users/login',
    LOGOUT_REQUEST: '/users/logout',
    FACEBOOK_LOGIN_REQUEST: '/users/facebook',
    GOOGLE_LOGIN_REQUEST: '/users/google',
    APPLE_LOGIN_REQUEST: '/users/apple',
    REGISTER_REQUEST: '/users/register',
    REGISTER_NO_CONFIRM_REQUEST: '/users/register_no_confirm',
    EMAIL_REGISTER_REQUEST: '/users/register_email',
    USER_REQUEST: '/users/profile',
    USERNAME_CHECK_REQUEST: '/users/check',
    USERNAME_EMAIL_REQUEST: '/users/check_email',
    START_PASSWORD_RECOVERY: '/users/start_password_recovery',
    RECOVER_PASSWORD: '/users/recover_password',
    CHAT_UID_REQUEST: '/users/comet_chat_token',
};

export function userRequest() {
    return API.get(API_LIST.USER_REQUEST);
}

export function setUser(params) {
    return API.post(API_LIST.USER_REQUEST, params.data);
}

export function setUserAvatar(params) {
    return API.post(API_LIST.USER_REQUEST, params.payload, params.config);
}

export function checkUserAuth(params) {
    return API.post(API_LIST.TOKEN_REFRESH, params);
}

export function loginRequest(params) {
    // eslint-disable-next-line no-console
    console.log('loginRequest api', params);
    return API.post(API_LIST.LOGIN_REQUEST, params, { withCredentials: true });
}

export function logoutRequest() {
    return API.get(API_LIST.LOGOUT_REQUEST, { withCredentials: true });
}

export function registerRequest(params) {
    return API.post(API_LIST.REGISTER_REQUEST, params, {
        withCredentials: true,
    });
}

export function registerNoConfirmRequest(params) {
    return API.post(API_LIST.REGISTER_NO_CONFIRM_REQUEST, params, {
        withCredentials: true,
    });
}

export function emailRegisterRequest(params) {
    return API.post(API_LIST.EMAIL_REGISTER_REQUEST, params);
}

export function facebookLoginRequest(params) {
    return API.post(API_LIST.FACEBOOK_LOGIN_REQUEST, params, {
        withCredentials: true,
    });
}

export function googleLoginRequest(params) {
    return API.post(API_LIST.GOOGLE_LOGIN_REQUEST, params, {
        withCredentials: true,
    });
}

export function appleLoginRequest(params) {
    return API.post(API_LIST.APPLE_LOGIN_REQUEST, params, {
        withCredentials: true,
    });
}

export function usernameCheckRequest(username) {
    return API.get(`${API_LIST.USERNAME_CHECK_REQUEST}?username=${username}`);
}

export function emailCheckRequest(email) {
    return API.get(`${API_LIST.USERNAME_EMAIL_REQUEST}?email=${email}`);
}

export function startPasswordRecovery(params) {
    return API.post(API_LIST.START_PASSWORD_RECOVERY, params);
}

export function recoverPassword(params) {
    return API.post(API_LIST.RECOVER_PASSWORD, params);
}

export function chatAuthTokenRequest(params) {
    return API.get(API_LIST.CHAT_UID_REQUEST, params);
}
