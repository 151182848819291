import { takeLatest, put as dispatch } from 'redux-saga/effects';

import * as actions from './actions';
import * as api from './api';
import * as constants from './actionConstants';

export function* userInteraction() {
    yield takeLatest(constants.ADD_AS_FRIEND_REQUEST, addAsFriend);
    yield takeLatest(constants.REMOVE_FRIEND_REQUEST, removeFriend);
    yield takeLatest(constants.BLOCK_PLAYER_REQUEST, blockPlayer);
    yield takeLatest(constants.UNBLOCK_PLAYER_REQUEST, unblockPlayer);
}

function* addAsFriend(params) {
    try {
        yield api.addAsFriend(params);
        yield dispatch(actions.addAsFriendSuccess());
    } catch (e) {
        //eslint-disable-next-line
        console.warn('addAsFriend failed', e);
        yield dispatch(actions.addAsFriendFailure(e));
    }
}

function* removeFriend(params) {
    try {
        yield api.removeAsFriend(params);
        yield dispatch(actions.removeFriendSuccess());
    } catch (e) {
        //eslint-disable-next-line
        console.warn('removeAsFriend failed', e);
        yield dispatch(actions.removeFriendFailure(e));
    }
}

function* blockPlayer(params) {
    try {
        yield api.blockPlayer(params);
        yield dispatch(actions.blockPlayerSuccess());
    } catch (e) {
        //eslint-disable-next-line
        console.warn('blockPlayer failed', e);
        yield dispatch(actions.blockPlayerFailure(e));
    }
}

function* unblockPlayer(params) {
    try {
        yield api.unBlockPlayer(params);
        yield dispatch(actions.unblockPlayerSuccess());
    } catch (e) {
        //eslint-disable-next-line
        console.warn('unBlockPlayer failed', e);
        yield dispatch(actions.unblockPlayerFailure(e));
    }
}
