import * as constants from './constants';

export const getStatsRequest = (month, year, mode, id, isYearMode) => {
    return {
        type: constants.GET_STATS_REQUEST,
        month,
        year,
        mode,
        id,
        isYearMode,
    };
};

export const getStatsSuccess = (data) => {
    return {
        type: constants.GET_STATS_SUCCESS,
        data,
    };
};

export const getStatsFailiure = (error) => {
    return {
        type: constants.GET_STATS_FAILURE,
        error,
    };
};
