// send notifications
export const SEND_NOTIFICATION_PASSWORD_SUCCESS =
    'NOTIFICATIONS:PASSWORD_SUCCESS';
export const SEND_NOTIFICATION_PASSWORD_INCORRECT =
    'NOTIFICATIONS:PASSWORD_INCORRECT';
export const SEND_NOTIFICATION_PASSWORD_MATCH =
    'NOTIFICATIONS:PASSWORD_DO_NOT_MATCH';
export const SEND_NOTIFICATION_CORRESPONDENCE = 'NOTIFICATIONS:CORRESPONDENCE';

// receive notifications
export const RECEIVE_NOTIFICATION_CORRESPONDENCE =
    'NOTIFICATIONS:RECEIVE_CORRESPONDENCE';
