import { takeLatest, put as dispatch } from 'redux-saga/effects';

import * as actions from './actions';
import * as api from './api';
import * as constants from './constants';

export function* liveGamesSaga() {
    yield takeLatest(constants.GET_LIVE_GAMES_REQUEST, getLiveGames);
}

function* getLiveGames(params) {
    try {
        const response = yield api.getLiveGames(params);
        yield dispatch(actions.getLiveGamesSuccess(response.data.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('getLiveGames failed', e);
        yield dispatch(actions.getLiveGamesFailure(e));
    }
}
