export default {
    en: {
        'home.app_name': 'Keysquare Chess',
        'home.learn': 'Learn',
        'home.play': 'Play',
        'home.watch': 'Watch',
        'home.results': 'Results',
        'home.gct': 'Grand Chess Tour',
        'home.legacy': 'Legacy',
    },
};
