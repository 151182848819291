import * as constants from './constants';

export const getFooterRequest = () => {
    return {
        type: constants.GET_FOOTER_REQUEST,
    };
};

export const getFooterSuccess = (data) => {
    return {
        type: constants.GET_FOOTER_SUCCESS,
        data,
    };
};

export const getFooterFailure = (error) => {
    return {
        type: constants.GET_FOOTER_FAILURE,
        error,
    };
};
