import API from 'API';

const API_LIST = {
    GET_GAME_SETTINGS: '/games/live',
};

export function getLiveGames(params) {
    const liveGameParams = params.data.username
        ? {
              username: params.data.username,
          }
        : {
              variant: params.data.variant,
              kind: params.data.kind,
              username: params.data.username,
              limit: 8,
              offset: 0,
          };
    return API.get(API_LIST.GET_GAME_SETTINGS, { params: liveGameParams });
}
