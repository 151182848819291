import { take } from 'redux-saga/effects';

import * as constants from './constants';

export function* gameSaga() {
    yield take(constants.SET_VARIANT_TYPE);
    yield take(constants.SET_TIME_CONTROL);
    yield take(constants.SET_RATED_GAME);
    yield take(constants.SET_PRIVATE_GAME);
    yield take(constants.SET_DIFFICULTY_LEVEL);
}
