import { all } from 'redux-saga/effects';
import { localeSaga } from 'store/locale/saga';
import { userSaga } from 'store/user/saga';
import { utilSaga } from 'store/util/saga';
import { gameSaga } from 'store/game/saga';
import { OTBTournamentSaga } from 'store/otbTournament/saga';
// import { connectGameWs } from 'store/gameWs/sagaWs';
import { userProfileSaga } from 'store/userProfile/saga';
import { gameHistorySaga } from 'store/gameHistory/saga';
import { statsSaga } from 'store/stats/saga';
import { subscriptionSaga } from 'store/subscriptions/saga';
import { puzzleSaga } from 'store/puzzle/saga';
import { footerSaga } from 'store/footer/saga';
import { settingsSaga } from 'store/settings/saga';
import { postGameAnalysisSaga } from 'store/postGameAnalysis/saga';
import { pgnReaderSaga } from 'store/pgnReader/saga';
import { liveGamesSaga } from 'store/liveGames/saga';
import { userInteraction } from 'store/gameWs/saga';
import { globalSettings } from 'store/globalSettings/saga';
import { strapiSaga } from 'store/strapi/saga';

export default function* rootSaga() {
    yield all([
        userSaga(),
        localeSaga(),
        utilSaga(),
        gameSaga(),
        OTBTournamentSaga(),
        // connectGameWs(),
        userProfileSaga(),
        gameHistorySaga(),
        statsSaga(),
        subscriptionSaga(),
        puzzleSaga(),
        footerSaga(),
        settingsSaga(),
        postGameAnalysisSaga(),
        pgnReaderSaga(),
        liveGamesSaga(),
        userInteraction(),
        globalSettings(),
        strapiSaga(),
    ]);
}
