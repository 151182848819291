import * as constants from './constants';

const initialState = {
    liveGames: {
        inProgress: false,
        success: null,
        data: {},
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_LIVE_GAMES_REQUEST:
            return {
                ...state,
                liveGames: { inProgress: true, success: null },
            };
        case constants.GET_LIVE_GAMES_FAILURE:
            return {
                ...state,
                liveGames: { inProgress: false, success: true },
            };
        case constants.GET_LIVE_GAMES_SUCCESS:
            return {
                ...state,
                liveGames: {
                    inProgress: false,
                    success: true,
                    data: action.data,
                },
            };

        default:
            return state;
    }
};

export default reducer;
