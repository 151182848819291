import React from 'react';
import { takeLatest, put as dispatch } from 'redux-saga/effects';

import * as actions from './actions';
import * as api from './api';
import * as constants from './constants';
import { redirect } from 'store/util/actions';

import { GAME_HISTORY, replaceWildcards } from 'shared/urlList';
import {addToast} from "../toast/actions";

import pga from 'assets/icons/pga_request.png';

export function* postGameAnalysisSaga() {
    yield takeLatest(
        constants.GET_POST_GAME_ANALYSIS_REQUEST,
        getPostGameAnalysis
    );
    yield takeLatest(
        constants.GET_POST_GAME_ANALYSIS_NOTIFICATION_REQUEST,
        getPostGameAnalysisNotifications
    );
}

function* getPostGameAnalysis(params) {
    try {
        const response = yield api.getPostGameAnalysis(params);
        yield dispatch(actions.getPostGameAnalysisSuccess(response.data.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('getPostGameAnalysis failed', e);
        yield dispatch(actions.getPostGameAnalysisFailure(e));
    }
}

function* getPostGameAnalysisNotifications({ id, completed }) {
    if (completed) {
        const url = replaceWildcards(GAME_HISTORY.POST_GAME_ANALYSIS, [id]);
        yield dispatch(redirect(url));
    }
    try {
        const response = yield api.getPostGameAnalysis({id, request: true});
        const data = response.data.data;
        if (data.status === 'completed') {
            const url = replaceWildcards(GAME_HISTORY.POST_GAME_ANALYSIS, [id]);
            yield dispatch(redirect(url));
        } else {
            const toast = {
                type: 'info',
                text: {
                    title: `The analysis of your game is underway. We'll notify you when it is ready.`,
                },
                durationInSeconds: 7,
				content: (
					<img
						src={pga}
						alt="badge"
						style={{ width: '75px', height: '75px' }}
					/>
				),
            };
            yield dispatch(addToast(toast));
        }
    } catch (e) {
        if (e.data && e.data.error && e.data.error === 'game_no_moves_to_analyse') {
            const toast = {
                type: 'error',
                text: {
                    title: `This game can not be analyzed as no moves were played.`,
                },
                durationInSeconds: 7,
            };
            yield dispatch(addToast(toast));
        } else {
            //eslint-disable-next-line
            console.warn('getPostGameAnalysisNotifications failed', e);
            yield dispatch(actions.getPostGameAnalysisNotificationFailure(e));
        }
    }
}
