import * as constants from './constants';

export const getPuzzleTagsRequest = (data) => {
    return {
        type: constants.GET_PUZZLE_TAGS_REQUEST,
        data,
    };
};

export const getPuzzleTagsFailure = (data) => {
    return {
        type: constants.GET_PUZZLE_TAGS_FAILURE,
        data,
    };
};

export const getPuzzleTagsSuccess = (data) => {
    return {
        type: constants.GET_PUZZLE_TAGS_SUCCESS,
        data,
    };
};

export const getPuzzleStatsRequest = (data) => {
    return {
        type: constants.GET_PUZZLE_STATS_REQUEST,
        data,
    };
};

export const getPuzzleStatsFailure = (data) => {
    return {
        type: constants.GET_PUZZLE_STATS_FAILURE,
        data,
    };
};

export const getPuzzleStatsSuccess = (data) => {
    return {
        type: constants.GET_PUZZLE_STATS_SUCCESS,
        data,
    };
};

export const getPuzzleBatchRequest = (data) => {
    return {
        type: constants.GET_PUZZLE_BATCH_REQUEST,
        data,
    };
};

export const getPuzzleBatchFailure = (data) => {
    return {
        type: constants.GET_PUZZLE_BATCH_FAILURE,
        data,
    };
};

export const getPuzzleBatchSuccess = (data) => {
    return {
        type: constants.GET_PUZZLE_BATCH_SUCCESS,
        data,
    };
};

export const getPuzzleBatchDailyLimit = (data) => {
    return {
        type: constants.GET_PUZZLE_BATCH_5DLIMIT,
        data,
    };
};

export const getPuzzleDailyRequest = (data) => {
    return {
        type: constants.GET_PUZZLE_DAILY_REQUEST,
        data,
    };
};

export const getPuzzleDailyFailure = (data) => {
    return {
        type: constants.GET_PUZZLE_DAILY_FAILURE,
        data,
    };
};

export const getPuzzleDailySuccess = (data) => {
    return {
        type: constants.GET_PUZZLE_DAILY_SUCCESS,
        data,
    };
};

export const sendPuzzleResultRequest = (id, status) => {
    return {
        type: constants.SEND_PUZZLE_RESULT_REQUEST,
        id,
        status,
    };
};

export const sendPuzzleResultFailure = (data) => {
    return {
        type: constants.SEND_PUZZLE_RESULT_FAILURE,
        data,
    };
};

export const sendPuzzleResultSuccess = (data) => {
    return {
        type: constants.SEND_PUZZLE_RESULT_SUCCESS,
        data,
    };
};

export const getDailyPuzzlesRequest = () => {
    return {
        type: constants.GET_DAILY_PUZZLES_REQUEST
    };
};

export const getDailyPuzzlesFailiure = (data) => {
    return {
        type: constants.GET_DAILY_PUZZLES_FAILURE,
        data,
    };
};

export const getDailyPuzzlesSuccess = (data) => {
    return {
        type: constants.GET_DAILY_PUZZLES_SUCCESS,
        data,
    };
};

export const sendPuzzleFromLessonsResultRequest = (payload) => {
    return {
        type: constants.SEND_PUZZLE_FROM_LESSONS_RESULT_REQUEST,
        payload,
    };
};

export const sendPuzzleFromLessonsResultFailure = (data) => {
    return {
        type: constants.SEND_PUZZLE_FROM_LESSONS_RESULT_FAILURE,
        data,
    };
};

export const sendPuzzleFromLessonsResultSuccess = (data) => {
    return {
        type: constants.SEND_PUZZLE_FROM_LESSONS_RESULT_SUCCESS,
        data,
    };
};

export const getPuzzle5DLimitStatus = () => {
    return {
        type: constants.GET_PUZZLE_5DLIMIT_STATUS,
    };
};

export const getPuzzle5DLimitStatusSuccess = (data) => {
    return {
		type: constants.GET_PUZZLE_5DLIMIT_STATUS_SUCCESS,
		data
    };
};

export const getPuzzle5DLimitStatusFailure = (data) => {
    return {
		type: constants.GET_PUZZLE_5DLIMIT_STATUS_FAILURE,
		data
    };
};
