import React, { useEffect } from 'react';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';

import styles from './ProductPaymentCancel.module.scss';
import { useHistory } from 'react-router-dom';

export default function ProductPaymentCancel() {
    const history = useHistory();

    useEffect(() => {
        history.push('/home');
    }, []);

    return (
        <div className={styles.product_payment_cancel}>
            <Logo />
            <h2 style={{ textAlign: 'center', marginTop: 20 }}>
                Something went wrong! <br />
                Please try again!
            </h2>
        </div>
    );
}
