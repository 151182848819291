import * as constants from './constantsProfile';
import { getUserLS } from 'shared/localStorage';
const userInfo = localStorage.user ? JSON.parse(getUserLS()) : '';
const avatar =
    userInfo && userInfo.avatar && userInfo.avatar.src
        ? userInfo.avatar.src
        : '';

const initialState = {
    personalInfos: {
        firstName: userInfo.firstname,
        lastName: userInfo.lastname,
        username: userInfo.username,
        birthDate: userInfo.birthdate,
        email: userInfo.email,
        password: userInfo.username,
        country: userInfo.country,
        avatar: avatar,
        timeZone: userInfo.timezone,
        FIDE: userInfo.fide_title,
        userRole: userInfo.user_tier,
        ksRating: userInfo.ks_rating,
		level: userInfo.level,
		registered_with: userInfo.registered_with
    },
    privacy: {
        chat: 'everyone',
        seeFriends: 'everyone',
        challenges: 'everyone',
        incomingFriendRequests: true,
    },
    notifications: {
        doNotDisturb: false,
        correspondenceTurn: 'push',
        newChallenge: 'push',
        correspondenceOver: 'email',
        otbTournamentAvailable: 'push',
        longTimeNoSee: 'email',
        friendsRequest: 'push',
        FIDERatingModerated: 'email',
        cardRejected: 'push',
        subscriptionConfirmed: 'push',
        newLearningContentAvailable: 'push',
        importantBroadcasting: 'push',
        weeklyNewsAndAdds: 'email',
        newContentTheme: 'push',
    },
};

const setFirstName = (state, action) => {
    return {
        ...state,
        personalInfos: {
            ...state.personalInfos,
            firstName: action.name,
        },
    };
};

const setLastName = (state, action) => {
    return {
        ...state,
        personalInfos: {
            ...state.personalInfos,
            lastName: action.name,
        },
    };
};
const setUserName = (state, action) => {
    return {
        ...state,
        personalInfos: {
            ...state.personalInfos,
            username: action.username,
        },
    };
};
const setEmail = (state, action) => {
    return {
        ...state,
        personalInfos: {
            ...state.personalInfos,
            email: action.email,
        },
    };
};

const setTimeZone = (state, action) => {
    return {
        ...state,
        personalInfos: {
            ...state.personalInfos,
            timeZone: action.data,
        },
    };
};

const setPassword = (state, action) => {
    return {
        ...state,
        personalInfos: {
            ...state.personalInfos,
            password: action.password,
        },
    };
};

const setAvatar = (state, action) => {
    return {
        ...state,
        personalInfos: {
            ...state.personalInfos,
            avatar: action.avatar,
        },
    };
};

const setCountry = (state, action) => {
    return {
        ...state,
        personalInfos: {
            ...state.personalInfos,
            country: action.country,
        },
    };
};

const setChat = (state, action) => {
    return {
        ...state,
        privacy: {
            ...state.privacy,
            chat: action.chat,
        },
    };
};

const setSeeFriends = (state, action) => {
    return {
        ...state,
        privacy: {
            ...state.privacy,
            seeFriends: action.data,
        },
    };
};
const setAllowGameChallenges = (state, action) => {
    return {
        ...state,
        privacy: {
            ...state.privacy,
            challenges: action.challenges,
        },
    };
};
const setIncomingRequests = (state, action) => {
    return {
        ...state,
        privacy: {
            ...state.privacy,
            incomingFriendRequests: action.request,
        },
    };
};

const setDoNotDisturb = (state, action) => {
    return {
        ...state,
        notifications: {
            ...state.notifications,
            doNotDisturb: action.data,
        },
    };
};

const setCorrespondenceTurn = (state, action) => {
    return {
        ...state,
        notifications: {
            ...state.notifications,
            correspondenceTurn: action.data,
        },
    };
};
const setNewChallenge = (state, action) => {
    return {
        ...state,
        notifications: {
            ...state.notifications,
            newChallenge: action.data,
        },
    };
};
const setCorrespondenceOver = (state, action) => {
    return {
        ...state,
        notifications: {
            ...state.notifications,
            correspondenceOver: action.data,
        },
    };
};
const setOtbTournamentAvailable = (state, action) => {
    return {
        ...state,
        notifications: {
            ...state.notifications,
            otbTournamentAvailable: action.data,
        },
    };
};
const setLongTimeNoSee = (state, action) => {
    return {
        ...state,
        notifications: {
            ...state.notifications,
            longTimeNoSee: action.data,
        },
    };
};
const setFriendsRequest = (state, action) => {
    return {
        ...state,
        notifications: {
            ...state.notifications,
            friendsRequest: action.data,
        },
    };
};
const setFIDERating = (state, action) => {
    return {
        ...state,
        notifications: {
            ...state.notifications,
            FIDERatingModerated: action.data,
        },
    };
};
const setCardRejected = (state, action) => {
    return {
        ...state,
        notifications: {
            ...state.notifications,
            cardRejected: action.data,
        },
    };
};
const setSubscriptionConfirmed = (state, action) => {
    return {
        ...state,
        notifications: {
            ...state.notifications,
            subscriptionConfirmed: action.data,
        },
    };
};
const setNewLearningContent = (state, action) => {
    return {
        ...state,
        notifications: {
            ...state.notifications,
            newLearningContentAvailable: action.data,
        },
    };
};
const setImportantBroadcasting = (state, action) => {
    return {
        ...state,
        notifications: {
            ...state.notifications,
            importantBroadcasting: action.data,
        },
    };
};
const setWeeklyNews = (state, action) => {
    return {
        ...state,
        notifications: {
            ...state.notifications,
            weeklyNewsAndAdds: action.data,
        },
    };
};
const setNewContentTheme = (state, action) => {
    return {
        ...state,
        notifications: {
            ...state.notifications,
            newContentTheme: action.data,
        },
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_FIRST_NAME:
            return setFirstName(state, action);
        case constants.SET_LAST_NAME:
            return setLastName(state, action);
        case constants.SET_USERNAME:
            return setUserName(state, action);
        case constants.SET_EMAIL:
            return setEmail(state, action);
        case constants.SET_CHANGED_PASSWORD:
            return setPassword(state, action);
        case constants.SET_COUNTRY:
            return setCountry(state, action);
        case constants.SET_AVATAR:
            return setAvatar(state, action);
        case constants.SET_TIME_ZONE:
            return setTimeZone(state, action);
        case constants.SET_PRIVACY_CHAT:
            return setChat(state, action);
        case constants.SET_SEE_FRIENDS:
            return setSeeFriends(state, action);
        case constants.SET_CHALLENGES:
            return setAllowGameChallenges(state, action);
        case constants.SET_INCOMING_FRIENDS_REQUESTS:
            return setIncomingRequests(state, action);
        case constants.SET_DO_NOT_DISTURB:
            return setDoNotDisturb(state, action);
        case constants.SET_CORRESPONDENCE_TURN:
            return setCorrespondenceTurn(state, action);
        case constants.SET_NEW_CHALLENGE:
            return setNewChallenge(state, action);
        case constants.SET_CORRESPONDENCE_OVER:
            return setCorrespondenceOver(state, action);
        case constants.SET_OTB_TOURNAMENT_AVAILABLE:
            return setOtbTournamentAvailable(state, action);
        case constants.SET_LONG_TIME_NO_SEE:
            return setLongTimeNoSee(state, action);
        case constants.SET_FRIENDS_REQUESTS:
            return setFriendsRequest(state, action);
        case constants.SET_FIDE_RATING:
            return setFIDERating(state, action);
        case constants.SET_CARD_REJECTED:
            return setCardRejected(state, action);
        case constants.SET_SUBSCRIPTION_CONFIRMED:
            return setSubscriptionConfirmed(state, action);
        case constants.SET_NEW_LEARNING_CONTENT:
            return setNewLearningContent(state, action);
        case constants.SET_IMPORTANT_BROADCASTING:
            return setImportantBroadcasting(state, action);
        case constants.SET_WEEKLY_NEWS:
            return setWeeklyNews(state, action);
        case constants.SET_NEW_CONTENT_THEME:
            return setNewContentTheme(state, action);
        default:
            return state;
    }
};

export default reducer;
