import API from 'API';
// import {getRandomInt} from "../../shared/number";

const API_LIST = {
    GET_TAGS: '/puzzles/tags',
    GET_STATS: '/puzzles/stats',
    GET_PUZZLE_BATCH: '/puzzles/search', // /puzzles/search?limit=:limit:&level=:level:&theme=1&subtheme=2
    GET_DAILY: '/puzzles/',
    SEND_RESULT: '/puzzles/_id_/result', // POST /puzzles/{puzzleId}/result
    GET_DAILY_PUZZLES: '/puzzles/daily', // {api_url}}\/puzzles\/daily
    GET_REMAINING_NUMBER: '/puzzles/left',
	SEND_RESULT_FROM_LESSONS: '/content/progress',
};

export function getPuzzleTags(params) {
    return API.get(API_LIST.GET_TAGS);
}

export function getPuzzleStats(params) {
    return API.get(API_LIST.GET_STATS);
}

export function getPuzzleBatch(data) {
    const params = data.data;
    return API.get(API_LIST.GET_PUZZLE_BATCH, {
        params: {
            limit: params.limit ? params.limit : 20,
            level: params.level,
            theme: params.theme,
            subtheme: params.subtheme ? params.subtheme : null,
        },
    });
}

export function getPuzzleByID(id) {
    return API.get(API_LIST.GET_DAILY + id);
}

export function getDailyPuzzles() {
    return API.get(API_LIST.GET_DAILY_PUZZLES);
}

export function sendPuzzleResult(id, solved) {
    // return {data: {data:{rating_diff: getRandomInt(solved ? 0 : -100, solved ? 100 : 0)}}}; // Mock data to prevent spending 5 day puzzle limit
    return API.post(API_LIST.SEND_RESULT.replace('_id_', id), {solved: solved});
}

export function getRemaining() {
    return API.get(API_LIST.GET_REMAINING_NUMBER);
}

export function sendPuzzleFromLessonsResult(data) {
	return API.post(API_LIST.SEND_RESULT_FROM_LESSONS, data);
}
