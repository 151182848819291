import API from 'API';
// import { mockData } from './mock';

const API_LIST = {
    GET_USER_PROFILE: '/users/',
    GET_CURRENT_USER_PROFILE: '/users/profile',
    ADD_AS_FRIEND: '/friends', //TODO Change to server provided url
    MY_BADGES: '/badges/my',
    PROFILE_BADGES: '/badges/user/',
};

export function getUserProfile(params) {
    // return mockData;
    return API.get(API_LIST.GET_USER_PROFILE + params.id);
}

export function getCurrentUserProfile() {
    // return mockData;
    return API.get(API_LIST.GET_CURRENT_USER_PROFILE);
}

export function addAsFriend(params) {
    return API.post(API_LIST.ADD_AS_FRIEND, params.data);
}

export function removeAsFriend(params) {
    return API.delete(API_LIST.ADD_AS_FRIEND + `/${params.userId}`);
}

export function getMyBadges() {
    return API.get(API_LIST.MY_BADGES);
}

export function getProfileBadges(params) {
    return API.get(API_LIST.PROFILE_BADGES + params.id);
}
