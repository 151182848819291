import React from 'react';

import styles from './PlayerAvatarAndBadge.module.scss';

const PlayerAvatar = ({ avatar, imageSize, level, snackbar, ...props }) => {
    if (snackbar) {
        return (
            <>
                {avatar ? (
                    <img
                        src={avatar}
                        alt="user avatar"
                        style={{
                            width: imageSize,
                            height: imageSize,
                            borderRadius: '50%',
                            zIndex: 50,
                        }}
                    />
                ) : level ? (
						<img
							src={require(`assets/icons/skill_levels/${level &&
								level}.png`)}
							alt="user default"
							style={{
								width: imageSize,
								height: imageSize,
								borderRadius: '50%',
								zIndex: 50,
							}}
						/>
					) : <img
							src={require(`assets/icons/skill_levels/beginner.png`)}
							alt="user default"
							style={{
								width: imageSize,
								height: imageSize,
								borderRadius: '50%',
								zIndex: 50,
							}}
						/>
					}
            </>
        );
    }

    return (
        <div className={styles.playerInfoicon}>
            {avatar && avatar.src ? (
                <img
                    src={avatar.src}
                    alt="user avatar"
                    style={{
                        width: imageSize,
                        height: imageSize,
                        borderRadius: '50%',
                    }}
                />
            ) : level ? (
				<img
					src={require(`assets/icons/skill_levels/${level &&
						level}.png`)}
					alt="user default"
					style={{
						width: imageSize,
						height: imageSize,
						borderRadius: '50%',
						zIndex: 50,
					}}
				/>
			) : <img
					src={require(`assets/icons/skill_levels/beginner.png`)}
					alt="user default"
					style={{
						width: imageSize,
						height: imageSize,
						borderRadius: '50%',
						zIndex: 50,
					}}
				/>}
        </div>
    );
};

export default PlayerAvatar;
