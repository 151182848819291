import API from 'API';
import { months } from 'shared/date';

const API_LIST = { GET_GAMES: '/games' };

export function getGameHistory(params) {
    const apiParams = {
        user: params.id,
    };
    // If we call first time we should not send month
    if (params.knowsMonth) {
        apiParams.month = params.year + '-' + ('0' + (months.indexOf(params.month) + 1)).slice(-2) + '-01';
    }
    if (params.mode !== 'all') {
        apiParams.kind = params.mode;
    }
    // return mockData(params.month, params.year);
    return API.get(API_LIST.GET_GAMES, { params: apiParams });
}
