export const SET_TIME_CONTROL = 'GAME:SET_TIME_CONTROL';
export const SET_VARIANT_TYPE = 'GAME:SET_VARIANT_TYPE';
export const SET_RATED_GAME = 'GAME:SET_RATED_GAME';
export const SET_PRIVATE_GAME = 'GAME:SET_PRIVATE_GAME';
export const SET_DIFFICULTY_LEVEL = 'GAME:SET_DIFFICULTY_LEVEL';
export const SET_PIECES_COLOR = 'GAME:SET_PIECES_COLOR';
export const SET_PREDEFINED_FEN = 'GAME:SET_PREDEFINED_FEN';

export const PLAYER_COLOR = {
    WHITE: 'white',
    BLACK: 'black',
};

export const PLAYER_COLOR_SHORT = {
    WHITE: 'w',
    BLACK: 'b',
};

export const PLAYER_COLOR_SHORT_MAPPER = {
    w: 'white',
    b: 'black',
};

export const ENGINE_LEVEL = {
    LEVEL1: 0,
    LEVEL2: 2,
    LEVEL3: 5,
    LEVEL4: 7,
    LEVEL5: 10,
    LEVEL6: 12,
    LEVEL7: 15,
    LEVEL8: 20,
};

export const GAME = {
    STANDARD: 'standard',
    CHESS_960: 'chess960',
    // THREECHECK: 'threeCheck',
    // KINGOFTHEHILL: 'kingOfTheHill',
    // ANTICHESS: 'antichess',
};

export const GAME_TYPE_LIST = {
    BULLET_1_0: 'BULLET_1_0',
    // BULLET_1_1: 'BULLET_1_1',
    BLITZ_3_0: 'BLITZ_3_0',
    BLITZ_3_2: 'BLITZ_3_2',
    BLITZ_5_0: 'BLITZ_5_0',
    // BLITZ_5_3: 'BLITZ_5_3',
    RAPID_10_0: 'RAPID_10_0',
    // RAPID_15_0: 'RAPID_15_0',
    // RAPID_15_5: 'RAPID_15_5',
    // RAPID_30_30: 'RAPID_30_30',
    STANDARD_30_0: 'STANDARD_30_0',
    // STANDARD_90_0: 'STANDARD_90_0',
    CORRESPONDENCE_1_0: 'CORRESPONDENCE_1_0',
    CORRESPONDENCE_3_0: 'CORRESPONDENCE_3_0',
    CORRESPONDENCE_5_0: 'CORRESPONDENCE_5_0',
};

export const GUEST_GAME_TYPE_LIST = {
    BULLET_1_0: 'BULLET_1_0',
    // BULLET_1_1: 'BULLET_1_1',
    BLITZ_3_0: 'BLITZ_3_0',
    BLITZ_3_2: 'BLITZ_3_2',
    BLITZ_5_0: 'BLITZ_5_0',
    // BLITZ_5_3: 'BLITZ_5_3',
    RAPID_10_0: 'RAPID_10_0',
    // RAPID_15_0: 'RAPID_15_0',
    // RAPID_15_5: 'RAPID_15_5',
    // RAPID_30_30: 'RAPID_30_30',
    STANDARD_30_0: 'STANDARD_30_0',
    // STANDARD_90_0: 'STANDARD_90_0',
};

export const GAME_TYPE = {
    [GAME_TYPE_LIST.BULLET_1_0]: {
        name: GAME_TYPE_LIST.BULLET_1_0,
        minutes: 1,
        seconds: 0,
        increment: 0,
    },
    // [GAME_TYPE_LIST.BULLET_1_1]: {
    //     name: GAME_TYPE_LIST.BULLET_1_1,
    //     minutes: 1,
    //     seconds: 0,
    //     increment: 1,
    // },
    [GAME_TYPE_LIST.BLITZ_3_0]: {
        name: GAME_TYPE_LIST.BLITZ_3_0,
        minutes: 3,
        seconds: 0,
        increment: 0,
    },
    [GAME_TYPE_LIST.BLITZ_3_2]: {
        name: GAME_TYPE_LIST.BLITZ_3_2,
        minutes: 3,
        seconds: 0,
        increment: 2,
    },
    [GAME_TYPE_LIST.BLITZ_5_0]: {
        name: GAME_TYPE_LIST.BLITZ_5_0,
        minutes: 5,
        seconds: 0,
        increment: 0,
    },
    // [GAME_TYPE_LIST.BLITZ_5_3]: {
    //     name: GAME_TYPE_LIST.BLITZ_5_3,
    //     minutes: 5,
    //     seconds: 0,
    //     increment: 3,
    // },
    [GAME_TYPE_LIST.RAPID_10_0]: {
        name: GAME_TYPE_LIST.RAPID_10_0,
        minutes: 10,
        seconds: 0,
        increment: 0,
    },
    // [GAME_TYPE_LIST.RAPID_15_0]: {
    //     name: GAME_TYPE_LIST.RAPID_15_0,
    //     minutes: 15,
    //     seconds: 0,
    //     increment: 0,
    // },
    // [GAME_TYPE_LIST.RAPID_15_5]: {
    //     name: GAME_TYPE_LIST.RAPID_15_5,
    //     minutes: 15,
    //     seconds: 0,
    //     increment: 5,
    // },
    // [GAME_TYPE_LIST.RAPID_30_30]: {
    //     name: GAME_TYPE_LIST.RAPID_30_30,
    //     minutes: 30,
    //     seconds: 0,
    //     increment: 30,
    // },
    [GAME_TYPE_LIST.STANDARD_30_0]: {
        name: GAME_TYPE_LIST.STANDARD_30_0,
        minutes: 30,
        seconds: 0,
        increment: 0,
    },
    // [GAME_TYPE_LIST.STANDARD_90_0]: {
    //     name: GAME_TYPE_LIST.STANDARD_90_0,
    //     minutes: 90,
    //     seconds: 0,
    //     increment: 0,
    // },
    [GAME_TYPE_LIST.CORRESPONDENCE_1_0]: {
        name: GAME_TYPE_LIST.CORRESPONDENCE_1_0,
		days: 1,
        minutes: 1440,
        seconds: 0,
        increment: 0,
    },
    [GAME_TYPE_LIST.CORRESPONDENCE_3_0]: {
        name: GAME_TYPE_LIST.CORRESPONDENCE_3_0,
		days: 3,
        minutes: 4320,
        seconds: 0,
        increment: 0,
    },
    [GAME_TYPE_LIST.CORRESPONDENCE_5_0]: {
        name: GAME_TYPE_LIST.CORRESPONDENCE_5_0,
		days: 5,
        minutes: 7200,
        seconds: 0,
        increment: 0,
    },
};
