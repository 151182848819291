import * as CONST from './constants';

export const addToast = toast => {
	return {
		type: CONST.ADD_TOAST,
		toast,
	};
};

export const removeToast = timestamp => {
	return {
		type: CONST.REMOVE_TOAST,
		timestamp,
	};
};
