import {
    GET_CURRENT_USER_REQUEST,
    GET_USER_REQUEST,
    GET_CURRENT_USER_FAILURE,
    GET_USER_FAILURE,
    GET_CURRENT_USER_SUCCESS,
    GET_USER_SUCCESS,
    ADD_A_FRIEND_FAILURE,
    ADD_A_FRIEND_REQUEST,
    ADD_A_FRIEND_SUCCESS,
    SET_USER_COLORS,
    GET_MY_BADGES_FAILURE,
    GET_MY_BADGES_REQUEST,
    GET_MY_BADGES_SUCCESS,
    GET_PROFILE_BADGES_FAILURE,
    GET_PROFILE_BADGES_REQUEST,
    GET_PROFILE_BADGES_SUCCESS,
    REMOVE_A_FRIEND_REQUEST,
    REMOVE_A_FRIEND_SUCCESS,
    REMOVE_A_FRIEND_FAILURE,
} from './constants';
import { USER_ROLES } from 'shared/userRoles';

const initialState = {
    addFriendState: { inProgress: false, success: true, error: null },
    inProgress: false,
    data: {},
    gradientColors: {},
    success: null,
    error: false,
    badgesState: { inProgress: false, success: true, error: null },
    badges: [],
};

const setUserColors = (state, action) => {
    if (state.data && (state.data.level || state.data.skill_level)) {
        switch (action.data) {
            case USER_ROLES.BEGINNER:
                return {
                    ...state,
                    gradientColors: { color1: '#3dbb75', color2: '#bedf79' },
                };
            case USER_ROLES.CASUAL:
                return {
                    ...state,
                    gradientColors: { color1: '#099DD0 ', color2: '#08E5A7' },
                };
            case USER_ROLES.CLUB:
                return {
                    ...state,
                    gradientColors: { color1: '#104f9f', color2: '#0bbebf' },
                };
            case USER_ROLES.LEAGUE:
                return {
                    ...state,
                    gradientColors: { color1: '#614bef', color2: '#113e9a' },
                };
            case USER_ROLES.TOURNAMENT:
                return {
                    ...state,
                    gradientColors: { color1: '#73116f', color2: '#732ede' },
                };
            case USER_ROLES.ADVANCED:
                return {
                    ...state,
                    gradientColors: { color1: '#b64952', color2: '#761164' },
                };
            case USER_ROLES.EXPERT:
                return {
                    ...state,
                    gradientColors: { color1: '#bfb050', color2: '#be4f4f' },
                };
            case USER_ROLES.MASTER:
                return {
                    ...state,
                    gradientColors: { color1: '#151515', color2: '#363636' },
                };
            default:
        }
    } else {
        return {
            ...state,
            gradientColors: { color1: '#3dbb75', color2: '#bedf79' },
        };
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CURRENT_USER_REQUEST:
        case GET_USER_REQUEST:
            return { ...state, inProgress: true, error: null };
        case GET_CURRENT_USER_SUCCESS:
        case GET_USER_SUCCESS:
            return {
                ...state,
                inProgress: false,
                error: false,
                data: action.data,
                success: true,
            };
        case GET_CURRENT_USER_FAILURE:
        case GET_USER_FAILURE:
            return {
                ...state,
                inProgress: false,
                error: action.error,
                success: false,
            };
        case ADD_A_FRIEND_REQUEST:
        case REMOVE_A_FRIEND_REQUEST:
            return {
                ...state,
                addFriendState: {
                    error: null,
                    inProgress: true,
                    success: false,
                },
            };
        case ADD_A_FRIEND_SUCCESS:
            return {
                ...state,
                addFriendState: {
                    error: null,
                    inProgress: false,
                    success: true,
                },
                data: {
                    ...state.data,
                    is_friend: true,
                    can_friend: false,
                },
            };
        case REMOVE_A_FRIEND_SUCCESS:
            return {
                ...state,
                addFriendState: {
                    error: null,
                    inProgress: false,
                    success: true,
                },
                data: {
                    ...state.data,
                    can_friend: true,
                    is_friend: false,
                },
            };
        case ADD_A_FRIEND_FAILURE:
        case REMOVE_A_FRIEND_FAILURE:
            return {
                ...state,
                addFriendState: {
                    error: action.error,
                    inProgress: false,
                    success: false,
                },
            };
        case SET_USER_COLORS:
            return setUserColors(state, action);
        case GET_MY_BADGES_REQUEST:
        case GET_PROFILE_BADGES_REQUEST:
            return {
                ...state,
                badgesState: {
                    inProgress: true,
                },
            };
        case GET_MY_BADGES_SUCCESS:
        case GET_PROFILE_BADGES_SUCCESS:
            return {
                ...state,
                badgesState: {
                    inProgress: false,
                    error: false,
                    success: true,
                },
                badges: action.data,
            };
        case GET_MY_BADGES_FAILURE:
        case GET_PROFILE_BADGES_FAILURE:
            return {
                ...state,
                badgesState: {
                    error: action.error,
                    inProgress: false,
                    success: false,
                },
            };

        default:
            return state;
    }
};

export default reducer;
