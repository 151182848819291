import React, { Component } from 'react';
import { connect } from 'react-redux';

// import * as actions from 'store/user/actions';

import styles from './Splash.module.scss';

// axios.defaults.withCredentials = true;

class Splash extends Component {
    componentDidMount = () => {
        // this.props.checkAuth();
        // TODO Check out in some other way
        window.location = '/home';
    };

    render() {
        return <div className={styles.splash} />;
    }
}

const mapDispatchToProps = {
    // checkAuth: () => actions.checkAuth(),
};

export default connect(null, mapDispatchToProps)(Splash);
