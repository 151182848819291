import React from 'react';
import { connect } from 'react-redux';
import { connectManually } from 'store/gameWs/sagaWs';
import uuidv4 from 'uuid/v4';

import { addToast, removeToast } from 'store/toast/actions';

import {
    sendWsAction,
    receiveWsAction,
    wsClearQueueList,
} from 'store/gameWs/actions';

import { getUserProfileRequest } from 'store/userProfile/actions';

import { DRAW_OFFER_RESPONSE } from 'store/gameWs/wsConstants';
import * as wsGameActions from 'store/gameWs/wsActions';
import { getGameWs } from 'store/gameWs/selector';
import {
    getUserWSToken,
    getUser,
    getUserAccessToken,
} from 'store/user/selector';
import { redirect } from 'store/util/actions';

import { capitalize } from 'shared/string';
import { getWsTokenWrapper } from './TokenWrapper';

import PlayerAvatarAndBadge from 'UI/PlayerAvatarAndBadge/PlayerAvatarAndBadge';
import pga from 'assets/icons/pga_request.png';
import { ReactComponent as Flare } from 'assets/icons/flare_pro.svg';

import { GAME_HISTORY, GAME_URLS, replaceWildcards } from 'shared/urlList';

// const userInfo = JSON.parse(getUserLS());

class WSWrapper extends React.Component {
    state = {
        sender: false,
        rematch: false,
        reaction: '',
    };

    componentDidMount() {
        // TODO Server input whether there is a live game in progress
        // const gameId = getGameIdLS();
        //
        // if (gameId) {
        //     this.reconnect(gameId);
        // }
        // console.log('connect manually 0');
        // connectManually();
        this.challengeNotifications = [];
    }

    componentDidUpdate(prevProps) {
        // If user changed and socket is not open get new WS
        if (
            this.props.user &&
            (!prevProps.user || prevProps.user.id !== this.props.user.id)
        ) {
            if (
                !this.props.gameWs ||
                !this.props.gameWs.socket ||
                this.props.gameWs.socket.readyState > 1
            ) {
                getWsTokenWrapper(this.props.dispatch, this.props.aToken);
            }
        }
        // WebSocket token changed and user is logged in or guest user
        if (prevProps.wsToken !== this.props.wsToken) {
            if (
                this.props.gameWs &&
                this.props.gameWs.socket &&
                (this.props.gameWs.socket.readyState === 1 ||
                    this.props.gameWs.socket.readyState === 0)
            ) {
                this.props.gameWs.socket.close();
                // this.props.dispatch(wsDisconnect());
            }
            // eslint-disable-next-line
            console.log('connect manually 1');
            connectManually();
        }

        if (prevProps.gameWs !== this.props.gameWs) {
            // If socket disconnected try to connect again
            if (
                prevProps.gameWs.disconnected !==
                    this.props.gameWs.disconnected &&
                this.props.gameWs.disconnected
            ) {
                // eslint-disable-next-line
                console.log('connect manually 2', this.props.gameWs);
                // Get new WS
                // connectManually();
                getWsTokenWrapper(this.props.dispatch, this.props.aToken);
            }
            if (
                this.props.gameWs.disconnected &&
                this.props.gameWs.connectionAttempts !==
                    prevProps.gameWs.connectionAttempts
            ) {
                const weight =
                    this.props.gameWs.connectionAttempts > 2
                        ? this.props.gameWs.connectionAttempts * 10
                        : this.props.gameWs.connectionAttempts;
                const timeout = 1000 * weight;
                setTimeout(() => {
                    // eslint-disable-next-line
                    console.log('connect manually 3 timeout');
                    if (this.props.gameWs.disconnected) {
                        // eslint-disable-next-line
                        console.log('connect manually 3 call');
                        // Get new WS
                        // connectManually();
                        getWsTokenWrapper(
                            this.props.dispatch,
                            this.props.aToken
                        );
                    }
                }, timeout);
            }

            // If socket connected
            if (
                prevProps.gameWs.connected !== this.props.gameWs.connected &&
                this.props.gameWs.connected
            ) {
                if (
                    this.props.gameWs.socket &&
                    this.props.gameWs.sendListQueue
                ) {
                    this.props.gameWs.sendListQueue.forEach((action, index) => {
                        // // TODO This should be done in a better way on reload page
                        // // How it works: 1. It tries to reconnect to the game from GameVsFriend & GameVsOpponent
                        // // It is still not connected to WS at that point so it goest ot sendListQueue
                        // // It connects to guest WS first and on connect sends this
                        // // And after it gets correct WS token and connects to normal WS it was already fired
                        // // This fix will not even work on slower internet connections
                        // if (action.wsAction === 'connect_game') {
                        //     setTimeout(() => {
                        //         this.props.dispatch(action);
                        //         this.props.dispatchClearQueue(index);
                        //     }, 1000);
                        // } else {
                        //     this.props.dispatch(action);
                        //     this.props.dispatchClearQueue(index);
                        // }
                        this.props.dispatch(action);
                        this.props.dispatchClearQueue(index);
                    });
                }
            }
            // If user matched and reconnected
            if (prevProps.gameWs.matched !== this.props.gameWs.matched) {
                // Remove challenge notifications from screen
                if (this.challengeNotifications.length > 0) {
                    this.challengeNotifications.forEach((notification) => {
                        this.props.dispatch(removeToast(notification));
                    });
                    this.challengeNotifications = [];
                }

                let isDaily = false;
                if (
                    this.props.gameWs.matched &&
                    this.props.gameWs.matched.data &&
                    this.props.gameWs.matched.data.time
                ) {
                    const time = this.props.gameWs.matched.data.time;
                    const hours = time / 60 / 60;
                    isDaily = hours && hours > 2;
                }

                // If first time match
                if (this.invited) {
                    this.invited = false;
                    if (isDaily) {
                        const id = this.props.gameWs.matched.data.id;
                        this.props.dispatchRedirect(
                            replaceWildcards(GAME_URLS.PLAY_DAILY, [id])
                        );
                    } else {
                        const id = this.props.gameWs.matched.data.id;
                        this.props.dispatchRedirect(
                            replaceWildcards(GAME_URLS.PLAY_FRIEND, [id])
                        );
                    }
                }

                if (isDaily) {
                    const id = this.props.gameWs.matched.data.id;
                    this.props.dispatchRedirect(
                        replaceWildcards(GAME_URLS.PLAY_DAILY, [id])
                    );
                }
            }

            // If user has been challenged
            if (
                prevProps.gameWs.challengeList !==
                    this.props.gameWs.challengeList &&
                this.props.gameWs.challengeList.length > 0 &&
                !this.props.gameWs.rematch
            ) {
                this.onNewGameInvite(this.props.gameWs.challengeList);
            }

            // when user don't respond in 15 seconds
            if (
                prevProps.gameWs.waitingFriend.timeout !==
                    this.props.gameWs.waitingFriend.timeout &&
                this.state.sender
            ) {
                // Show timeout only if timeout is true
                if (this.props.gameWs.waitingFriend.timeout) {
                    this.onTimeout();
                }
            }

            // show message when user cancelled invitation
            if (this.props.gameWs.waitingFriend.denyReason === 'cancelled') {
                this.userCancelled();
            }

            // if user gets rematch
            if (
                prevProps.gameWs.rematch !== this.props.gameWs.rematch &&
                this.props.gameWs.challengeList.length > 0
            ) {
                this.onRematch(this.props.gameWs.challengeList);
            }

            // if user gets rematch timeout
            if (
                prevProps.gameWs.rematchTimeout !==
                    this.props.gameWs.rematchTimeout &&
                this.props.gameWs.rematchTimeout
            ) {
                this.onTimeout();
            }

            // if user gets reaction
            if (prevProps.gameWs.reaction !== this.props.gameWs.reaction) {
                this.showReaction(this.props.gameWs.reaction);
            }

            // if user get notification
            if (
                prevProps.gameWs.notificationList !==
                    this.props.gameWs.notificationList &&
                this.props.gameWs.notificationList.length > 0
            ) {
                this.showNotificationToast(this.props.gameWs.notificationList);
            }
            // if received latency ping
            if (
                prevProps.gameWs.latency_ping !==
                    this.props.gameWs.latency_ping &&
                this.props.gameWs.latency_ping
            ) {
                this.sendPong(this.props.gameWs.latency_ping);
            }
        }
    }

    showNotificationToast = (data) => {
        const notificationInfo = data[data.length - 1];
        // eslint-disable-next-line
        console.log('notificationInfo', notificationInfo);

        const opponentInfo = {
            username: notificationInfo.from.username,
            avatar: notificationInfo.from.avatar,
            level: notificationInfo.from.level,
        };

        let isPageValid;

        switch (true) {
            case this.props.gameWs.result &&
                window.location.pathname.includes('game/friend/'):
            case this.props.gameWs.result &&
                window.location.pathname.includes('game/opponent/'):
                isPageValid = true;
                break;

            case window.location.pathname.includes('puzzle/'):
            case window.location.pathname.includes('videos/'):
            case window.location.pathname.includes('game/friend/'):
            case window.location.pathname.includes('game/opponent/'):
                isPageValid = false;
                break;

            default:
                isPageValid = true;
                break;
        }

        switch (notificationInfo.event) {
            case 'correspondence_move':
                return this.props.dispatch(
                    addToast({
                        type: 'play',
                        durationInSeconds: 7,
                        text: {
                            title: `${notificationInfo.message}`,
                        },
                        cta: {
                            accept: () =>
                                this.props.dispatchRedirect(
                                    replaceWildcards(GAME_URLS.PLAY_DAILY, [
                                        notificationInfo.data.game_id,
                                    ])
                                ),
                            // (window.location.href = `/correspondence/${notificationInfo.data.game_id}`),
                        },
                        content: (
                            <PlayerAvatarAndBadge
                                player={opponentInfo}
                                imageSize={40}
                                snackbar
                            />
                        ),
                    })
                );
            case 'correspondence_match':
                return this.props.dispatch(
                    addToast({
                        type: 'play',
                        durationInSeconds: 7,
                        text: {
                            title: `${notificationInfo.message}`,
                        },
                        cta: {
                            accept: () =>
                                this.props.dispatchRedirect(
                                    replaceWildcards(GAME_URLS.PLAY_DAILY, [
                                        notificationInfo.data.game_id,
                                    ])
                                ),
                        },
                        content: (
                            <PlayerAvatarAndBadge
                                player={opponentInfo}
                                imageSize={40}
                                snackbar
                            />
                        ),
                    })
                );
            case 'correspondence_game_over':
                return this.props.dispatch(
                    addToast({
                        type: 'info',
                        durationInSeconds: 7,
                        text: {
                            title: `${notificationInfo.message}`,
                        },
                        content: (
                            <PlayerAvatarAndBadge
                                player={opponentInfo}
                                imageSize={40}
                                snackbar
                            />
                        ),
                    })
                );
            case 'correspondence_match_failed':
                return this.props.dispatch(
                    addToast({
                        type: 'error',
                        durationInSeconds: 7,
                        text: {
                            title: `${notificationInfo.message}`,
                        },
                    })
                );
            case 'correspondence_time_running_out':
                return this.props.dispatch(
                    addToast({
                        type: 'play',
                        durationInSeconds: 7,
                        text: {
                            title: `${notificationInfo.message}`,
                        },
                        cta: {
                            accept: () =>
                                this.props.dispatchRedirect(
                                    replaceWildcards(GAME_URLS.PLAY_DAILY, [
                                        notificationInfo.data.game_id,
                                    ])
                                ),
                        },
                        content: (
                            <PlayerAvatarAndBadge
                                player={opponentInfo}
                                imageSize={40}
                                snackbar
                            />
                        ),
                    })
                );
            case 'correspondence_draw_offer':
                return this.props.dispatch(
                    addToast({
                        type: 'play',
                        durationInSeconds: 7,
                        text: {
                            title: `${notificationInfo.message}`,
                        },
                        cta: {
                            accept: () =>
                                this.props.dispatchRedirect(
                                    replaceWildcards(GAME_URLS.PLAY_DAILY, [
                                        notificationInfo.data.game_id,
                                    ])
                                ),
                        },
                        content: (
                            <PlayerAvatarAndBadge
                                player={opponentInfo}
                                imageSize={40}
                                snackbar
                            />
                        ),
                    })
                );
            case 'new_badge':
                return this.props.dispatch(
                    addToast({
                        type: 'success',
                        durationInSeconds: 7,
                        text: {
                            title: `${notificationInfo.data.badge.description}`,
                            subtitle: `${notificationInfo.message}`,
                        },
                        content: (
                            <img
                                src={notificationInfo.data.badge.image}
                                alt="badge"
                                style={{ width: '75px', height: '75px' }}
                            />
                        ),
                    })
                );

            case 'game_analysis_done':
                if (isPageValid) {
                    return this.props.dispatch(
                        addToast({
                            type: 'open',
                            durationInSeconds: 7,
                            text: {
                                title: `${notificationInfo.message}`,
                            },
                            cta: {
                                open: () =>
                                    this.props.dispatchRedirect(
                                        replaceWildcards(
                                            GAME_HISTORY.POST_GAME_ANALYSIS,
                                            [notificationInfo.data.game_id]
                                        )
                                    ),
                            },
                            content: (
                                <img
                                    src={pga}
                                    alt="badge"
                                    style={{ width: '75px', height: '75px' }}
                                />
                            ),
                        })
                    );
                } else {
                    return;
                }

            default:
                break;
        }
    };

    friendChallengeAccepted = (id) => {
        this.sendWs(
            wsGameActions.friendChallengeResponse(
                id,
                DRAW_OFFER_RESPONSE.ACCEPT
            )
        );
    };

    friendChallengeRefused = (id) => {
        const rematchFlag = this.props.gameWs.rematch ? true : false;
        this.sendWs(
            wsGameActions.friendChallengeResponse(
                id,
                DRAW_OFFER_RESPONSE.DENY,
                rematchFlag
            )
        );

        if (this.props.gameWs.rematch) {
            this.props.dispatch(
                addToast({
                    type: 'error',
                    durationInSeconds: 7,
                    text: {
                        title: 'Rematch declined.',
                    },
                })
            );
        } else {
            this.props.dispatch(
                addToast({
                    type: 'error',
                    durationInSeconds: 7,
                    text: {
                        title: 'Challenge declined.',
                    },
                })
            );
        }
    };

    reconnect = (gameId) => {
        this.sendWs(wsGameActions.reconnect(gameId));
    };

    sendPong = (ping) => {
        this.sendWs(wsGameActions.sendLatencyPong(ping));
    };

    sendWs = ({ type, data }) => {
        this.props.dispatchSendWsAction(type, data);
    };

    onTimeout = () => {
        if (this.props.gameWs.rematchTimeout) {
            this.props.dispatch(
                addToast({
                    type: 'error',
                    durationInSeconds: 7,
                    text: {
                        title: 'Rematch timed out.',
                    },
                })
            );
        } else {
            this.props.dispatch(
                addToast({
                    type: 'error',
                    durationInSeconds: 7,
                    text: {
                        title: 'Challenge timed out.',
                    },
                })
            );
        }

        this.setState({ sender: false });
    };

    userCancelled = () => {
        this.props.dispatch(removeToast());
        if (this.props.gameWs.rematch) {
            this.props.dispatch(
                addToast({
                    text: {
                        title: 'Rematch withdrawn by user.',
                    },
                    durationInSeconds: 7,
                    type: 'error',
                })
            );
        } else {
            this.props.dispatch(
                addToast({
                    text: {
                        title: 'Challenge withdrawn by user.',
                    },
                    durationInSeconds: 7,
                    type: 'error',
                })
            );
        }
        this.sendWs(wsGameActions.cancelChallengeFriend());
        this.setState({ sender: false });
    };

    onNewGameInvite = (data) => {
        let isDaily = false;
        const challenge = data[data.length - 1];
        const hours = challenge.hours > 0;
        if (hours && challenge.hours > 2) {
            isDaily = true;
        }
        // const minutes = challenge.minutes > 0;
        // const seconds = challenge.seconds > 0;

        const opponentInfo = {
            username: challenge.user.username,
            avatar: challenge.user.avatar,
            level: challenge.user.level,
        };

        let subtitle;

        if (isDaily) {
            const days = challenge.hours / 24;
            subtitle = `${capitalize(challenge.variant)}
				${days}-day`;
        } else {
            subtitle = `${capitalize(challenge.variant)}
				${hours ? `${challenge.hours} h` : ''}
				${
                    challenge.increment
                        ? `${challenge.minutes} + ${challenge.increment}`
                        : `${challenge.minutes} min`
                }`;
        }

        const timestamp = uuidv4();
        this.challengeNotifications.push(timestamp);

        this.props.dispatch(
            addToast({
                timestamp: timestamp,
                text: {
                    title: (
                        <div>
                            {challenge.user.username} {challenge.user.user_tier === 1 && <Flare style={{width: '20px', height: '15px', paddingRight: '5px'}} />}
                            wants to play. Are you up for it?`
                        </div>
                    ),
                    subtitle: subtitle,
                },
                durationInSeconds: 15,
                type: 'cta',
                cta: {
                    accept: () => {
                        this.invited = true;
                        this.friendChallengeAccepted(challenge.user.id);
                    },
                    decline: () =>
                        this.friendChallengeRefused(challenge.user.id),
                },
                content: (
                    <PlayerAvatarAndBadge
                        player={opponentInfo}
                        imageSize={40}
                        snackbar
                    />
                ),
            })
        );

        this.setState({ sender: true });
    };

    onRematch = (data) => {
        let isDaily = false;
        const challenge = data[data.length - 1];
        const hours = challenge.hours > 0;
        // const minutes = challenge.minutes > 0;
        // const seconds = challenge.seconds > 0;
        if (hours && challenge.hours > 2) {
            isDaily = true;
        }

        const opponentInfo = {
            username: challenge.user.username,
            avatar: challenge.user.avatar,
            level: challenge.user.level,
        };

        let subtitle;

        if (isDaily) {
            const days = challenge.hours / 24;
            subtitle = `${capitalize(challenge.variant)}
				${days}-day`;
        } else {
            subtitle = `${capitalize(challenge.variant)}
				${hours ? `${challenge.hours} h` : ''}
				${
                    challenge.increment
                        ? `${challenge.minutes} + ${challenge.increment}`
                        : `${challenge.minutes} min`
                }`;
        }

        const timestamp = Date.now();
        this.challengeNotifications.push(timestamp);

        this.props.dispatch(
            addToast({
                timestamp: timestamp,
                text: {
                    title: `${challenge.user.username} wants a rematch!`,
                    subtitle: subtitle,
                },
                durationInSeconds: 15,
                type: 'cta',
                cta: {
                    accept: () => {
                        this.invited = true;
                        this.friendChallengeAccepted(challenge.user.id);
                    },
                    decline: () =>
                        this.friendChallengeRefused(challenge.user.id),
                },
                content: (
                    <PlayerAvatarAndBadge
                        player={opponentInfo}
                        imageSize={40}
                        snackbar
                    />
                ),
            })
        );

        this.setState({ rematch: true, sender: false });
    };

    showReaction = (reaction) => {
        this.setState({ reaction });
    };

    render() {
        return this.props.children;
    }
}

const mapStateToProps = (state) => {
    return {
        gameWs: getGameWs(state),
        wsToken: getUserWSToken(state),
        user: getUser(state),
        aToken: getUserAccessToken(state),
        // notifications: getNotificationList(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        dispatchSendWsAction: (type, data) =>
            dispatch(sendWsAction(type, data)),
        dispatchReceiveWs: (data) => dispatch(receiveWsAction(data)),
        dispatchRedirect: (url) => dispatch(redirect(url)),
        dispatchClearQueue: () => dispatch(wsClearQueueList()),
        getUserProfileRequest: (profileId) =>
            dispatch(getUserProfileRequest(profileId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WSWrapper);
