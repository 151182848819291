import * as CONST from './constants';

import uuidv4 from 'uuid/v4';

const initialState = {
    toasts: [],
};

const addToast = (state, { toast }) => {
    if (!toast.timestamp) {
        toast.timestamp = uuidv4();
    }

    // Check if there are toasts with same id (timestamp)
    if (state.toasts && state.toasts.length > 0) {
        if (state.toasts.some((el) => toast.timestamp === el.timestamp)) {
            console.log('same', toast.timestamp);
            toast.timestamp = uuidv4();
        }
    }

    return {
        ...state,
        toasts: [...state.toasts, toast],
    };
};

const removeToast = (state, { timestamp }) => {
    const index = state.toasts.map((e) => e.timestamp).indexOf(timestamp);

    if (typeof index === 'number' && index !== -1) {
        state.toasts.splice(index, 1);

        return {
            ...state,
            toasts: [...state.toasts],
        };
    } else {
        return state;
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CONST.ADD_TOAST:
            return addToast(state, action);
        case CONST.REMOVE_TOAST:
            return removeToast(state, action);
        default:
            return state;
    }
};

export default reducer;
