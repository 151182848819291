import React from 'react';
import { connect, useDispatch } from 'react-redux';

import { getToasts } from 'store/toast/selector';
import * as actions from 'store/toast/actions';

import Toast from 'components/Wrappers/ToastWrapper/Toast/Toast';
// import PlayerAvatarAndBadge from 'UI/PlayerAvatarAndBadge/PlayerAvatarAndBadge';

// import { getUserLS } from 'shared/localStorage';

import styles from './ToastWrapper.module.scss';

const ToastWrapper = ({ children, toasts }) => {
	const dispatch = useDispatch();

	const removeToast = (timestamp) => {
		dispatch(actions.removeToast(timestamp));
	};

	// const userInfo = JSON.parse(getUserLS());

	return (
		<div className={styles.toast_wrapper}>
			{children}
			<div className={styles.toasts_container}>
				{/* <button name="TEST BUTTON" onClick={() => dispatch(actions.addToast({
					text: {
						title: 'lamadrugada1990ue wants to play. Are you up for it?',
						subtitle: 'Quick play 3 min'
					},
					durationInSeconds: 7,
					type: 'cta',
					cta: {
						accept: () => alert('ACCEPT'),
						decline: () => alert('DECLINE'),
					},
					content: <PlayerAvatarAndBadge player={userInfo} imageSize={40}/>
				}))} style={{ width: '100px', height: '40px', marginBottom: '16px' }}>ADD</button> */}
				{toasts && toasts.map(toast => {
					return <Toast data={toast} key={toast.timestamp} removeToast={removeToast}/>;
				})}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
    return {
        toasts: getToasts(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ToastWrapper);
