import * as constants from './constants';

export const getGameHistoryRequest = (month, year, mode, id, knowsMonth) => {
    return {
        type: constants.GET_GAME_HISTORY_REQUEST,
        month,
        year,
        mode,
        id,
        knowsMonth,
    };
};

export const getGameHistorySuccess = (data) => {
    return {
        type: constants.GET_GAME_HISTORY_SUCCESS,
        data,
    };
};

export const getGameHistoryFailiure = (error) => {
    return {
        type: constants.GET_GAME_HISTORY_FAILURE,
        error,
    };
};
