import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setTableHeight } from 'store/util/actions';

import Fallback from '../Fallback/Fallback';

const ScreenSizeWrapper = (props) => {
    const dispatch = useDispatch();

    const calculateTableHeight = () => {
        if (localStorage.getItem('resize')) {
            return parseInt(localStorage.getItem('resize'));
        }

        if (window.innerHeight > 768) {
            switch (true) {
                case window.innerWidth > 1660:
                    return window.innerHeight - 200;
                case window.innerWidth <= 1660 && window.innerWidth > 1440:
                    return window.innerWidth - 792;
                case window.innerWidth <= 1440:
                    return window.innerWidth - 628;

                default:
                    return null;
            }
        } else {
            // return 530;
            return window.innerHeight - 150;
        }
    };

    let smallScreen = false;
    let mobile = false;

    if (window.innerWidth < 415) {
        smallScreen = false;
        mobile = true;
    } else if (window.innerWidth < 750 || window.outerWidth < 750) {
        smallScreen = true;
        mobile = false;
    }

    if (mobile || smallScreen) {
        const mobileHostname = process.env.REACT_APP_MOBILE_HOSTNAME.replace(/\/$/, '');
        const mobilePathname = window.location.pathname;
        window.location.href = mobileHostname + mobilePathname;
    }

    useEffect(() => {
        dispatch(setTableHeight(calculateTableHeight()));
    }, []);
    return (
        <>
            {smallScreen || mobile ? (
                <Fallback text="resolution" mobile={mobile}>{props.children}</Fallback>
            ) : (
                props.children
            )}
        </>
    );
};

export default ScreenSizeWrapper;
