import HOME from './sections/home';
import ERROR from './sections/error';
import UTIL from './sections/util';

export default {
    en: {
        ...ERROR.en,
        ...UTIL.en,

        // Input -------------------------------------------
        'input.enter_email': 'Enter your email',
        'input.enter_pass': 'Enter your password',
        'input.enter_new_pass': 'Enter new password',
        'input.repeat_new_pass': 'Re-enter new password',
        'input.firstname': 'First name',
        'input.lastname': 'Last name',
        // ------------------------------------------------

        // Header ----------------------------
        'header.sign_in': 'Log in',
        'header.sign_out': 'Sign out',
        'header.close': 'Close',
        // ---------------------------------

        // Onboarding ----------------------------
        'onboarding.title': 'Welcome!',
        'onboarding.subtitle': 'Please select an option.',
        'onboarding.continue_facebook': 'Continue with Facebook',
        'onboarding.continue_google': 'Continue with Google',
        'onboarding.continue_apple': 'Continue with Apple',
        'onboarding.continue_email': 'Continue with email',
        'onboarding.terms_and_conditions':
            'By signing up, I agree to Keysquare’s <link_1>terms and conditions</link_1>, and <link_2>privacy policy</link_2>.',
        // --------------------------------------

        // Login -----------------------------------------
        'login.title': 'Log in',
        'login.subtitle': 'Enter your details',
        'login.sign_in_button': 'Log in',
        'login.forgot_password': 'Forgot your password ?',
        'login.no_account': "Don't have an account yet ?",
        'login.sign_up': 'Sign up',
        'login.continue_facebook': 'Facebook',
        'login.continue_google': 'Google',
        'login.continue_apple': 'Apple',
        // ------------------------------------------------

        // EmailRegistartion -----------------------------------------------------------------------------------------------------------
        'email_registartion.sign_up': 'Create your account',
        'email_registartion.next_button': 'Next',
        'email_registartion.check_email': 'Check your inbox',
        'email_registartion.check_email_text': `
        We've sent you an email to verify your details. We verify your email to make sure you can recover your password if you lose it. Having only verified accounts also encourages fairplay within the K-chess community. \n Click on the link in the email to complete sign-up.`,
        // ----------------------------------------------------------------------------------------------------------------------------

        // EmailRegistartion2 -----------------------------------------------------------------------------------------------------------
        'email_registartion_2.welcome': 'Choose your username',
        'email_registartion_2.enter_name': 'Please enter your name.',
        'email_registartion_2.next_button': 'Next',
        'email_registartion_2.go_button': 'Start playing !',
        'email_registartion_2.username_title': 'Choose your username',
        'email_registartion_2.birthdate_title': 'Enter your birthday',
        'email_registartion_2.birthdate_subtitle':
            'You must be at least 12 years old to use KChess.',
        'email_registartion_2.skill_level_title': 'Your level',
        'email_registartion_2.skill_level_subtitle':
            'Choose a level based on your current skill and abilities. This determines the players you’re matched with for your first games, as well as the content we will recommend for you.',
        // ----------------------------------------------------------------------------------------------------------------------------

        // SocialRegistration -----------------------------------------------------------------------------------------------------------
        'social_registration.next_button': 'Next',
        'social_registration.go_button': 'Let’s go!',
        'social_registration.birthadate_title': 'Welcome, { firstname }!',
        'social_registration.birthadate_subtitle': 'When is your birthday?',
        'social_registration.birthadate_paragraph':
            'You must be at least 12 years old to use Keysquare Chess. Don’t worry — we won’t share your age with anyone.',
        'social_registration.username_title': 'And, your username?',
        'social_registration.username_subtitle':
            'This is what’s visible to other Keysquare users.',
        'social_registration.skill_level_title': 'Just one more thing.',
        'social_registration.skill_level_subtitle':
            'How would you describe your skill level in chess?',
        // ----------------------------------------------------------------------------------------------------------------------------

        // SignUp -----------------------------------------
        'sign_up.sign_up': 'Sign up',
        'sign_up.sign_up_button': 'Sign up',
        'sign_up.forgot_password': 'Forgot password?',
        // ------------------------------------------------

        // PasswordRecovery --------------------------------------------------------------------------------------------
        'password_recovery.step_1_title': 'Forgot password?',
        'password_recovery.step_1_subtitle':
            'Enter the email address you used to sign up and we’ll send you a verification link to reset your password.',
        'password_recovery.email_address': 'Email adress',
        'password_recovery.step_1_button': 'Reset password',
        'password_recovery.step_2_title': 'Email validation',
        'password_recovery.step_2_subtitle':
            'Proceed to the next step by clicking on the link sent to { userMail }.',
        'password_recovery.step_2_link': 'Go back',
        'password_recovery.step_3_title': 'Forgot passwords?',
        'password_recovery.step_3_subtitle':
            'Your email has been verified. Please enter a new password.',
        'password_recovery.new_password': 'New password',
        'password_recovery.repeat_new_password': 'Re-enter new password',
        'password_recovery.step_3_button': 'Update password',
        'password_recovery.step_4_title': 'Password changed',
        'password_recovery.step_4_subtitle':
            'You successfully changed your password!',
        'password_recovery.step_4_button': 'Go to sign in',
        // ------------------------------------------------------------------------------------------------------------

        ...HOME.en,
    },
};
