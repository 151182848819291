export default {
    en: {
        'util.required_data': 'Required',
        'util.valid_email': 'Invalid email address',
        'util.valid_name': 'Please enter a valid name',
        'util.valid_password':
            'Password must contain between { minLength } and { maxLength } characters, and at least 1 digit or special character (~!@3$%^&*)',
        'util.min_length': 'Username must be between { minLength } and { maxLength } characters',
        'util.max_length': 'Username must be between { minLength } and { maxLength } characters',
        'util.min_value': 'Min value is { minValue }',
        'util.max_value': 'Max value is { maxValue }',
        'util.mp3_format_only': '.mp3 format only',
        'util.data_size_limit': 'Data size limit is 10MB',
        'util.user_username_contains_spaces': "Username can't contain blank spaces",
        'util.user_username_contains_special_characters': "Username can't contain special characters",
    },
};
