import * as constants from './constants';

export const getPostGameAnalysisRequest = (id, request) => {
    return {
        type: constants.GET_POST_GAME_ANALYSIS_REQUEST,
        id,
        request,
    };
};

export const getPostGameAnalysisSuccess = (data) => {
    return {
        type: constants.GET_POST_GAME_ANALYSIS_SUCCESS,
        data,
    };
};

export const getPostGameAnalysisFailure = (data) => {
    return {
        type: constants.GET_POST_GAME_ANALYSIS_FAILURE,
        data,
    };
};

export const getPostGameAnalysisNotificationRequest = (id, completed) => {
    return {
        type: constants.GET_POST_GAME_ANALYSIS_NOTIFICATION_REQUEST,
        id,
        completed,
    };
};

export const getPostGameAnalysisNotificationSuccess = (data) => {
    return {
        type: constants.GET_POST_GAME_ANALYSIS_NOTIFICATION_SUCCESS,
        data,
    };
};

export const getPostGameAnalysisNotificationFailure = (data) => {
    return {
        type: constants.GET_POST_GAME_ANALYSIS_NOTIFICATION_FAILURE,
        data,
    };
};
