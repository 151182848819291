import STRAPI from 'API_STRAPI';
import API from 'API';

// import { mock } from './mock';

const PREFIX = '/cms';

const API_LIST = {
    GET_PGN_READER: '/pgn',
    GET_LIVE_GAMES: '/tournaments/game',
};

export function getPGNReader(params) {
    const type = params.pgnType.replace('-', '_');

    if (type === 'gct') {
        return API.get(PREFIX + API_LIST.GET_LIVE_GAMES + '/' + type + '/' + params.id);
    }
    return API.get(PREFIX + API_LIST.GET_PGN_READER, {
        params: { type, id: params.id },
    });
}
