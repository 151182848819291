import axios from 'axios';
import { removePuzzleFiltersSS } from 'shared/sessionStorage';

import {
    clearUser,
    removeAccessToken,
    setAccessToken,
    setGuestUser,
} from 'store/user/actions';
import store from 'store/root/store';
import {
    getIsGuestLS,
    removeIsGuestLS,
    setIsGuestLS,
} from './shared/localStorage';
import { FALLBACK, MAIN_URLS, ONBOARDING_URLS } from './shared/urlList';
import { redirect } from './store/util/actions';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    timeout: 5000,
    // headers: {
    //     'Content-Type': 'application/json; charset=utf-8',
    //     'Access-Control-Allow-Methods':'PUT',
    // },
});

// export function setAccessToken(at) {
//     instance.defaults.headers.common = { ...instance.defaults.headers.common, Authorization: `bearer ${at}` };
// }

instance.interceptors.request.use(
    (config) => {
        // console.log('config.headers', config.headers);
        if (store.getState().user.accessToken) {
            config.headers = {
                ...config.headers,
                Authorization: `bearer ${store.getState().user.accessToken}`,
            };
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// If response status 401 (forbiden) remove item from localStorage (logout user)
instance.interceptors.response.use(
    (response) => {
        if (response.data.error) {
            return Promise.reject(response);
        }
        if (response.status === 503) {
            // store.dispatch(redirect(FALLBACK.SCHEDULE_MAINTENANCE));
        }
        return response;
    },
    (error) => {
        if (error.response) {
            if (error.response.status === 401) {
                // There is no need to be in all cases, but just on getAccesstoken fail
                // localStorage.removeItem('_k_sqr_at');
                // store.dispatch(removeAccessToken());
                return getAccesstoken(error);
            }
            if (error.response.status === 503) {
                // store.dispatch(redirect(FALLBACK.SCHEDULE_MAINTENANCE));
            }
        }

        if (error.toString().indexOf('Error: Network Error') !== -1) {
            if (window.location.pathname !== FALLBACK.SCHEDULE_MAINTENANCE) {
                // store.dispatch(redirect(FALLBACK.SCHEDULE_MAINTENANCE));
            }
        }
        return Promise.reject(error);
    }
);

export function getAccesstoken(error) {
    return instance
        .get('/users/token_refresh', {
            withCredentials: true,
        })
        .then((response) => {
            if (response.data.data.access) {
                // TODO Set AT in redux
                // localStorage.setItem('_k_sqr_at', response.data.data.access);
                store.dispatch(setAccessToken(response.data.data.access));
                if (error) {
                    // Request failed sevice again
                    return instance.request(error.config);
                }
            }
            // Remove is guest flag if AT is successfully aquired
            removeIsGuestLS();

            return response;
        })
        .catch((e) => {
            const isGuest = getIsGuestLS();
            // if it is not guest already
            if (!isGuest) {
                // Set is guest in LS
                setIsGuestLS(true);
                // Set is guest in redux
                store.dispatch(setGuestUser());
                // Set guest token (there might be no need since it will be loaded after redirect)
                // getGuestWSToken(store.dispatch);
                // Delete tokens
                localStorage.removeItem('_k_sqr_at');
                store.dispatch(removeAccessToken());
                // Remove user
                store.dispatch(clearUser());
                // Remove puzzle hub filters
                removePuzzleFiltersSS();

                // Redirect to home if not in onboarding proccess
                // if (
                //     !window.location.pathname.includes(ONBOARDING_URLS.LOGIN) &&
                //     !window.location.pathname.includes(
                //         ONBOARDING_URLS.ONBOARDING
                //     ) &&
                //     !window.location.pathname.includes(
                //         ONBOARDING_URLS.RESET_PASSWORD
                //     ) &&
                //     !window.location.pathname.includes(
                //         ONBOARDING_URLS.REGISTER_SOCIAL
                //     ) &&
                //     !window.location.pathname.includes(
                //         ONBOARDING_URLS.VERIFY_EMAIL
                //     ) &&
                //     !window.location.pathname.includes(
                //         ONBOARDING_URLS.REGIESTER
                //     )
                // ) {
                //     setTimeout(() => {
                //         store.dispatch(redirect(MAIN_URLS.HOME));
                //     }, 20);
                // }
            }
            return Promise.reject(e);
        });
}

export default instance;
