import axios from 'axios';

import store from 'store/root/store';

const instance = axios.create({
    baseURL: process.env.REACT_APP_STRAPI_API_ENDPOINT,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
    },
});

instance.interceptors.request.use(
    (config) => {
        if (store.getState().user.accessToken) {
            config.headers = {
                ...config.headers,
                Authorization: `bearer ${store.getState().user.accessToken}`,
            };
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default instance;
