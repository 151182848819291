import * as constants from './constants';

export const getPGNReaderRequest = (id, pgnType) => {
    return {
        type: constants.GET_PGN_READER_REQUEST,
        id,
        pgnType,
    };
};

export const getPGNReaderSuccess = (data) => {
    return {
        type: constants.GET_PGN_READER_SUCCESS,
        data,
    };
};

export const getPGNReaderFailure = (data) => {
    return {
        type: constants.GET_PGN_READER_FAILURE,
        data,
    };
};

