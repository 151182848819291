import * as constants from './constants';

const initialState = {
    gameSettings: {
        inProgress: false,
        success: null,
        lastGet: null,
        data: {
            highlightMoves: true,
            whiteOnBottom: false,
            moveDrag: true,
            moveClick: true,
            enablePremoves: true,
            alwaysPromoteToQueen: false,
            drawArrows: true,
            pieceSet: 0,
            boardSet: 0,
        },
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_GAME_SETTINGS_REQUEST:
            return {
                ...state,
                gameSettings: { data: state.gameSettings.data, inProgress: true, success: null },
            };
        case constants.GET_GAME_SETTINGS_FAILURE:
            return {
                ...state,
                gameSettings: { data: state.gameSettings.data, inProgress: false, success: true },
            };
        case constants.GET_GAME_SETTINGS_SUCCESS:
            return {
                ...state,
                gameSettings: {
                    inProgress: false,
                    success: false,
                    lastGet: new Date(),
                    data: {
                        highlightMoves: action.data.highlight_moves,
                        whiteOnBottom: action.data.white_bottom,
                        moveDrag:
                            action.data.move_method === 'drag' ||
                            action.data.move_method === 'both',
                        moveClick:
                            action.data.move_method === 'click' ||
                            action.data.move_method === 'both',
                        enablePremoves: action.data.premoves,
                        alwaysPromoteToQueen: action.data.always_promote_queen,
                        drawArrows: action.data.right_click_draws,
                        pieceSet: action.data.pieces,
                        boardSet: action.data.board,
                    },
                },
            };

        default:
            return state;
    }
};

export default reducer;
