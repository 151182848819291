export function modalsReducer(state, action) {
    switch (action.type) {
        case 'OPEN': {
            return {
                current: action.payload,
                modals: [...state.modals, action.payload],
            };
        }
        case 'CLOSE': {
            return {
                current: state.modals[state.modals.length - 2] || null,
                modals: state.modals.filter((m) => m.id !== action.payload),
            };
        }
        case 'CLOSE_ALL': {
            return {
                current: state.current,
                modals: [],
            };
        }
        default: {
            return state;
        }
    }
}
