export const SEND_WS = {
    NEW_GAME: 'new_game',
    CANCEL_WAITING: 'cancel_waiting',
    MOVE_METRICS: 'move_metrics',
    MOVE: 'move',
    BEST_MOVES: 'best_moves',
    RESIGN: 'resign',
    DRAW_OFFER: 'draw_offer',
    DRAW_OFFER_RESPONSE: 'draw_offer_resp',
    SEARCH_USER: 'search_user',
    NEW_GAME_INVITE_RESPONSE: 'new_game_invite_resp',
    CONNECT: 'connect_game',
    DISCONNECT_GAME: 'disconnect_game',
    SPECTATE_OTB_GAME: 'otb_connect',
    DISCONNECT_OTB_GAME: 'otb_disconnect',
    CANCEL_CHALLENGE: 'new_game_invite_cancel',
    GET_CORRESPONDENCE_GAMES: 'get_correspondence_games',
    SEND_REACTION: 'send_reaction',
    LATENCY_PONG: 'latency_pong',
    LATENCY_PING_TRIGGER: 'latency_trigger',
    LATENCY_AVERAGE: 'latency_get',
    SPECTATE_GAMES: 'spectate_games',
    STOP_SPECTATING_GAMES: 'stop_spectating_games',
    IS_IN_QUEUE: 'is_in_queue',
};

export const RECEIVE_WS = {
    MOVE: 'move',
    MATCHED: 'matched',
    GAME_OVER: 'game_over',
    DRAW_OFFER: 'draw_offer',
    DRAW_OFFER_RESPONSE: 'draw_offer_resp',
    USER_SEARCH_RESULT: 'search_result',
    CANCEL_WAITING: 'cancel_waiting',
    CANCEL_CHALLENGE: 'new_game_invite_cancel',
    NEW_GAME_INVITE: 'new_game_invite',
    CONNECT: 'connect',
    CONNECTED: 'connected',
    SPECTATE_OTB_GAME: 'connect_otb',
    OTB_MOVE: 'otb_move',
    OTB_GAME_OVER: 'otb_game_over',
    OTB_GAME_OVER_REST: 'otb_game_over_rest',
    NEW_GAME_INVITE_RESPONSE: 'new_game_invite_resp',
    NEW_GAME_INVITE_FAILED: 'new_game_invite_failed',
    GET_CORRESPONDENCE_GAMES: 'correspondence_games',
    NEW_GAME_TIMEOUT: 'new_game_timeout',
    ERROR: 'error',
    NEW_REACTION: 'new_reaction',
    NOTIFICATION: 'notification',
    LATENCY_PING: 'latency_ping',
    PLAYER_LATENCY: 'player_latency',
    SPECTATE_GAME_STATUS: 'spectated_games_status',
    SPECTATED_USER_NEW_GAME: 'spectated_user_new_game',
    IS_IN_QUEUE: 'is_in_queue',
    RESET_OTB_GAME: 'otb_reset',
};

export const DRAW_OFFER_RESPONSE = {
    ACCEPT: 'accept',
    DENY: 'deny',
};
