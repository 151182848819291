import { takeLatest, put as dispatch } from 'redux-saga/effects';

import * as actions from './action';
import * as api from './api';
import * as constants from './constants';

export function* globalSettings() {
    yield takeLatest(constants.GET_GAME_SETTINGS_REQUEST, getGameSettings);
    yield takeLatest(constants.GET_PRIVACY_REQUEST, getPrivacySettings);
    yield takeLatest(constants.GET_DAILY_REQUEST, getDailySettings);
    yield takeLatest(
        constants.GET_NOTIFICATION_SETTINGS_REQUEST,
        getNotificationSettings
    );
    yield takeLatest(constants.SET_GAME_SETTINGS_REQUEST, setGameSettings);
    yield takeLatest(constants.SET_PRIVACY_REQUEST, setPrivacySettings);
    // yield takeLatest(constants.GET_DAILY_REQUEST, getDailySettings);
    // yield takeLatest(
    //     constants.GET_NOTIFICATION_SETTINGS_REQUEST,
    //     getNotificationSettings
    // );
}

function* getGameSettings() {
    try {
        const resp = yield api.getGameSettings();
        yield dispatch(actions.getGameSettingsSuccess(resp.data.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('getGameSettings failed', e);
        yield dispatch(actions.getGameSettingsFailure(e));
    }
}

function* setGameSettings(params) {
    try {
        yield api.setGameSettings(params);
        yield dispatch(actions.setGameChessSettingsSuccess(params));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('setGameSettings failed', e);
        yield dispatch(actions.setGameChessSettingsFailure(e));
    }
}

function* getPrivacySettings() {
    try {
        const resp = yield api.getPrivacySettings();
        yield dispatch(actions.getPrivacySettingsSuccess(resp.data.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('getPrivacySettings failed', e);
        yield dispatch(actions.getPrivacySettingsFailure(e));
    }
}

function* setPrivacySettings(params) {
    try {
        yield api.setPrivacySettings(params);
        yield dispatch(actions.setPrivacySettingsSuccess(params));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('setPrivacySettings failed', e);
        yield dispatch(actions.setPrivacySettingsFailure(e));
    }
}

function* getDailySettings() {
    try {
        const resp = yield api.getDailySettings();
        yield dispatch(actions.getDailySettingsSuccess(resp.data.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('getDailySettings failed', e);
        yield dispatch(actions.getDailySettingsFailure(e));
    }
}

function* getNotificationSettings() {
    try {
        const resp = yield api.getNotificationSettings();
        yield dispatch(actions.getNotificationSettingsSuccess(resp.data.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('getNotificationSettings failed', e);
        yield dispatch(actions.getNotificationSettingsFailure(e));
    }
}
