import { END, eventChannel } from 'redux-saga';
import {
    call,
    take,
    put as dispatch,
    cancelled,
    takeEvery,
} from 'redux-saga/effects';

const socketHistory = [];

export function createWebSocketConnection(data) {
    // if (!data.token) {
    //     return Promise.resolve(false);
    // }

    return new Promise((resolve, reject) => {
		// eslint-disable-next-line
        console.log('socketHistory', socketHistory);
        // Disconnect previous socket if active
        // TODO Find better way to do this
        if (socketHistory.length > 0) {
			const oldSocket = socketHistory[socketHistory.length - 1];
			// eslint-disable-next-line
            console.log('oldSocket', oldSocket);
            if (oldSocket.readyState === 1 || oldSocket.readyState === 0) {
                oldSocket.close();
            }
        }
        const socket = new WebSocket(
            `${data.endpoint}${data.token ? `?token=${data.token}&client=web` : '?client=web'}`
        );
        socketHistory.push(socket);
        socket.onopen = function() {
            resolve(socket);
            setTimeout(() => {
                heartbeat(socket);
            }, 3000);
        };

        socket.onerror = function(evt) {
            reject(evt);
        };
    });
}

function heartbeat(socket) {
    if (!socket) {
        return;
    }
    if (socket.readyState !== 1) {
        return;
    }
    socket.send('ping');

    setTimeout(() => heartbeat(socket), 5000);
}

export function* listenForSocketMessages(socket, params) {
    // let socket;
    let socketChannel;
    try {
        socketChannel = yield call(createSocketChannel, socket);

        // tell the application that we have a connection
        yield dispatch(params.connectionSuccess(socket));

        while (true) {
            // wait for a message from the channel
            const payload = yield take(socketChannel);

            // a message has been received, dispatch an action with the message payload
            yield dispatch(handleNewWsMessage(payload, params.dispatchAction));
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('There was an error', error);
    } finally {
        // Handle intentionally closed/cancelled
        if (yield cancelled()) {
            // close the channel
            socketChannel.close();

            // close the WebSocket connection
            socket.close();

            // eslint-disable-next-line no-console
            console.log('cancelled');
        } else {
            // eslint-disable-next-line no-console
            console.error('WebSocket disconnected!');

            yield dispatch(params.dispatchUnexpectedDisconnect());
        }
    }
}

export function* listenForMessagesForSocket(socket, params) {
    if (socket.readyState && socket.readyState !== WebSocket.CLOSED) {
        yield takeEvery(params.action, handleSentAction, socket);
        return Promise.resolve(true);
    } else {
        // eslint-disable-next-line no-console
        console.log('Websocket is closed');
    }
}

function createSocketChannel(socket) {
    return eventChannel((emit) => {
        const unsubscribe = () => {
            socket.onmessage = null;
        };

        socket.onmessage = (event) => {
            emit(event.data);
        };

        socket.onclose = () => {
            emit(END);
        };

        return unsubscribe;
    });
}

function handleSentAction(socket, data) {
    const wsData = { ...data };
    wsData.type = wsData.wsAction;
    delete wsData.wsAction;

    if (socket.readyState && socket.readyState !== WebSocket.CLOSED) {
        if (wsData && wsData.type.indexOf('latency') === -1) {
            // eslint-disable-next-line no-console
            console.log('handleSentAction wsData', wsData);
        }
        socket.send(JSON.stringify(wsData));
    }
}

function handleNewWsMessage(data, dispatchAction) {
    const parsedData = JSON.parse(data);
    if (parsedData && parsedData.type.indexOf('latency') === -1 && parsedData.type.indexOf('otb_clock')  && parsedData.type.indexOf('otb_reset')) {
        // eslint-disable-next-line no-console
        console.log('dispatchAction parsedData', parsedData);
    }
    return dispatchAction(parsedData);
}
