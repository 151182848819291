import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import qs from 'query-string';

import Spinner from 'UI/Spinner/Spinner';
import { addToast } from 'store/toast/actions';
import API from 'API';

import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { getUser } from 'store/user/actions';

import styles from './ProductPaymentSuccess.module.scss';
import { useHistory, useLocation } from 'react-router-dom';

export default function ProductPaymentSuccess() {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        const { session_id } = qs.parse(location.search);

        async function validateStripeSession() {
            try {
                dispatch(getUser());

                const response = await API.post(
                    '/products/purchase/validate-payment',
                    {
                        session_id,
                    }
                );

                history.replace(response?.data?.data?.final_url ?? '/home');
                dispatch(
                    addToast({
                        type: 'success',
                        text: {
                            title: 'Thank you for your purchase!',
                        },
                        durationInSeconds: 7,
                    })
                );
            } catch (error) {
                setLoading(false);
                dispatch(
                    addToast({
                        type: 'error',
                        text: {
                            title:
                                error?.response?.error ??
                                'Sorry, something went wrong. Please try again later.',
                        },
                        durationInSeconds: 7,
                    })
                );
            }
        }

        if (!session_id) {
            history.replace('/home');
        } else {
            validateStripeSession();
        }
    }, []);

    return (
        <div className={styles.product_payment_success}>
            <Logo />
            <h2>Processing your request</h2>
            {loading ? (
                <Spinner />
            ) : (
                <div style={{ visibility: 'hidden', height: '42px' }} />
            )}
        </div>
    );
}
