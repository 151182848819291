import { takeLatest, put as dispatch } from 'redux-saga/effects';

import * as actions from './actions';
import * as api from './api';
import * as constants from './constants';

export function* settingsSaga() {
    yield takeLatest(constants.GET_GAME_SETTINGS_REQUEST, getGameSettings);
}

function* getGameSettings(params) {
    try {
        const response = yield api.getGameSettings(params);
        yield dispatch(actions.getGameSettingsSuccess(response.data.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('getGameSettings failed', e);
        yield dispatch(actions.getGameSettingsFailure(e));
    }
}
