import { takeLatest, put as dispatch } from 'redux-saga/effects';

import * as actions from './actions';
import * as api from './api';
import * as constants from './constants';

export function* footerSaga() {
    yield takeLatest(constants.GET_FOOTER_REQUEST, getFooterDataRequest);
}

function* getFooterDataRequest() {
    try {
        const response = yield api.getFooterData();
        yield dispatch(actions.getFooterSuccess(response.data.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('getFooterFailure failed', e);
        yield dispatch(actions.getFooterFailure(e));
    }
}
