import * as constants from './constants';

const initialState = {
    pgnReader: {
        inProgress: false,
        success: undefined,
        data: {
            highlightMoves: true,
            whiteOnBottom: false,
            moveDrag: true,
            moveClick: true,
            enablePremoves: true,
            alwaysPromoteToQueen: false,
            drawArrows: true,
            pieceSet: 4,
            boardSet: 0,
        },
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_PGN_READER_REQUEST:
            return {
                ...state,
                pgnReader: { inProgress: true, success: null },
            };
        case constants.GET_PGN_READER_FAILURE:
            return {
                ...state,
                pgnReader: { inProgress: false, success: true },
            };
        case constants.GET_PGN_READER_SUCCESS:
            return {
                ...state,
                pgnReader: {
                    inProgress: false,
                    success: true,
                    data: action.data,
                },
            };

        default:
            return state;
    }
};

export default reducer;
