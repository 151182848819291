import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    getInAppPurchaseIap,
    shouldOpenPurchaseModal,
} from 'utils/inAppPurchaseUtils';
import { useModals } from 'context/modal/useModals';
import store from 'store/root/store';

export default function IndividualAppPurchasePage() {
    const location = useLocation();
    const history = useHistory();
    const modals = useModals();

    const token = store.getState().user.accessToken;

    const params = new URLSearchParams(location.search);

    const fetchData = async () => {
        try {
            const response = await getInAppPurchaseIap({
                id: params.get('entityId'),
                entity: params.get('entity'),
            });

            const onCloseModal = () => {
                history.push('/lessons');
            };

            shouldOpenPurchaseModal(
                { iap: response.data.data, onCloseModal },
                modals,
                () => {},
                params.get('redirectTo')
            );
        } catch (error) {
            if (!token) {
                setTimeout(() => {
                    fetchData();
                }, 300);
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return null;
}
