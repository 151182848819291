import * as constants from './constants';

export const passwordSuccess = (text) => {
    return {
        type: constants.SEND_NOTIFICATION_PASSWORD_SUCCESS,
        text,
    };
};

export const passwordIncorrect = (text) => {
    return {
        type: constants.SEND_NOTIFICATION_PASSWORD_INCORRECT,
        text,
    };
};

export const passwordMatch = (text) => {
    return {
        type: constants.SEND_NOTIFICATION_PASSWORD_MATCH,
        text,
    };
};

export const correspondence = (text) => {
    return {
        type: constants.SEND_NOTIFICATION_CORRESPONDENCE,
        text,
    };
};

export const receiveCorrespondence = (text) => {
    return {
        type: constants.RECEIVE_NOTIFICATION_CORRESPONDENCE,
        text,
    };
};
