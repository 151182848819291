import * as CONSTANTS from './constants';

// HOME PAGE ACTIONS
export const getHomeRequest = () => {
    return {
        type: CONSTANTS.GET_HOME_REQUEST,
    };
};

export const getHomeSuccess = (data) => {
    return {
        type: CONSTANTS.GET_HOME_SUCCESS,
        data,
    };
};

export const getHomeFailure = (error) => {
    return {
        type: CONSTANTS.GET_HOME_FAILURE,
        error,
    };
};

// PLAYHUB ACTIONS
export const getPlayhubRequest = () => {
    return {
        type: CONSTANTS.GET_PLAYHUB_REQUEST,
    };
};

export const getPlayhubSuccess = (data) => {
    return {
        type: CONSTANTS.GET_PLAYHUB_SUCCESS,
        data,
    };
};

export const getPlayhubFailure = (error) => {
    return {
        type: CONSTANTS.GET_PLAYHUB_FAILURE,
        error,
    };
};

// LEARN PAGE ACTIONS
export const getLearnRequest = () => {
    return {
        type: CONSTANTS.GET_LEARN_REQUEST,
    };
};

export const getLearnSuccess = (data) => {
    return {
        type: CONSTANTS.GET_LEARN_SUCCESS,
        data,
    };
};

export const getLearnFailure = (error) => {
    return {
        type: CONSTANTS.GET_LEARN_FAILURE,
        error,
    };
};

// WATCH PAGE ACTIONS
export const getWatchRequest = () => {
    return {
        type: CONSTANTS.GET_WATCH_REQUEST,
    };
};

export const getWatchSuccess = (data) => {
    return {
        type: CONSTANTS.GET_WATCH_SUCCESS,
        data,
    };
};

export const getWatchFailure = (error) => {
    return {
        type: CONSTANTS.GET_WATCH_FAILURE,
        error,
    };
};

// ARTICLES ACTIONS
export const getAllArticlesRequest = (data) => {
    return {
        type: CONSTANTS.GET_ALL_ARTICLES_REQUEST,
        data,
    };
};

export const getAllArticlesSuccess = (data) => {
    return {
        type: CONSTANTS.GET_ALL_ARTICLES_SUCCESS,
        data,
    };
};

export const getAllArticlesFailure = (error) => {
    return {
        type: CONSTANTS.GET_ALL_ARTICLES_FAILURE,
        error,
    };
};

export const clearAllArticles = () => {
    return {
        type: CONSTANTS.CLEAR_ALL_ARTICLES,
    };
};

// SINGLE ARTICLE ACTIONS
export const getSingleArticleRequest = (id) => {
    return {
        type: CONSTANTS.GET_SINGLE_ARTICLE_REQUEST,
        id,
    };
};

export const getSingleArticleSuccess = (data) => {
    return {
        type: CONSTANTS.GET_SINGLE_ARTICLE_SUCCESS,
        data,
    };
};

export const getSingleArticleFailure = (error) => {
    return {
        type: CONSTANTS.GET_SINGLE_ARTICLE_FAILURE,
        error,
    };
};

// GET MOST VIEWED ARTICLES
export const getMostViewedArticlesRequest = () => {
    return {
        type: CONSTANTS.GET_MOST_VIEWED_ARTICLES_REQUEST,
    };
};

export const getMostViewedArticlesSuccess = (data) => {
    return {
        type: CONSTANTS.GET_MOST_VIEWED_ARTICLES_SUCCESS,
        data,
    };
};

export const getMostViewedArticlesFailure = (error) => {
    return {
        type: CONSTANTS.GET_MOST_VIEWED_ARTICLES_FAILURE,
        error,
    };
};

// GET ALL VIDEOS ACTIONS
export const getAllVideosRequest = (data) => {
    return {
        type: CONSTANTS.GET_ALL_VIDEOS_REQUEST,
        data,
    };
};

export const getAllVideosSuccess = (data) => {
    return {
        type: CONSTANTS.GET_ALL_VIDEOS_SUCCESS,
        data,
    };
};

export const getAllVideosFailure = (error) => {
    return {
        type: CONSTANTS.GET_ALL_VIDEOS_FAILURE,
        error,
    };
};

// GET SINGLE VIDEO ACTIONS
export const getSingleVideoRequest = (id) => {
    return {
        type: CONSTANTS.GET_SINGLE_VIDEO_REQUEST,
        id,
    };
};

export const getSingleVideoSuccess = (data) => {
    return {
        type: CONSTANTS.GET_SINGLE_VIDEO_SUCCESS,
        data,
    };
};

export const getSingleVideoFailure = (error) => {
    return {
        type: CONSTANTS.GET_SINGLE_VIDEO_FAILURE,
        error,
    };
};

// GET VIDEO CATEGORY ACTIONS
export const getVideoCategoryRequest = (data) => {
    return {
        type: CONSTANTS.GET_VIDEO_CATEGORY_REQUEST,
        data,
    };
};

export const getVideoCategorySuccess = (data) => {
    return {
        type: CONSTANTS.GET_VIDEO_CATEGORY_SUCCESS,
        data,
    };
};

export const getVideoCategoryFailure = (error) => {
    return {
        type: CONSTANTS.GET_VIDEO_CATEGORY_FAILURE,
        error,
    };
};
