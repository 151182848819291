import React from 'react';
import styles from './IndividualAppPurchaseModal.module.scss';
import { ReactComponent as Close } from 'assets/icons/close_icon.svg';

import IndividualAppPurchaseCta from 'components/IndividualAppPurchaseCta/IndividualAppPurchaseCta';

const entityContent = {
    common: {
        title:
            'Want more from K-chess? Unlock this lesson or all our training suite!',
        subtitle:
            'Reach the top of your game with our documentaries, podcasts, interviews, lessons, masterclasses, puzzles.',
        text: 'Subscribe to unlock all K-chess',
        learnMore: 'Learn more',
        or: 'or',
    },
    episode: {
        text: 'Unlock this episode',
    },
    lesson: {
        text: 'Unlock this lesson',
    },
    masterclass: {
        text: 'Unlock this masterclass episode',
    },
    program: {
        title:
            'Want more from K-chess? Unlock this program or all our training suite.',
        text: 'Unlock this program',
    },
};

export default function InAppPurchaseContent({ context, innerProps }) {
    const {
        entity,
        entityId,
        price,
        currency,
        finalUrl,
        onCloseModal,
    } = innerProps;

    return (
        <div className={styles.content_wrapper}>
            <div
                className={styles.content_closeModal}
                onClick={() => {
                    context.closeModal(context.id);
                    onCloseModal && onCloseModal();
                }}
            >
                <Close id="open" />
            </div>
            <div className={styles.title_content}>
                <p className={styles.title}>
                    {entityContent[entity]?.title ?? entityContent.common.title}
                </p>
            </div>
            <IndividualAppPurchaseCta
                onCloseModal={context.closeModal}
                entityId={entityId}
                entity={entity}
                price={price}
                currency={currency}
                finalUrl={finalUrl}
                entityText={entityContent[entity]?.text}
                learnMoreText={entityContent.common.learnMore}
                subscribeText={entityContent.common.text}
            />
        </div>
    );
}
