import { takeLatest, put as dispatch } from 'redux-saga/effects';
import store from 'store/root/store';

import * as actions from './actions';

import { wsDisconnect } from 'store/gameWs/actions';
import * as api from './api';
import * as constants from './constants';
import { redirect } from 'store/util/actions';
import { removeIsGuestLS } from 'shared/localStorage';

export function* userSaga() {
    yield takeLatest(constants.CHECK_AUTH, checkUserAuth);
    yield takeLatest(constants.LOGIN_REQUEST, loginRequest);
    yield takeLatest(constants.USER_LOGOUT, userLogout);
    yield takeLatest(constants.LOGOUT_REQUEST, userLogoutRequest);
    yield takeLatest(constants.CHECK_AUTH_LOGOUT, userLogout);
    yield takeLatest(constants.REGISTER_REQUEST, registerRequest);
    yield takeLatest(
        constants.REGISTER_NO_CONFIRM_REQUEST,
        registerNoConfirmRequest
    );
    yield takeLatest(constants.EMAIL_REGISTER_REQUEST, emailRegisterRequest);
    yield takeLatest(constants.FACEBOOK_LOGIN_REQUEST, facebookLoginRequest);
    yield takeLatest(constants.GOOGLE_LOGIN_REQUEST, googleLoginRequest);
    yield takeLatest(constants.APPLE_LOGIN_REQUEST, appleLoginRequest);
    yield takeLatest(constants.GET_USER_REQUEST, userRequest);
    yield takeLatest(constants.CHECK_USERNAME_REQUEST, checkUsernameRequest);
    yield takeLatest(constants.CHECK_EMAIL_REQUEST, checkEmailRequest);
    yield takeLatest(constants.RESET_USER, userRequest);
    yield takeLatest(
        constants.GET_CHAT_AUTH_TOKEN_REQUEST,
        getChatAuthTokenRequest
    );
    yield takeLatest(
        constants.START_PASSWORD_RECOVERY_REQUEST,
        startPasswordRecovery
    );
    yield takeLatest(constants.RECOVER_PASSWORD_REQUEST, recoverPassword);
    yield takeLatest(constants.SET_USER_REQUEST, setUserRequest);
    yield takeLatest(constants.SET_USER_AVATAR_REQUEST, setUserAvatarRequest);
}

// TODO Bojan refresh token part of the flow
function* checkUserAuth() {
    try {
        const response = yield api.userRequest();
        yield dispatch(actions.getUserSuccess(response.data.data));
        yield dispatch(redirect('/home'));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('checkUserAuth failed', e);
        yield dispatch(actions.logout(e));
    }
}

function* loginRequest({ data }) {
    try {
        const response = yield api.loginRequest(data);
        const redirectAfterLogin = store.getState().user.redirectAfterLogin
            ? store.getState().user.redirectAfterLogin
            : '/home';
        console.log(
            'redirectAfterLogin',
            redirectAfterLogin,
            store.getState().user.redirectAfterLogin
        );
        yield dispatch(actions.setAccessToken(response.data.data.access));
        yield dispatch(actions.resetUser());
        // yield dispatch(actions.setRefreshToken(response.data.data.refresh));
        yield dispatch(
            actions.getChatAuthTokenRequest(response.data.data.access)
        );
        yield dispatch(actions.loginSuccess(response.data.data));
        yield dispatch(actions.getUser());
        yield dispatch(wsDisconnect());
        yield removeIsGuestLS();
        console.log(
            'store.getState().user.redirectAfterLogin',
            store.getState(),
            store.getState().user.redirectAfterLogin
        );
        yield dispatch(redirect(redirectAfterLogin));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('loginRequest failed', e);
        yield dispatch(actions.loginFailure(e));
    }
}

function* userLogoutRequest() {
    try {
        const resp = yield api.logoutRequest();

        yield dispatch(actions.logoutSuccess(resp.data.data));
        localStorage.removeItem('user');
        localStorage.removeItem('_k_sqr_at');
        localStorage.removeItem('_k_sqr_rt');
        localStorage.removeItem('_k_sqr_ws_t');
        localStorage.removeItem('_k_sqr_cu');
        localStorage.removeItem('usr_type');
        yield dispatch(wsDisconnect());
        removeIsGuestLS();
        yield dispatch(redirect('/home', true));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('user logout failed', e);
        yield dispatch(actions.logoutFailure(e));
    }
}

function* userLogout() {
    try {
        yield api.logoutRequest();

        yield dispatch(actions.logoutRequest());
        localStorage.removeItem('user');
        localStorage.removeItem('_k_sqr_at');
        localStorage.removeItem('_k_sqr_rt');
        localStorage.removeItem('_k_sqr_ws_t');
        localStorage.removeItem('_k_sqr_cu');
        localStorage.removeItem('usr_type');
        localStorage.removeItem('_k_sqr_m_dta');
        yield dispatch(wsDisconnect());
        removeIsGuestLS();
        yield dispatch(redirect('/home', true));
        // window.location = '/onboarding';
    } catch (e) {
        //eslint-disable-next-line
        console.warn('user logout failed', e);
        yield dispatch(actions.logoutFailure(e));
    }
}

function* registerRequest({ data, flag }) {
    try {
        const response = yield api.registerRequest(data);
        yield removeIsGuestLS();
        switch (flag) {
            case 'facebook':
                yield dispatch(
                    actions.setAccessToken(response.data.data.access)
                );
                yield dispatch(
                    actions.setRefreshToken(response.data.data.refresh)
                );
                yield dispatch(
                    actions.getChatAuthTokenRequest(response.data.data.access)
                );
                yield dispatch(
                    actions.facebookLoginSuccess(response.data.data)
                );
                break;
            case 'google':
                yield dispatch(
                    actions.setAccessToken(response.data.data.access)
                );
                yield dispatch(
                    actions.setRefreshToken(response.data.data.refresh)
                );
                yield dispatch(
                    actions.getChatAuthTokenRequest(response.data.data.access)
                );
                yield dispatch(actions.googleLoginSuccess(response.data.data));
                break;
            case 'apple':
                yield dispatch(
                    actions.setAccessToken(response.data.data.access)
                );
                yield dispatch(
                    actions.setRefreshToken(response.data.data.refresh)
                );
                yield dispatch(
                    actions.getChatAuthTokenRequest(response.data.data.access)
                );
                yield dispatch(actions.appleLoginSuccess(response.data.data));
                break;

            default:
                yield dispatch(
                    actions.setAccessToken(response.data.data.access)
                );
                yield dispatch(
                    actions.setRefreshToken(response.data.data.refresh)
                );
                yield dispatch(
                    actions.getChatAuthTokenRequest(response.data.data.access)
                );
                yield dispatch(actions.loginSuccess(response.data.data));
                break;
        }
        yield dispatch(actions.registerSuccess(response.data.data));
        yield dispatch(actions.getUser());
        // yield dispatch(redirect('/home'));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('registerRequest failed', e);
        yield dispatch(actions.registerFailure(e));
    }
}

function* registerNoConfirmRequest({ data }) {
    try {
        const response = yield api.registerNoConfirmRequest(data);
        yield removeIsGuestLS();

        yield dispatch(actions.setAccessToken(response.data.data.access));
        yield dispatch(actions.setRefreshToken(response.data.data.refresh));
        yield dispatch(
            actions.getChatAuthTokenRequest(response.data.data.access)
        );
        yield dispatch(actions.loginSuccess(response.data.data));
        yield dispatch(actions.registerSuccess(response.data.data));
        yield dispatch(actions.getUser());
    } catch (e) {
        //eslint-disable-next-line
        console.warn('registerNoConfirmRequest failed', e);
        yield dispatch(actions.registerFailure(e));
    }
}

function* emailRegisterRequest({ data }) {
    try {
        const response = yield api.emailRegisterRequest(data);
        yield dispatch(wsDisconnect());
        yield removeIsGuestLS();
        yield dispatch(actions.emailRegisterSuccess(response.data.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('emailRegisterRequest failed', e);
        yield dispatch(actions.emailRegisterFailure(e));
    }
}

function* facebookLoginRequest({ data }) {
    try {
        const response = yield api.facebookLoginRequest(data);
        yield dispatch(actions.setAccessToken(response.data.data.access));
        yield dispatch(actions.resetUser());
        // yield dispatch(actions.setRefreshToken(response.data.data.refresh));
        yield dispatch(
            actions.getChatAuthTokenRequest(response.data.data.access)
        );
        yield dispatch(actions.facebookLoginSuccess(response.data.data));
        yield dispatch(actions.getUser());
        yield dispatch(wsDisconnect());
        yield removeIsGuestLS();
        const redirectAfterLogin = store.getState().user.redirectAfterLogin
            ? store.getState().user.redirectAfterLogin
            : '/home';
        yield dispatch(redirect(redirectAfterLogin));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('facebookLoginRequest failed', e);
        yield dispatch(actions.facebookLoginFailure(e));
    }
}

function* googleLoginRequest({ data }) {
    try {
        const response = yield api.googleLoginRequest(data);
        yield dispatch(actions.setAccessToken(response.data.data.access));
        // yield dispatch(actions.setRefreshToken(response.data.data.refresh));
        yield dispatch(actions.resetUser());
        yield dispatch(
            actions.getChatAuthTokenRequest(response.data.data.access)
        );
        yield dispatch(actions.googleLoginSuccess(response.data.data));
        yield dispatch(actions.getUser());
        yield dispatch(wsDisconnect());
        yield removeIsGuestLS();
        const redirectAfterLogin = store.getState().user.redirectAfterLogin
            ? store.getState().user.redirectAfterLogin
            : '/home';
        yield dispatch(redirect(redirectAfterLogin));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('googleLoginRequest failed', e);
        yield dispatch(actions.googleLoginFailure(e));
    }
}

function* appleLoginRequest({ data }) {
    try {
        const response = yield api.appleLoginRequest(data);
        yield dispatch(actions.setAccessToken(response.data.data.access));
        // yield dispatch(actions.setRefreshToken(response.data.data.refresh));
        yield dispatch(actions.resetUser());
        yield dispatch(
            actions.getChatAuthTokenRequest(response.data.data.access)
        );
        yield dispatch(actions.appleLoginSuccess(response.data.data));
        yield dispatch(actions.getUser());
        yield dispatch(wsDisconnect());
        yield removeIsGuestLS();
        const redirectAfterLogin = store.getState().user.redirectAfterLogin
            ? store.getState().user.redirectAfterLogin
            : '/home';
        yield dispatch(redirect(redirectAfterLogin));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('appleLoginRequest failed', e);
        yield dispatch(actions.appleLoginFailure(e));
    }
}

function* userRequest() {
    try {
        const response = yield api.userRequest();
        yield dispatch(actions.getUserSuccess(response.data.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('loginRequest failed', e);
        yield dispatch(actions.getUserFail(e));
    }
}

function* setUserRequest(params) {
    try {
        yield api.setUser(params);
        yield dispatch(actions.setUserSuccess(params.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('loginRequest failed', e);
        yield dispatch(actions.setUserFailure(e));
    }
}

function* setUserAvatarRequest(params) {
    try {
        const resp = yield api.setUserAvatar(params);
        yield dispatch(actions.setUserAvatarSuccess(resp.data.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('setUserAvatarRequest failed', e);
        yield dispatch(actions.setUserAvatarFailure(e));
    }
}

function* checkUsernameRequest({ username }) {
    try {
        const response = yield api.usernameCheckRequest(username);
        yield dispatch(actions.checkUsernameSuccess(response.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('usernameCheckRequest failed', e);
        yield dispatch(actions.checkUsernameFailure(e));
    }
}

function* checkEmailRequest({ email }) {
    try {
        const response = yield api.emailCheckRequest(email);
        yield dispatch(actions.checkEmailSuccess(response.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('emailCheckRequest failed', e);
        yield dispatch(actions.checkEmailFailure(e));
    }
}

function* startPasswordRecovery({ data }) {
    try {
        const response = yield api.startPasswordRecovery(data);
        yield dispatch(actions.startPasswordRecoverySuccess(response.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('startPasswordRecovery failed', e);
        yield dispatch(actions.startPasswordRecoveryFailure(e));
    }
}

// TODO Bojan: direct login? (response contains access and refresh tokens)
function* recoverPassword({ data }) {
    try {
        const response = yield api.recoverPassword(data);
        yield dispatch(actions.recoverPasswordSuccess(response.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('recoverPassword failed', e);
        yield dispatch(actions.recoverPasswordFailure(e));
    }
}

function* getChatAuthTokenRequest({ options }) {
    try {
        const response = yield api.chatAuthTokenRequest(options);
        yield dispatch(
            actions.chatAuthTokenRequestSuccess(response.data.data.token)
        );
    } catch (e) {
        //eslint-disable-next-line
        console.warn('loginRequest failed', e);
        // yield dispatch(actions.chatAuthTokenRequestFail(e));
    }
}
