import {
    WS_CONNECTION_REQUEST,
    WS_CONNECTION_FAILURE,
    WS_CONNECTION_SUCCESS,
    WS_DISCONNECT,
    WS_DISCONNECT_SUCCESS,
    WS_UNEXPECTED_DISCONNECT,
    WS_RECEIVE_ACTION,
    WS_SEND_ACTION,
    WS_CLEAR_QUEUE,
    WS_CLEAR_GAME,
    ADD_AS_FRIEND_REQUEST,
    BLOCK_PLAYER_REQUEST,
    REMOVE_FRIEND_REQUEST,
    UNBLOCK_PLAYER_REQUEST,
    ADD_AS_FRIEND_FAILURE,
    ADD_AS_FRIEND_SUCCESS,
    BLOCK_PLAYER_FAILURE,
    BLOCK_PLAYER_SUCCESS,
    REMOVE_FRIEND_FAILURE,
    REMOVE_FRIEND_SUCCESS,
    UNBLOCK_PLAYER_FAILURE,
    UNBLOCK_PLAYER_SUCCESS,
    SET_USER_INTERACTION,
} from './actionConstants';

export const wsConnectionSuccess = (socketChannel, name) => {
    return {
        type: WS_CONNECTION_SUCCESS,
        socketChannel,
        name,
    };
};

export const wsConnectionRequest = () => {
    return {
        type: WS_CONNECTION_REQUEST,
    };
};

export const wsConnectionFailure = (e) => {
    return {
        type: WS_CONNECTION_FAILURE,
        error: e,
    };
};

export const wsDisconnect = () => {
    return {
        type: WS_DISCONNECT,
    };
};

export const wsDisconnectSuccess = () => {
    return {
        type: WS_DISCONNECT_SUCCESS,
    };
};

export const wsUnexpectedDisconnect = () => {
    return {
        type: WS_UNEXPECTED_DISCONNECT,
    };
};

export const wsClearQueueList = (index) => {
    return {
        type: WS_CLEAR_QUEUE,
        index,
    };
};

export const sendWsAction = (wsAction, data) => {
    return {
        type: WS_SEND_ACTION,
        wsAction,
        data,
    };
};

export const receiveWsAction = (data) => {
    return {
        type: WS_RECEIVE_ACTION,
        data,
    };
};

export const clearGame = () => {
    return {
        type: WS_CLEAR_GAME,
    };
};

export const setUserInteraction = (data) => {
    return {
        type: SET_USER_INTERACTION,
        data,
    };
};

export const addAsFriendRequest = (id, payload) => {
    return {
        type: ADD_AS_FRIEND_REQUEST,
        id,
        payload,
    };
};

export const removeFriendRequest = (id) => {
    return {
        type: REMOVE_FRIEND_REQUEST,
        id,
    };
};

export const blockPlayerRequest = (id, payload) => {
    return {
        type: BLOCK_PLAYER_REQUEST,
        id,
        payload,
    };
};

export const unblockPlayerRequest = (id, payload) => {
    return {
        type: UNBLOCK_PLAYER_REQUEST,
        id,
        payload,
    };
};
export const addAsFriendSuccess = () => {
    return {
        type: ADD_AS_FRIEND_SUCCESS,
    };
};

export const removeFriendSuccess = () => {
    return {
        type: REMOVE_FRIEND_SUCCESS,
    };
};

export const blockPlayerSuccess = () => {
    return {
        type: BLOCK_PLAYER_SUCCESS,
    };
};

export const unblockPlayerSuccess = () => {
    return {
        type: UNBLOCK_PLAYER_SUCCESS,
    };
};
export const addAsFriendFailure = (error) => {
    return {
        type: ADD_AS_FRIEND_FAILURE,
        error,
    };
};

export const removeFriendFailure = (error) => {
    return {
        type: REMOVE_FRIEND_FAILURE,
        error,
    };
};

export const blockPlayerFailure = (error) => {
    return {
        type: BLOCK_PLAYER_FAILURE,
        error,
    };
};

export const unblockPlayerFailure = (error) => {
    return {
        type: UNBLOCK_PLAYER_FAILURE,
        error,
    };
};
