import * as constants from './constants';

const initialState = {
    password: {
        success: '',
        incorrect: '',
        doNotMatch: '',
    },
    correspondence: {
        send: '',
        receive: '',
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SEND_NOTIFICATION_PASSWORD_SUCCESS:
            return {
                ...state,
                password: {
                    ...state.password,
                    success: action.text,
                },
            };
        case constants.SEND_NOTIFICATION_PASSWORD_MATCH:
            return {
                ...state,
                password: {
                    ...state.password,
                    doNotMatch: action.text,
                },
            };
        case constants.SEND_NOTIFICATION_PASSWORD_INCORRECT:
            return {
                ...state,
                password: {
                    ...state.password,
                    incorrect: action.text,
                },
            };
        case constants.SEND_NOTIFICATION_CORRESPONDENCE:
            return {
                ...state,
                correspondence: {
                    ...state,
                    send: action.text,
                },
            };
        case constants.RECEIVE_NOTIFICATION_CORRESPONDENCE:
            return {
                ...state,
                correspondence: {
                    ...state,
                    receive: action.text,
                },
            };
        default:
            return state;
    }
};

export default reducer;
