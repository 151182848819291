import * as constants from './constants';

// GET GAME SETTINGS
export const getGameChessSettingsRequest = () => {
    return {
        type: constants.GET_GAME_SETTINGS_REQUEST,
    };
};

export const getGameSettingsSuccess = (data) => {
    return {
        type: constants.GET_GAME_SETTINGS_SUCCESS,
        data,
    };
};

export const getGameSettingsFailure = (error) => {
    return {
        type: constants.GET_GAME_SETTINGS_FAILURE,
        error,
    };
};
// --------------------------------------------------

// SET GAME SETTINGS
export const setGameChessSettingsRequest = (data, name) => {
    return {
        type: constants.SET_GAME_SETTINGS_REQUEST,
        data,
        name,
    };
};

export const setGameChessSettingsSuccess = (data) => {
    return {
        type: constants.SET_GAME_SETTINGS_SUCCESS,
        data,
    };
};

export const setGameChessSettingsFailure = (error) => {
    return {
        type: constants.SET_GAME_SETTINGS_FAILURE,
        error,
    };
};
// ----------------------------------------------------

// GET PRIVACY SETTINGS
export const getPrivacySettingsRequest = () => {
    return {
        type: constants.GET_PRIVACY_REQUEST,
    };
};

export const getPrivacySettingsSuccess = (data) => {
    return {
        type: constants.GET_PRIVACY_SUCCESS,
        data,
    };
};

export const getPrivacySettingsFailure = (error) => {
    return {
        type: constants.GET_PRIVACY_FAILURE,
        error,
    };
};
// ----------------------------------------------------

// SET PRIVACY SETTINGS
export const setPrivacySettingsRequest = (data, name) => {
    return {
        type: constants.SET_PRIVACY_REQUEST,
        data,
        name,
    };
};

export const setPrivacySettingsSuccess = (data) => {
    return {
        type: constants.SET_PRIVACY_SUCCESS,
        data,
    };
};

export const setPrivacySettingsFailure = (error) => {
    return {
        type: constants.SET_PRIVACY_FAILURE,
        error,
    };
};
// ---------------------------------------------------

// GET DAILY SETTINGS
export const getDailySettingsRequest = () => {
    return {
        type: constants.GET_DAILY_REQUEST,
    };
};

export const getDailySettingsSuccess = (data) => {
    return {
        type: constants.GET_DAILY_SUCCESS,
        data,
    };
};

export const getDailySettingsFailure = (error) => {
    return {
        type: constants.GET_DAILY_FAILURE,
        error,
    };
};
// -------------------------------------------------------

// GET NOTIFICATION SETTINGS
export const getNotificationSettingsRequest = () => {
    return {
        type: constants.GET_NOTIFICATION_SETTINGS_REQUEST,
    };
};

export const getNotificationSettingsSuccess = (data) => {
    return {
        type: constants.GET_NOTIFICATION_SETTINGS_SUCCESS,
        data,
    };
};

export const getNotificationSettingsFailure = (error) => {
    return {
        type: constants.GET_NOTIFICATION_SETTINGS_FAILURE,
        error,
    };
};
// --------------------------------------------------------

// SET NOTIFICATION SETTINGS
export const setNotificationSettingsRequest = (data) => {
    return {
        type: constants.SET_NOTIFICATION_SETTINGS_REQUEST,
        data,
    };
};

export const setNotificationSettingsSuccess = () => {
    return {
        type: constants.SET_NOTIFICATION_SETTINGS_SUCCESS,
    };
};

export const setNotificationSettingsFailure = (error) => {
    return {
        type: constants.SET_NOTIFICATION_SETTINGS_FAILURE,
        error,
    };
};
// ------------------------------------------------------
