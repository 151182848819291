export const USER_ROLES = {
    BEGINNER: 'beginner',
    CASUAL: 'casual',
    CLUB: 'club',
    LEAGUE: 'league',
    TOURNAMENT: 'tournament',
    ADVANCED: 'advanced',
    EXPERT: 'expert',
    MASTER: 'master',
};
