import API from 'API';

const API_LIST = {
    GAME_SETTINGS: '/users/game_settings',
    PRIVACY_SETTINGS: '/users/privacy_settings',
    NOTIFICATION_SETTINGS: '/users/notifications_settings',
    DAILY_SETTINGS: '/users/daily_chess_settings',
};

export function getGameSettings() {
    return API.get(API_LIST.GAME_SETTINGS);
}

export function getPrivacySettings() {
    return API.get(API_LIST.PRIVACY_SETTINGS);
}

export function getNotificationSettings() {
    return API.get(API_LIST.NOTIFICATION_SETTINGS);
}

export function getDailySettings() {
    return API.get(API_LIST.DAILY_SETTINGS);
}

export function setGameSettings(params) {
    return API.post(API_LIST.GAME_SETTINGS, params.data);
}

export function setPrivacySettings(params) {
    return API.post(API_LIST.PRIVACY_SETTINGS, params.data);
}

export function setNotificationSettings(params) {
    return API.post(API_LIST.NOTIFICATION_SETTINGS, params);
}

export function setDailySettings(params) {
    return API.post(API_LIST.DAILY_SETTINGS, params);
}
