// import STRAPI from 'API_STRAPI';
import API from 'API';

const API_PROXY = API;
export const STRAPI_URL_EXTENSION = '/cms/';
export const STRAPI_URL_EXTENSION_HUBS = '/cms/hubs/';

export const STRAPI_URLS = {
    HOME: 'home',
    PLAYHUB: 'play',
    LEARN: 'learn',
    WATCH: 'watch',
    ARTICLES: 'articles',
    ARTICLE: 'articles/',
    MOST_VIEWED_ARTICLES: 'articles/mostviewed?_limit=3',
    VIDEOS: 'videos/',
    VIDEO_CATEGORY: 'video_category/',
};

export const getHomePage = () => {
    return API_PROXY.get(STRAPI_URL_EXTENSION_HUBS + STRAPI_URLS.HOME);
};

export const getPlayhubPage = () => {
    return API_PROXY.get(STRAPI_URL_EXTENSION_HUBS + STRAPI_URLS.PLAYHUB);
};

export const getLearnPage = () => {
    return API_PROXY.get(STRAPI_URL_EXTENSION_HUBS + STRAPI_URLS.LEARN);
};

export const getWatchPage = () => {
    return API_PROXY.get(STRAPI_URL_EXTENSION_HUBS + STRAPI_URLS.WATCH);
};

export const getAllArticles = (params) => {
    const apiCall =
        STRAPI_URL_EXTENSION +
        STRAPI_URLS.ARTICLES +
        `?${params.data.limit ? `_limit=${params.data.limit}` : ''}${
            params.data.offset ? `&_start=${params.data.offset}` : ''
        }`;

    return API_PROXY.get(apiCall);
};

export const getSingleArticle = (params) => {
    const apiCall = STRAPI_URL_EXTENSION + STRAPI_URLS.ARTICLE + params.id;

    return API_PROXY.get(apiCall);
};

export const getMostViewedArticles = () => {
    const apiCall = STRAPI_URL_EXTENSION + STRAPI_URLS.MOST_VIEWED_ARTICLES;

    return API_PROXY.get(apiCall);
};

export const getAllVideos = (params) => {
    const apiCall =
        STRAPI_URL_EXTENSION +
        STRAPI_URLS.VIDEOS +
        `?${params.data.limit ? `_limit=${params.data.limit}` : ''}${
            params.data.offset ? `&_start=${params.data.offset}` : ''
        }${
            params.data.selectedType !== 'all'
                ? `&video_category.slug=${
                      params.data.selectedType === 'chess journeys'
                          ? 'journeys'
                          : params.data.selectedType
                  }`
                : ''
        }`;

    return API_PROXY.get(apiCall);
};

export const getSingleVideo = (params) => {
    const apiCall = STRAPI_URL_EXTENSION + STRAPI_URLS.VIDEOS + params.id;

    return API_PROXY.get(apiCall);
};

export const getVideoCategory = (params) => {
    const apiCall =
        STRAPI_URL_EXTENSION + STRAPI_URLS.VIDEO_CATEGORY + params.data;

    return API_PROXY.get(apiCall);
};
