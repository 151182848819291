export function capitalize(word) {
    if (typeof word === 'string') {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }
    return word;
}

export const replaceLetterInString = (string, letter, at) => {
    const newString =
        string.substring(0, at) + letter + string.substring(at + 1);
    return newString;
};
