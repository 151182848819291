export const WS_CONNECTION_REQUEST = 'WS:GAME:WS_CONNECTION_REQUEST';
export const WS_CONNECTION_SUCCESS = 'WS:GAME:WS_CONNECTION_SUCCESS';
export const WS_CONNECTION_FAILURE = 'WS:GAME:WS_CONNECTION_FAILURE';
export const WS_DISCONNECT = 'WS:GAME:WS_DISCONNECT';
export const WS_DISCONNECT_SUCCESS = 'WS:GAME:WS_DISCONNECT_SUCCESS';
export const WS_UNEXPECTED_DISCONNECT = 'WS:GAME:WS_UNEXPECTED_DISCONNECT';
export const WS_SEND_ACTION = 'WS:GAME:WS_SEND_ACTION';
export const WS_RECEIVE_ACTION = 'WS:GAME:WS_RECEIVE_ACTION';
export const WS_CLEAR_QUEUE = 'WS:GAME:WS_CLEAR_QUEUE';
export const WS_CLEAR_GAME = 'WS:GAME:WS_CLEAR_GAME';
export const WS_NOTIFICATION = 'WS:NOTIFICATION:RECEIVED';

export const SET_USER_INTERACTION = 'GAME:SET_USER_INTERACTION';

export const ADD_AS_FRIEND_REQUEST = 'GAME:ADD_AS_FRIEND_REQUEST';
export const REMOVE_FRIEND_REQUEST = 'GAME:REMOVE_FRIEND_REQUEST';
export const BLOCK_PLAYER_REQUEST = 'GAME:BLOCK_PLAYER__REQUEST';
export const UNBLOCK_PLAYER_REQUEST = 'GAME:UNBLOCK_PLAYER_REQUEST';

export const ADD_AS_FRIEND_SUCCESS = 'GAME:ADD_AS_FRIEND_SUCCESS';
export const REMOVE_FRIEND_SUCCESS = 'GAME:REMOVE_FRIEND_SUCCESS';
export const BLOCK_PLAYER_SUCCESS = 'GAME:BLOCK_PLAYER_SUCCESS';
export const UNBLOCK_PLAYER_SUCCESS = 'GAME:UNBLOCK_PLAYER_SUCCESS';

export const ADD_AS_FRIEND_FAILURE = 'GAME:ADD_AS_FRIEND_FAILURE';
export const REMOVE_FRIEND_FAILURE = 'GAME:REMOVE_FRIEND_FAILURE';
export const BLOCK_PLAYER_FAILURE = 'GAME:BLOCK_PLAYER_FAILURE';
export const UNBLOCK_PLAYER_FAILURE = 'GAME:UNBLOCK_PLAYER_FAILURE';
