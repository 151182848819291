import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App.js';

import store from 'store/root/store';

import * as serviceWorker from './serviceWorker';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import version from 'version';

import './index.scss';

// Use Sentry only on production
if (process.env.REACT_APP_IS_PRODUCTION) {
    Sentry.init({
        dsn:
            'https://8b5fde3ef6764397939c0bdc0de7a64a@glitchtip.webrocks.net/3',
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        release: 'KasparovChess@' + version,
    });
}

const app = (
    <Router>
        <App store={store} />
    </Router>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
