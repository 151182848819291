import * as constants from './constants';

const initialState = {
    gameSettings: {
        gameSettingsState: {
            fetchInProgress: false,
            fetchSuccess: null,
            fetchError: null,
            submitInProgress: false,
            submitSuccess: null,
            submitError: null,
        },
        moveMethod: 'both',
        highlightMoves: true,
        whiteBottom: false,
        board: 0,
        pieces: 0,
        premoves: true,
        alwaysPromoteQueen: false,
        rightClickDraws: true,
    },
    privacySettings: {
        privacySettingsState: {
            fetchInProgress: false,
            fetchSuccess: null,
            fetchError: null,
            submitInProgress: false,
            submitSuccess: null,
            submitError: null,
        },
        doNotDisturb: false,
        allowChat: true,
        incomingChallengeRequests: true,
        friendsVisibility: true,
        incomingFriendRequests: true,
    },
    // dailyChess: {
    //     pauseGames: false,
    //     maximumNumberGames: null,
    //     automaticClaimVictory: false,
    //     friendChallenge: true,
    //     chat: 'everyone',
    //     inProgress: false,
    //     success: null,
    //     error: null,
    // },
};

const fetchGameSettings = (state, action) => {
    return {
        ...state,
        gameSettings: {
            gameSettingsState: {
                ...state.gameSettings.gameSettingsState,
                fetchInProgress: false,
                fetchSuccess: true,
                fetchError: false,
            },
            moveMethod: action.data.move_method,
            highlightMoves: action.data.highlight_moves,
            whiteBottom: action.data.white_bottom,
            board: action.data.board,
            pieces: action.data.pieces,
            premoves: action.data.premoves,
            alwaysPromoteQueen: action.data.always_promote_queen,
            rightClickDraws: action.data.right_click_draws,
        },
    };
};

const fetchPrivacySettings = (state, action) => {
    return {
        ...state,
        privacySettings: {
            privacySettingsState: {
                fetchInProgress: false,
                fetchSuccess: true,
            },
            doNotDisturb: action.data.do_not_disturb,
            allowChat: action.data.allow_chat,
            incomingChallengeRequests: action.data.incoming_challenge_requests,
            friendsVisibility: action.data.friends_visibility,
            incomingFriendRequests: action.data.incoming_friend_requests,
        },
    };
};

const setGameSettings = (state, action) => {
    if (action.data.name === 'live chess') {
        return {
            ...state,
            gameSettings: {
                ...state.gameSettings,
                gameSettingsState: {
                    ...state.gameSettings.gameSettingsState,
                    submitInProgress: false,
                    submitSuccess: true,
                    submitError: null,
                },
                premoves: action.data.premoves,
                alwaysPromoteQueen: action.data.always_promote_queen,
                rightClickDraws: action.data.right_click_draws,
            },
        };
    } else {
        return {
            ...state,
            gameSettings: {
                ...state.gameSettings,
                gameSettingsState: {
                    ...state.gameSettings.gameSettingsState,
                    submitInProgress: false,
                    submitSuccess: true,
                    submitError: null,
                },
                moveMethod: action.data.move_method,
                highlightMoves: action.data.highlight_moves,
                whiteBottom: action.data.white_bottom,
                board: action.data.board,
                pieces: action.data.pieces,
            },
        };
    }
};

const setPrivacySettings = (state, action) => {
    if (action.data.name === 'privacy') {
        return {
            ...state,
            privacySettings: {
                ...state.privacySettings,
                privacySettingsState: {
                    ...state.privacySettings.privacySettingsState,
                    submitInProgress: false,
                    submitSuccess: true,
                },
                allowChat: action.data.data.allow_chat,
                incomingChallengeRequests:
                    action.data.data.incoming_challenge_requests,
            },
        };
    } else {
        return {
            ...state,
            privacySettings: {
                ...state.privacySettings,
                privacySettingsState: {
                    ...state.privacySettings.privacySettingsState,
                    submitInProgress: false,
                    submitSuccess: true,
                },
                doNotDisturb: action.data.do_not_disturb,
            },
        };
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_GAME_SETTINGS_REQUEST:
            return {
                ...state,
                gameSettings: {
                    ...state.gameSettings,
                    gameSettingsState: {
                        ...state.gameSettings.gameSettingsState,
                        fetchInProgress: true,
                        fetchSuccess: false,
                        fetchError: false,
                    },
                },
            };
        case constants.GET_GAME_SETTINGS_SUCCESS:
            return fetchGameSettings(state, action);
        case constants.GET_GAME_SETTINGS_FAILURE:
            return {
                ...state,
                gameSettings: {
                    ...state.gameSettings,
                    gameSettingsState: {
                        ...state.gameSettings.gameSettingsState,
                        fetchInProgress: false,
                        fetchSuccess: false,
                        fetchError: action.error,
                    },
                },
            };
        case constants.SET_GAME_SETTINGS_REQUEST:
            return {
                ...state,
                gameSettings: {
                    ...state.gameSettings,
                    gameSettingsState: {
                        ...state.gameSettings.gameSettingsState,
                        submitInProgress: true,
                        submitSuccess: null,
                    },
                },
            };
        case constants.SET_GAME_SETTINGS_SUCCESS:
            return setGameSettings(state, action);
        case constants.SET_GAME_SETTINGS_FAILURE:
            return {
                ...state,
                gameSettings: {
                    ...state.gameSettings,
                    gameSettingsState: {
                        ...state.gameSettings.gameSettingsState,
                        submitError: action.error,
                    },
                },
            };
        case constants.GET_PRIVACY_REQUEST:
            return {
                ...state,
                privacySettings: {
                    ...state.privacySettings,
                    privacySettingsState: {
                        fetchInProgress: true,
                    },
                },
            };
        case constants.GET_PRIVACY_SUCCESS:
            return fetchPrivacySettings(state, action);
        case constants.GET_PRIVACY_FAILURE:
            return {
                ...state,
                privacySettings: {
                    ...state.privacySettings,
                    privacySettingsState: {
                        fetchInProgress: false,
                        fetchError: action.error,
                    },
                },
            };
        case constants.SET_PRIVACY_REQUEST:
            return {
                ...state,
                privacySettings: {
                    ...state.privacySettings,
                    privacySettingsState: {
                        ...state.privacySettings.privacySettingsState,
                        submitInProgress: true,
                        submitSuccess: null,
                    },
                },
            };
        case constants.SET_PRIVACY_SUCCESS:
            return setPrivacySettings(state, action);
        case constants.SET_PRIVACY_FAILURE:
            return {
                ...state,
                privacySettings: {
                    ...state.privacySettings,
                    privacySettingsState: {
                        ...state.privacySettings.privacySettingsState,
                        submitInProgress: false,
                        submitError: action.error,
                    },
                },
            };
        default:
            return state;
    }
};

export default reducer;
